import React from "react";
import convertRoleToPtBR from "../../utils/profile-access";
import { IconContainer } from "../../atoms/IconContainer";
import DataNotFound from "../../atoms/DataNotFound";

import {
  IconAcordeon,
  CardsGrid,
  Card,
  Key,
  Code,
  Value,
  ControlAcordeon,
  AccordionShow,
  Acordeon,
  Actions,
  IconDelete,
  IconUpdate,
} from "./styles";

function TableAccess({ profiles, isSubmitting, handleDelete, handleUpdate }) {
  return (
    <>
      {profiles.length === 0 ? (
        <DataNotFound message="Nenhum resultado encontrado" />
      ) : (
        <CardsGrid>
          {profiles.map((profile) => (
            <Card key={`${profile.id}-${profile.name}`}>
              <Code>
                Código &nbsp;<Value>{profile.id}</Value>
              </Code>

              <AccordionShow
                type="checkbox"
                id={`${profile.id}-${profile.name}`}
              />
              <ControlAcordeon htmlFor={`${profile.id}-${profile.name}`}>
                <IconAcordeon />
              </ControlAcordeon>

              <Acordeon className="card">
                <Key>
                  Nome &nbsp;<Value>{profile.name}</Value>
                </Key>
                <Key className="email">
                  E-mail &nbsp;
                  <Value>{profile.email}</Value>
                </Key>
                <Key>
                  Perfil de acesso &nbsp;
                  <Value>{convertRoleToPtBR(profile.role)}</Value>
                </Key>

                <Actions>
                  <IconContainer onClick={() => handleUpdate(profile)}>
                    <IconUpdate disabled={isSubmitting} />
                  </IconContainer>

                  <IconContainer onClick={() => handleDelete(profile.id)}>
                    <IconDelete disabled={isSubmitting} />
                  </IconContainer>
                </Actions>
              </Acordeon>
            </Card>
          ))}
        </CardsGrid>
      )}
    </>
  );
}

export default TableAccess;
