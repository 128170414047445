/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
/*  */
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

/* ================= */

// services
import api from "../../services/api";

import Dashboard from "../../templates/Dashboard";
import { Button } from "../../atoms/Button";
import Search from "../../organisms/Search";

/* Modals */
import ModalOrderRegister from "../../organisms/ModalOrderRegister";

/* IN PROGRESS AND FINISHED*/
/* ================= */
/* ================= */
import TableDemandProgress from "../../organisms/TableDemandProgress";
import TableDemandFinished from "../../organisms/TableDemandFinished";
import PaginationTab from "../../atoms/PaginationTab";
import ModalOrderDetail from "../../organisms/ModalOrderDetail";
import swal, { fireAction } from "../../utils/swal";

import { formatDate } from "../../utils/date-format";
import { formatPhone } from "../../utils/phone-format";
import { formatPrice } from "../../utils/price-format";

/* FINISHED */
/* ================= */
/* ================= */

/* ================= */
/* ================= */
/* ================= */
import {
  Container,
  Header,
  ButtonsContainer,
  TabNavigation,
  ToPrint,
  ContainerToPrint,
  Report,
} from "./styles";

const AntTabs = withStyles({
  root: {
    width: "max-content",
    borderBottom: "1px solid #e8e8e8",
    marginTop: 20,
  },
  indicator: {
    backgroundColor: "#0CBCE4",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    "@media(max-width: 468px)": {
      maxWidth: 150,
    },
    fontWeight: "bold",
    color: "#005D89",
    opacity: 1,
    fontFamily: [
      '"Source Sans Pro"',
      '"sans-serif"',
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 16,
    transition: "color .2s ease-out",
    "&:hover": {
      color: "#094967",
    },
    "&$selected": {
      color: "#0CBCE4",
    },
    "&:focus": {
      color: "#0CBCE4",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: "#fff",
    "@media(max-width: 768px)": {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr)",
      gridGap: "10px",
      placeItems: "center",
    },
  },
}));

function Demand() {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [isSearchbarVisible, setIsSearchbarVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /* ---------------------- */
  const [isModalOrderDetailVisible, setIsModalOrderDetailVisible] = useState(
    false
  );

  /* ------------------------- */
  /* ------------------------- */
  /* ------------------------- */
  /* --------------------------------- */
  const [details, setDetails] = useState({ type: "", data: {} });

  const getOrderDetails = React.useCallback(async (order) => {
    if (order) {
      const { data } = await api.get(`/orders/${order}`);
      setDetails({
        type: "ONE_ORDER",
        data: data?.order,
      });
    }
  }, []);

  function sendMessageOnWpp(number, details) {
    const message = `Saudações ${
      details.customer?.name
    }, esta é uma mensagem automática do sistema Seg Lavanderia para te manter informado sobre o pedido nº ${
      details.code
    }.

------------------------------
DADOS DO PEDIDO:
------------------------------

Data e hora do pedido: ${formatDate(details.created_at, "dd-MM-yyyy-TT")}
Status de entrega: ${details.status === "pending" ? "Pendente" : "Finalizado"}
Status de pagamento: ${
      details.paid ? "Pagamento efetuado" : "Aguardando pagamento"
    }
Data e hora de pagamento: ${
      details.payment_date !== null
        ? formatDate(details.payment_date, "dd-MM-yyyy-TT")
        : "Em espera"
    }
Forma de pagamento: ${details.payment_type}

------------------------------
RESUMO DO PEDIDO:
------------------------------
${details.items?.map((item, index) => {
  if (item.total > 0) {
    return item.service_id === null
      ? `
-> ${item.product?.name} - ${formatPrice(item?.unit_price)} - ${
          item?.quantity
        } und.
Total: ${formatPrice(item?.total)}`
      : `
-> ${item.service?.type} - ${item.service?.description} - ${formatPrice(
          item?.unit_price
        )} - ${item?.quantity} und.
Total: ${formatPrice(item?.total)}`;
  }
})}

Total da ordem: ${formatPrice(
      details.items?.reduce((total, current) => total + current.total, 0)
    )}
Observações: ${details.notes}
------------------------------

NOTA: O prazo para recolhimento das peças deixadas em nossa lavanderia é de no máximo 90 dias (três meses).

Se você não reconhece esse pedido, entre em contato conosco.

`;

    if (number) {
      window.open(
        `https://api.whatsapp.com/send?phone=55${number}&text=${encodeURI(
          message
        )}`
      );
    }
  }

  /* ------------------------- */
  /* ------------------------- */
  /* ------------------------- */
  /* ------------------------- */

  function handleToggleModalOrderDetailVisibility() {
    if (isModalOrderDetailVisible) {
      setDetails({
        type: "",
        data: {},
      });
    }
    setIsModalOrderDetailVisible(!isModalOrderDetailVisible);
  }
  /* ---------------------- */

  const [
    isModalOrderRegisterVisible,
    setIsModalOrderRegisterVisible,
  ] = useState(false);

  function handleToggleModalOrderRegisterVisibility() {
    setIsModalOrderRegisterVisible(!isModalOrderRegisterVisible);
  }

  /* ------------------- */
  /* ------------------- */
  const [lastPageInProgress, setLastPageInProgress] = useState(1);
  const [pageInProgress, setPageInProgress] = useState(1);
  const [queryProgress, setQueryProgress] = useState("");
  const [ordersInProgress, setOrdersInProgress] = useState([]);

  async function getOrdersInProgress(page, query = "", reset = false) {
    if (reset) {
      setPageInProgress(1);
    }
    const { data } = await api.get(
      `/orders?qs=${query}&status=pending&page=${reset ? 1 : page}&limit=10`
    );

    setOrdersInProgress(data.data);
    setLastPageInProgress(data.meta?.last_page);
  }

  useEffect(() => {
    getOrdersInProgress(pageInProgress, queryProgress, false);
  }, [pageInProgress, queryProgress]);

  function handleClickDetails(order) {
    getOrderDetails(order);
    handleToggleModalOrderDetailVisibility();
  }

  /* ------------------- */
  /* ------------------- */
  /* ------------------- */
  /* ------------------- */
  /* ------------------- */
  /* ------------------- */

  const [pageFinished, setPageFinished] = useState(1);
  const [ordersFinished, setOrdersFinished] = useState([]);
  const [lastPageFinished, setLastPageFinished] = useState(1);
  const [queryFinished, setQueryFinished] = useState("");

  async function getOrdersFinished(page, query = "", reset = false) {
    if (reset) {
      setPageFinished(1);
    }
    const { data } = await api.get(
      `/orders?qs=${query}&status=completed&page=${reset ? 1 : page}&limit=10`
    );

    setLastPageFinished(data.meta?.last_page);
    setOrdersFinished(data.data);
  }

  useEffect(() => {
    getOrdersFinished(pageFinished, queryFinished, false);
  }, [pageFinished, queryFinished]);

  /*-----------------------*/
  /*-----------------------*/
  /*-----------------------*/
  /*-----------------------*/
  /*-----------------------*/
  /*-----------------------*/

  function handleToggleSearchbarVisibility() {
    if (searchValue) {
      setSearchValue("");

      if (selectedTab === 0) {
        setQueryProgress("");
        getOrdersInProgress(pageInProgress, "", true);
      } else {
        setQueryFinished("");
        getOrdersFinished(pageFinished, "", true);
      }
    }
    setIsSearchbarVisible(!isSearchbarVisible);
  }

  function handleSearchQuery(e) {
    e.preventDefault();

    setPageInProgress(1);
    setPageFinished(1);

    if (selectedTab === 0) {
      setQueryProgress(searchValue);
      getOrdersInProgress(1, searchValue, true);
    }
    if (selectedTab === 1) {
      setQueryFinished(searchValue);
      getOrdersFinished(1, searchValue, true);
    }
  }

  const handleChangeSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
    setSearchValue("");
    setQueryProgress("");
    setQueryFinished("");
  };

  /*
  =================
  = FUNCTIONS
  =================
  */
  async function closeOrder(code) {
    swal
      .fire({
        title: "Finalizar ordem de serviço e produtos",
        text: "Tem certeza que deseja marcar como concluído o status da ordem?",
        icon: "warning",
        customClass: {
          header: "header-alert",
          icon: "icon-alert",
          title: `title-alert-warning`,
          content: "description-alert",
        },
        showCancelButton: true,
        confirmButtonColor: "#6b915c",
        cancelButtonColor: "#cc2c29",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      })
      .then(async (result) => {
        if (result.value) {
          setIsSubmitting(true);
          try {
            await api.put(`/orders/${code}/close`, null);

            fireAction(
              "Tudo certo!",
              "A ordem de serviço foi marcada como finalizada",
              "success"
            );

            isModalOrderDetailVisible && setIsModalOrderDetailVisible(false);
            setDetails({
              type: "",
              data: {},
            });

            getOrdersInProgress(pageInProgress, queryProgress, true);
            getOrdersFinished(pageFinished, queryFinished, true);
          } catch (err) {
            fireAction(
              "Ops, algo deu errado!",
              "Falha ao finalizar uma ordem de serviço",
              "error"
            );
          }
          setIsSubmitting(false);
        }
      });
  }

  const handleDelete = async (code) => {
    swal
      .fire({
        title: "Tem certeza que deseja excluir esta ordem?",
        text: "Esta ação não poderá ser revertida!",
        icon: "warning",
        customClass: {
          header: "header-alert",
          icon: "icon-alert",
          title: `title-alert-warning`,
          content: "description-alert",
        },
        showCancelButton: true,
        confirmButtonColor: "#6b915c",
        cancelButtonColor: "#cc2c29",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      })
      .then(async (result) => {
        if (result.value) {
          setIsSubmitting(true);
          try {
            await api.delete(`/orders/${code}`);

            fireAction(
              "Tudo certo!",
              "A ordem foi removida da base de dados",
              "success"
            );

            isModalOrderDetailVisible && setIsModalOrderDetailVisible(false);
            setDetails({
              type: "",
              data: {},
            });

            const filtered = ordersInProgress.filter(
              (order) => order.code !== code
            );
            setOrdersInProgress(filtered);

            if (filtered.length === 0 && pageInProgress > 1) {
              setPageInProgress((page) => page - 1);
            }
          } catch (err) {
            if (err.response?.status === 401) {
              fireAction(
                "Ops, algo deu errado!",
                "Você não tem permissão para realizar esta ação",
                "error"
              );
            } else {
              fireAction(
                "Ops, algo deu errado!",
                "Falha ao remover a ordem da base de dados",
                "error"
              );
            }
          }
          setIsSubmitting(false);
        }
      });
  };

  async function handleTogglePayment(paid, code) {
    if (paid) {
      swal
        .fire({
          title: "Atualização de pagamento",
          text: "Tem certeza que deseja desfazer o pagamento para esta ordem?",
          icon: "warning",
          customClass: {
            header: "header-alert",
            icon: "icon-alert",
            title: `title-alert-warning`,
            content: "description-alert",
          },
          showCancelButton: true,
          confirmButtonColor: "#6b915c",
          cancelButtonColor: "#cc2c29",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then(async (result) => {
          if (result.value) {
            setIsSubmitting(true);
            try {
              await api.delete(`/orders/${code}/pay`);

              fireAction(
                "Tudo certo!",
                "O status de pagamento foi revertido!",
                "success"
              );

              isModalOrderDetailVisible && setIsModalOrderDetailVisible(false);
              setDetails({
                type: "",
                data: {},
              });

              if (selectedTab === 0) {
                getOrdersInProgress(pageInProgress, queryProgress, false);
              }
              if (selectedTab === 1) {
                getOrdersFinished(pageFinished, queryFinished, false);
              }
            } catch (err) {
              if (err.response?.status === 401) {
                fireAction(
                  "Ops, algo deu errado!",
                  "Você não tem permissão para realizar esta ação",
                  "error"
                );
              } else {
                fireAction(
                  "Ops, algo deu errado!",
                  "Falha ao reverter o pagamento de uma ordem de serviço",
                  "error"
                );
              }
            }
            setIsSubmitting(false);
          }
        });
    } else {
      swal
        .fire({
          title: "Atualização de pagamento",
          text:
            "Tem certeza que deseja confirmar o recebimento do pagamento para esta ordem?",
          icon: "warning",
          customClass: {
            header: "header-alert",
            icon: "icon-alert",
            title: `title-alert-warning`,
            content: "description-alert",
          },
          showCancelButton: true,
          confirmButtonColor: "#6b915c",
          cancelButtonColor: "#cc2c29",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then(async (result) => {
          if (result.value) {
            setIsSubmitting(true);
            try {
              const today = new Date();

              await api.put(`/orders/${code}/pay`, {
                date: formatDate(today, "yyyy-MM-dd TT").replaceAll("/", "-"),
              });

              fireAction(
                "Tudo certo!",
                "O pagamento foi confirmado!",
                "success"
              );

              isModalOrderDetailVisible && setIsModalOrderDetailVisible(false);
              setDetails({
                type: "",
                data: {},
              });

              if (selectedTab === 0) {
                getOrdersInProgress(pageInProgress, queryProgress, false);
              }
              if (selectedTab === 1) {
                getOrdersFinished(pageFinished, queryFinished, false);
              }
            } catch (err) {
              fireAction(
                "Ops, algo deu errado!",
                "Falha ao confirmar o pagamento de uma ordem de serviço",
                "error"
              );
            }
            setIsSubmitting(false);
          }
        });
    }
  }

  async function handleRevertOrder(code) {
    swal
      .fire({
        title: "Reverter Pedido",
        text:
          "Tem certeza que deseja reverter o pedido de volta para pedidos em andamento?",
        icon: "warning",
        customClass: {
          header: "header-alert",
          icon: "icon-alert",
          title: `title-alert-warning`,
          content: "description-alert",
        },
        showCancelButton: true,
        confirmButtonColor: "#6b915c",
        cancelButtonColor: "#cc2c29",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      })
      .then(async (result) => {
        if (result.value) {
          setIsSubmitting(true);
          try {
            await api.delete(`/orders/${code}/close`);

            fireAction(
              "Tudo certo!",
              "O pedido foi revertido e encontra-se na seção de pedidos em andamento!",
              "success"
            );

            isModalOrderDetailVisible && setIsModalOrderDetailVisible(false);
            setDetails({
              type: "",
              data: {},
            });

            getOrdersInProgress(pageInProgress, queryProgress, false);
            getOrdersFinished(pageFinished, queryFinished, false);
          } catch (err) {
            if (err.response?.status === 401) {
              fireAction(
                "Ops, algo deu errado!",
                "Você não tem permissão para realizar esta ação",
                "error"
              );
            } else {
              fireAction(
                "Ops, algo deu errado!",
                "Falha ao reverter o status do pedido",
                "error"
              );
            }
          }
          setIsSubmitting(false);
        }
      });
  }

  const handleSubmit = async (values) => {
    try {
      await api.post("/orders", values);

      fireAction(
        "Tudo certo!",
        "O cadastro da nova ordem de serviço foi realizado com sucesso",
        "success"
      );

      getOrdersInProgress(pageInProgress);
    } catch (err) {
      fireAction(
        "Ops, algo deu errado!",
        "Falha durante o cadastro da nova ordem de serviço.",
        "error"
      );

      console.log("ORDER-CREATE: " + err);
    }
    setIsSubmitting(false);
  };

  const handlePrintOrdersInQuery = async () => {
    try {
      const { data } = await api.get(
        `/orders?qs=${queryFinished}&status=completed&page=1&limit=100`
      );

      const filtered = data?.data?.filter((order) => order.paid === false);

      console.log(filtered);
      setDetails({
        type: "MULTIPLE_ORDERS",
        data: filtered,
      });

      window.print();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ToPrint id="to-print">
        <ContainerToPrint>
          <p>
            <strong>SEG LAVANDERIA</strong>
            <em>AV INDEPENDÊNCIA, 770</em>
            <em>59900-000 - PAU DOS FERROS - RN</em>
            <em>Fone (84) 3351-3504</em>
          </p>
          {details?.type === "ONE_ORDER" && (
            <>
              <div>
                <p>DADOS DO SERVIÇO</p>
                <p>
                  Código OS: <span>{details?.data?.code}</span>
                </p>
                <p>
                  Data/horário do pedido:{" "}
                  <span>
                    {formatDate(details?.data?.created_at, "dd-MM-yyyy-TT")}
                  </span>
                </p>
                <p>
                  Forma de pagamento: <span>{details?.data?.payment_type}</span>
                </p>
              </div>
              <div>
                <p>DADOS DO CLIENTE</p>
                <p>
                  {details?.data?.customer?.name} -{" "}
                  {details?.data?.customer?.id}
                </p>
                <address>
                  {`${
                    details?.data?.customer?.address?.address &&
                    details?.data?.customer?.address?.address
                  }.
            ${
              details?.data?.customer?.address?.cep &&
              details?.data?.customer?.address?.cep
            }.
            ${
              details?.data?.customer?.address?.neighborhood &&
              details?.data?.customer?.address?.neighborhood
            }.
            ${
              details?.data?.customer?.address?.city &&
              details?.data?.customer?.address?.city
            }.`}
                </address>
                <p>
                  Telefone:{" "}
                  <span>
                    {details?.data?.customer?.phone_number &&
                      formatPhone(details?.data?.customer?.phone_number)}
                  </span>
                </p>
                {details?.data?.notes && (
                  <p>
                    Observações: <span>{details?.data?.notes}</span>
                  </p>
                )}
              </div>
              <div className="resume">
                <p>RESUMO DA ORDEM</p>
                {details?.data?.items?.map((item, index) => {
                  if (item.total > 0) {
                    return (
                      <fieldset key={index}>
                        <p>
                          {item.service_id === null
                            ? `${item?.quantity} ${
                                item.product?.name
                              } - UN ${formatPrice(
                                item?.unit_price
                              )} - Total ${formatPrice(item?.total)}`
                            : `${item?.quantity} ${item.service?.type} "${
                                item.service?.description
                              }" - UN ${formatPrice(
                                item?.unit_price
                              )} - Total ${formatPrice(item?.total)}`}
                        </p>
                      </fieldset>
                    );
                  }
                })}
              </div>
              <div>
                <p>
                  <span>Total geral </span>
                  <span>
                    {formatPrice(
                      details?.data?.items?.reduce(
                        (total, current) => total + current.total,
                        0
                      )
                    )}
                  </span>
                </p>
              </div>
            </>
          )}
          {details?.type === "MULTIPLE_ORDERS" && (
            <div>
              <div>
                <p>RESUMO DE ENCARGOS</p>
              </div>
              <div>
                {details?.data?.map((order) => (
                  <div key={order.code}>
                    <p>
                      Cliente:{" "}
                      <span>
                        {order?.customer?.name} - {order?.customer?.id}
                      </span>
                    </p>
                    {order?.customer?.phone_number && (
                      <p>
                        Contato: <span>{order?.customer?.phone_number}</span>
                      </p>
                    )}

                    <p>
                      Código OS: <span>{order?.code}</span>
                    </p>
                    <p>
                      Data/horário do pedido:{" "}
                      <span>
                        {formatDate(order?.created_at, "dd-MM-yyyy-TT")}
                      </span>
                    </p>
                    <p>
                      Forma de pagamento: <span>{order?.payment_type}</span>
                    </p>
                    <p>
                      Valor: <span>{formatPrice(order?.total)}</span>
                    </p>
                  </div>
                ))}
              </div>
              <div>
                <p>
                  <span>Total geral </span>
                  <span>
                    {formatPrice(
                      details?.data?.reduce(
                        (total, current) => total + current.total,
                        0
                      )
                    )}
                  </span>
                </p>
              </div>
            </div>
          )}
          <div>
            <p>
              COMPROVANTE DE SERVIÇOS <br /> SOLICITADOS
            </p>
            <p>
              <em>
                O prazo para recolhimento das peças deixadas em nossa lavanderia
                é de no máximo 90 dias (três meses).
              </em>
            </p>
          </div>
        </ContainerToPrint>
      </ToPrint>
      {/*  */}
      {/*  */}
      {/* END CONTAINER TO PRINT */}
      <Dashboard>
        <ModalOrderRegister
          visible={isModalOrderRegisterVisible}
          handleToggleVisibility={handleToggleModalOrderRegisterVisibility}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
        <ModalOrderDetail
          visible={isModalOrderDetailVisible}
          handleToggleVisibility={handleToggleModalOrderDetailVisibility}
          details={details.data}
          sendMessageOnWpp={sendMessageOnWpp}
          handleFinished={closeOrder}
          handleDelete={handleDelete}
          handlePayment={handleTogglePayment}
          handleRevert={handleRevertOrder}
          isSubmitting={isSubmitting}
        />
        <Container>
          <Header>
            <h1>Pedidos</h1>
            <ButtonsContainer>
              <Button
                primary
                sm
                onClick={handleToggleModalOrderRegisterVisibility}
              >
                Ordem de Pedido
              </Button>
              {/*  */}
              <Button tertiary sm onClick={handleToggleSearchbarVisibility}>
                {isSearchbarVisible ? "Fechar busca" : "Buscar pedido"}
              </Button>
            </ButtonsContainer>
          </Header>
          {/*  */}
          <Search
            visible={isSearchbarVisible}
            handleSubmit={handleSearchQuery}
            name="Pesquisar por código ou nome do cliente..."
            value={searchValue}
            handleChange={setSearchValue}
          />

          <div className={classes.demo1}>
            <AntTabs value={selectedTab} onChange={handleChangeSelectedTab}>
              <AntTab label="Pedidos em Andamento" />
              <AntTab label="Pedidos Entregues" />
            </AntTabs>
          </div>
          {selectedTab === 1 && (
            <Report>
              <em>
                Para imprimir o relatório de resumo de encargos basta realizar
                uma busca por termo, nome do cliente ou seu código.
              </em>
              <Button
                tertiary
                sm
                onClick={handlePrintOrdersInQuery}
                disabled={!queryFinished || selectedTab === 0}
              >
                Imprimir relatório
              </Button>
            </Report>
          )}

          {selectedTab === 0 && (
            <>
              <TableDemandProgress
                details={handleToggleModalOrderDetailVisibility}
                demands={ordersInProgress}
                handleDetail={handleClickDetails}
                handleFinished={closeOrder}
                handleDelete={handleDelete}
                handlePayment={handleTogglePayment}
                isSubmitting={isSubmitting}
              />
              {lastPageInProgress > 1 && (
                <TabNavigation>
                  <PaginationTab
                    count={lastPageInProgress}
                    page={pageInProgress}
                    handleChange={(event, value) => {
                      setPageInProgress(value);
                    }}
                  />
                </TabNavigation>
              )}
            </>
          )}
          {selectedTab === 1 && (
            <>
              <TableDemandFinished
                details={handleToggleModalOrderDetailVisibility}
                demands={ordersFinished}
                handleDetail={handleClickDetails}
                handlePayment={handleTogglePayment}
                handleRevert={handleRevertOrder}
              />
              {lastPageFinished > 1 && (
                <TabNavigation>
                  <PaginationTab
                    count={lastPageFinished}
                    page={pageFinished}
                    handleChange={(event, value) => {
                      setPageFinished(value);
                    }}
                  />
                </TabNavigation>
              )}
            </>
          )}
        </Container>
      </Dashboard>
    </>
  );
}

export default Demand;
