import React from "react";

import { Container, Content } from "./styles";

import LateralMenu from "../../organisms/LateralMenu";
import Navbar from "../../organisms/Navbar";
import Page from "../Page";

function Dashboard({ children }) {
  return (
    <Container>
      <Navbar />
      <Content>
        <LateralMenu />
        <Page>{children}</Page>
      </Content>
    </Container>
  );
}

export default Dashboard;
