import React from "react";
import PropTypes from "prop-types";

import { formatDate } from "../../utils/date-format";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Confirm,
  Actions,
} from "./styles";
import { Button } from "../../atoms/Button";
import HeaderInfo from "../../pages/CashFlow/HeaderInfo";

ModalCloseDay.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

function ModalCloseDay({
  visible,
  handleToggleVisibility,
  handleSubmit,
  isSubmitting,
  date,
}) {
  /* --------- */

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Fechar Caixa do Dia</Title>
        <HeaderInfo isModal date={date}>
          <Confirm>
            <p>
              Você confirma o fechamento do caixa?
              <span>{formatDate(date, "day dd-MM-yyyy")}</span>
            </p>
          </Confirm>
        </HeaderInfo>

        <Actions>
          <Button tertiary sm onClick={handleSubmit} disabled={isSubmitting}>
            Sim
          </Button>
          <Button
            tertiary
            sm
            onClick={handleToggleVisibility}
            disabled={isSubmitting}
          >
            Não
          </Button>
        </Actions>
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalCloseDay;
