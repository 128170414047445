import React, { useState } from "react";
import { Formik, Form as FormFormik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { useHistory } from "react-router-dom";
import { Toast } from "../../utils/swal";
import { fireAction } from "../../utils/swal";

import api from "../../services/api";

import { Button } from "../../atoms/Button";
import Icon from "../../atoms/Icon";

import FieldCustom from "../../organisms/FieldCustom";

import validations from "./validations";

import logo from "../../assets/logomarca.png";
import * as S from "./styles";

function ChangePassword() {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(
    false
  );

  function handleToggleNewPasswordVisible() {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  }
  function handleTogglePasswordVisible() {
    setIsPasswordVisible(!isPasswordVisible);
  }
  function handleTogglePasswordConfirmVisible() {
    setIsPasswordConfirmVisible(!isPasswordConfirmVisible);
  }

  const initialValues = {
    password: "",
    newPassword: "",
    passwordConfirmation: "",
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const { password, newPassword, passwordConfirmation } = values;

    try {
      const { data } = await api.put("/change-password", {
        password_old: password,
        password: newPassword,
        password_confirmation: passwordConfirmation,
      });

      if (data === "ok") {
        Toast.fire({
          icon: "success",
          title:
            "Senha alterada com sucesso. Da próxima vez que logar, use sua nova senha.",
        }).then(() => {
          history.goBack();
        });
      }
    } catch (err) {
      fireAction(
        "Erro",
        "Falha ao redefinir sua senha, senha antiga não corresponde.",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <S.Container>
      <S.CardContainer>
        <S.Logo>
          <img src={logo} alt="Seg Lavanderia" />
        </S.Logo>
        <S.FormContainer>
          <S.Title>
            <h1>Alterar Senha</h1>
            <p>Cadastre sua nova senha de acesso ao sistema.</p>
          </S.Title>
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values).then(() =>
                resetForm({
                  password: "",
                  newPassword: "",
                  passwordConfirmation: "",
                })
              );
            }}
          >
            {({ dirty, isValid, touched, errors, values }) => (
              <FormFormik>
                <FieldCustom
                  name="password"
                  title="Senha"
                  placeholder="Digite sua senha..."
                  touched={touched.password}
                  errors={errors.password}
                  value={values.password}
                  type={isPasswordVisible ? "text" : "password"}
                  icon={
                    <Icon handleClick={handleTogglePasswordVisible}>
                      {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                    </Icon>
                  }
                />

                <FieldCustom
                  name="newPassword"
                  title="Nova senha"
                  placeholder="Digite sua nova senha..."
                  touched={touched.newPassword}
                  errors={errors.newPassword}
                  value={values.newPassword}
                  type={isNewPasswordVisible ? "text" : "password"}
                  icon={
                    <Icon handleClick={handleToggleNewPasswordVisible}>
                      {isNewPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                    </Icon>
                  }
                />

                <FieldCustom
                  name="passwordConfirmation"
                  title="Confirme a senha"
                  placeholder="Confirme sua nova senha..."
                  touched={touched.passwordConfirmation}
                  errors={errors.passwordConfirmation}
                  value={values.passwordConfirmation}
                  type={isPasswordConfirmVisible ? "text" : "password"}
                  icon={
                    <Icon handleClick={handleTogglePasswordConfirmVisible}>
                      {isPasswordConfirmVisible ? <FaEyeSlash /> : <FaEye />}
                    </Icon>
                  }
                />

                <Button
                  disabled={!dirty || !isValid || isSubmitting}
                  type="submit"
                  mt="30"
                  primary
                  upper
                  sm
                  fullWidth
                >
                  Alterar
                </Button>
              </FormFormik>
            )}
          </Formik>
        </S.FormContainer>
      </S.CardContainer>
    </S.Container>
  );
}

export default ChangePassword;
