import styled from "styled-components";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: ${Metrics.radiusCircle};
  display: grid;
  place-items: center;
  transition: background 0.2s ease-out;
  cursor: pointer;

  &:hover {
    background: ${Colors.lightGrey};
    > svg {
      color: ${Colors.lightBlue}!important;
    }
  }

  &:active:not(:disabled) {
    background: ${Colors.lightBlue};
    > svg {
      color: ${Colors.white}!important;
    }
  }
`;
