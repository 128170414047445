import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import api from "../../services/api";
import { formatDate } from "../../utils/date-format";

import TableClientsDefaulters from "../TableClientsDefaulters";
import PaginationTab from "../../atoms/PaginationTab";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  SubTitle,
  TabNavigation,
} from "./styles";

ModalClientsDefaulters.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
};

function ModalClientsDefaulters({ visible, handleToggleVisibility, date }) {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [customers, setCustomers] = useState([]);

  const getCustomersList = useCallback(async (date = "", page) => {
    const { data } = await api.get(
      `/dashboard/defaulter-customers-list?day=${formatDate(
        date,
        "yyyy-MM-dd"
      ).replaceAll("/", "-")}&page=${page}&limit=5`
    );
    setCustomers(data.data);
    setLastPage(data.meta?.last_page);
  }, []);

  useEffect(() => {
    getCustomersList(date, page);
  }, [date, getCustomersList, page]);

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Clientes inadimplentes ({formatDate(date, "dd-MM-yyyy")})</Title>
        <SubTitle>
          Um cliente inadimplente é aquele que possui ao menos um pedido na
          plataforma Seg Lavanderia com status finalizado mas que ainda não foi
          pago.
        </SubTitle>
        <TableClientsDefaulters customers={customers} />
        {lastPage > 1 && (
          <TabNavigation>
            <PaginationTab
              count={lastPage}
              page={page}
              handleChange={(event, value) => {
                setPage(value);
              }}
            />
          </TabNavigation>
        )}
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalClientsDefaulters;
