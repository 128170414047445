import styled from "styled-components";

import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";
import Metrics from "../../settings/metrics";

export const Container = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;

  max-width: 400px;
  width: 100%;

  input {
    width: 100%;
    padding-right: ${Metrics.spaceXl};

    @media (max-width: 768px) {
      padding-right: calc(${Metrics.spaceXl} - 30px);
    }
  }
`;

export const ButtonsControl = styled.div`
  position: absolute;
  display: flex;
  z-index: 20;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background: transparent;
    margin-right: calc(${Metrics.space} - 2px);
    border-radius: ${Metrics.radiusCircle};
    font-size: calc(${Fonts.textXs} + ${Fonts.textSm});
    color: #fff;
    font-weight: bold;
    color: ${Colors.darkBlue};
    cursor: pointer;

    transition: background 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
      color 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      background: ${Colors.lightGrey};
      color: ${Colors.lightBlue};
    }
    &:active {
      background: ${Colors.lightBlue};
      color: ${Colors.white};
    }

    > svg {
      stroke-width: 3px;
    }
  }
`;
