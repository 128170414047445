import styled from "styled-components";

import { FiChevronLeft } from "react-icons/fi";

import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";
import Metrics from "../../settings/metrics";

export const Container = styled.div`
  overflow: hidden;
  margin-bottom: 40px;
  min-height: 100vh;
  height: auto;
  padding: ${Metrics.spaceXs};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  > h1 {
    color: ${Colors.lightBlue};
    font-size: ${Fonts.titleXl};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
  }
`;

export const Actions = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const PickerContainer = styled.div`
  width: 100%;
  max-width: 300px;
`;

export const GraphicContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  > h3 {
    margin: 10px 0;
    color: ${Colors.darkGrey};
    font-size: ${Fonts.titleSm};
  }
`;

export const GraphicsGrid = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  grid-gap: 5px;
  padding-bottom: 70px;
  padding-top: 20px;

  @media (max-width: 790px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

export const GoBack = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${Colors.darkBlue};
  font-size: ${Fonts.textMd};
  padding: ${Metrics.spaceSm};
`;

export const IconGoBack = styled(FiChevronLeft)`
  color: ${Colors.darkBlue};
  width: 24px;
  height: 240x;
`;
