import styled, { css } from "styled-components";

import { FiX } from "react-icons/fi";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";
import Fonts from "../../settings/fonts";

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1299;

  display: ${(props) => (props.visible ? "flex" : "none")};

  justify-content: center;

  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ModalContent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  padding: ${Metrics.space} ${Metrics.spaceLg};

  max-width: 500px;
  width: 100%;
  min-height: 320px;

  border-radius: ${Metrics.radiusSm};
  background: ${Colors.white};
  margin: ${Metrics.space} 0;

  fieldset {
    margin: ${Metrics.space} auto;

    legend {
      color: ${Colors.darkGrey};
      font-size: ${Fonts.textLg};
      font-weight: ${Fonts.boldWeight};
      margin: ${Metrics.space} 0;
    }
  }
  @media (max-width: 600px) {
    max-width: 500px;
    width: 94%;
    padding: ${Metrics.space};
  }
`;

export const Title = styled.h4`
  font-size: ${Fonts.titleMd};
  color: ${Colors.lightBlue};

  @media (max-width: 404px) {
    width: 180px;
  }
`;

export const Form = styled.form`
  margin-top: ${Metrics.spaceSm};
  display: flex;
  flex-direction: column;
`;
/*  */
/*  */
/*  */
/* Close Modal Btn */
export const CloseModal = styled.button`
  background: none;
  border: none;

  position: absolute;
  right: ${Metrics.space};
  top: ${Metrics.space};
`;

const iconCSS = css`
  width: 24px;
  height: 24px;
  color: ${Colors.darkBlue};
`;

export const IconCloseModal = styled(FiX)`
  ${iconCSS};
  stroke-width: 4px;
`;

export const HeaderGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: ${Metrics.spaceSm};
  margin-top: ${Metrics.space};

  > p {
    display: flex;
    flex-direction: column;
    font-weight: ${Fonts.boldWeight};
    font-size: ${Fonts.textLg};
    color: ${Colors.darkGrey};

    span {
      color: ${Colors.black};
      font-size: ${Fonts.titleSm};
    }

    @media (max-width: 800px) {
      margin-bottom: ${Metrics.space};

      span {
        font-weight: ${Fonts.boldWeight};
        font-size: ${Fonts.textLg};
      }
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > p {
    font-weight: ${Fonts.boldWeight};
    font-size: ${Fonts.textLg};
    color: ${Colors.darkGrey};
    display: flex;
    flex-wrap: wrap;
    flex: 0;
  }
  > p + p {
    margin-top: calc(${Metrics.spaceSm} + ${Metrics.spaceXs});
  }
`;

export const Confirm = styled(Info)`
  margin: ${Metrics.space} 0;

  > p {
    font-weight: ${Fonts.semiBoldWeight};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > span {
      font-weight: ${Fonts.boldWeight};
    }
  }
`;

export const InputInfo = styled.p`
  span {
    color: ${Colors.lightBlue};
    margin-left: ${Metrics.spaceXs};
  }
`;

export const OutputInfo = styled.p`
  span {
    color: ${Colors.orange};
    margin-left: ${Metrics.spaceXs};
  }
`;

export const Gain = styled.p`
  span {
    color: ${Colors.success};
    position: relative;
    margin-left: ${Metrics.spaceXs};

    em {
      margin-left: ${Metrics.spaceSm};
      font-size: ${Fonts.textSm};
      letter-spacing: ${Fonts.titleSpacingX};
      text-transform: uppercase;
      font-weight: ${Fonts.boldWeight};
      font-style: normal;
    }
  }
`;

export const Losses = styled.p`
  span {
    color: ${Colors.warn};
    position: relative;
    margin-left: ${Metrics.spaceXs};

    em {
      margin-left: ${Metrics.spaceSm};
      font-size: ${Fonts.textSm};
      letter-spacing: ${Fonts.titleSpacingX};
      text-transform: uppercase;
      font-weight: ${Fonts.boldWeight};
      font-style: normal;
    }
  }
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${Metrics.space};
  justify-content: space-around;
  align-items: center;

  @media (max-width: 380px) {
    flex-direction: column;

    > button {
      width: 100%;
      margin-top: ${Metrics.spaceSm};
    }
  }
`;
