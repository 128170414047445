import React from "react";

import { AvatarContainer } from "./styles";

function Avatar({ profile }) {
  return (
    <AvatarContainer>
      <img
        src={
          profile ||
          "https://directemployers.org/wp-content/uploads/2018/08/avatar-JaneDoe-300x300.jpg"
        }
        alt="profile"
      />
    </AvatarContainer>
  );
}

export default Avatar;
