import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

const materialTheme = createMuiTheme({
  typography: {
    body1: {
      fontSize: "18px",
      fontFamily: "Source Sans Pro",
    },

    body2: {
      fontSize: "14px",
      fontFamily: "Source Sans Pro",
    },
    caption: {
      fontSize: "14px",
      fontFamily: "Source Sans Pro",
    },
  },

  overrides: {
    MuiInputBase: {
      root: {
        maxWidth: "400px",
        width: "100%",

        "@media(max-width: 768px)": {
          maxWidth: "400px",
          width: "100%",
        },
      },

      input: {
        boxSizing: "border-box",
        width: "100%",
        height: "50px",
        borderRadius: "15px",
        padding: "15px 20px",
        fontWeight: 600,
        fontSize: "16px",
        boxShadow: "0 3px 6px #00000033",
        position: "relative",

        "&::placeholder": {
          color: "#000",
          lineHeight: "20px",
          textAlign: "left",
          fontWeight: "regular!important",
          fontStyle: "italic",
        },
      },
    },

    MuiPaper: {
      root: {
        top: "172px!important",

        "@media(max-width: 401px)": {
          maxWidth: "400px",
          width: "100%",
          left: "0 !important",
        },
      },
    },

    MuiSelect: {
      /*  position: "relative", */

      select: {
        maxWidth: "400px",
        width: "100%",
        fontWeight: "regular",
        textTransform: "initial",

        "@media(max-width: 768px)": {
          maxWidth: "400px",
          width: "100%",
        },
      },

      icon: {
        width: 28,
        height: 28,
        top: "bottom(50% - 14px)",
        margin: "auto",
        marginRight: 28,
        borderRadius: "50%",
        color: "#005D89",
        transition: "all .2s ease-out",
      },
      iconOpen: {
        backgroundColor: "#0CBCE4",
        color: "#fff",
      },
    },

    MuiMenu: {
      /* "@media(max-width: 768px)": {
        maxWidth: "400px",
        width: "100%",
      }, */

      paper: {
        maxWidth: "400px",
        width: "100%",
        boxSizing: "border-box",
        padding: 5,
        borderRadius: 15,
      },
    },

    MuiListItem: {
      /* "@media(max-width: 768px)": {
        maxWidth: "400px",
        width: "100%",
      }, */

      root: {
        maxWidth: "400px",
        width: "100%",
        height: "40px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
      },

      button: {
        "&:active": {},
        "&:first-child": {},
        "&:hover:first-child": {
          backgroundColor: "initial",
        },
        "&:hover:not(:first-child)": {
          backgroundColor: "#0cbce4",
          color: "#fff",
        },
      },
    },
  },
});

export default materialTheme;
