export default {
  // ========= Grays
  lightGrey: "#f2f2f2",
  grey: "#cccccc",
  darkGrey: "#484848",
  greyShadow: "#00000033",

  // ========= UI
  orange: "#ffc06a",
  lightBlue: "#0cbce4",
  darkBlue: "#005d89",

  darkBlueHover: "#004C6F",

  black: "#000000",
  white: "#ffffff",

  // ========= Alerts
  lightAlert: "#fff2cc",
  alert: "#ffd34d",
  darkAlert: "#a88721",

  lightWarn: "#ffc3c2",
  warn: "#f56662",
  darkWarn: "#cc2c29",

  lightSuccess: "#ccedab",
  success: "#8ecc51",
  darkSuccess: "#6b915c",
};
