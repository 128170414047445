import React from "react";

import { ThemeProvider } from "@material-ui/styles";
import materialTheme from "./overrides";
import { FiChevronDown } from "react-icons/fi";

import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

/* ================= */

function SelectCustom({
  data,
  name,
  placeholder,
  value,
  handleBlur,
  handleChange,
  labelSecondary,
}) {
  const BootstrapInput = withStyles((theme) => ({
    input: {
      borderRadius: 15,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #fff",
      height: "50px",
      fontSize: 16,
      padding: "15px 20px",
      color: "#000",

      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "Source Sans Pro",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      "&:focus": {
        outline: "none",
        borderRadius: 15,
        backgroundColor: theme.palette.background.paper,
      },
    },
  }))(InputBase);

  return (
    <ThemeProvider theme={materialTheme}>
      <FormControl>
        <Select
          required
          name={name}
          value={value}
          displayEmpty
          onChange={handleChange}
          onBlur={handleBlur}
          input={<BootstrapInput />}
          IconComponent={FiChevronDown}
        >
          <MenuItem value="" disabled selected>
            <em>{placeholder || "Selecione uma opção"}</em>
          </MenuItem>
          {data &&
            data.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

export default SelectCustom;
