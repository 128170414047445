import styled from "styled-components";

import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";
import Metrics from "../../settings/metrics";

import { FaUsersCog } from "react-icons/fa";

export const Container = styled.div`
  overflow: hidden;
  margin-bottom: 40px;
  padding: ${Metrics.spaceXs};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    color: ${Colors.lightBlue};
    font-size: ${Fonts.titleXl};
  }

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderControl = styled.div`
  display: flex;
  margin-top: ${Metrics.spaceLg};

  > h2 {
    color: ${Colors.lightBlue};
    font-size: ${Fonts.titleMd};
    display: flex;
    align-items: center;

    @media (max-width: 310px) {
      font-size: ${Fonts.titleSm};
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const IconControls = styled(FaUsersCog)`
  width: 24px;
  height: 24px;
  color: ${Colors.lightBlue};
  margin-right: ${Metrics.spaceXs};

  @media (max-width: 310px) {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-left: ${Metrics.spaceXl};

  > button {
    margin-right: ${Metrics.spaceLg};

    @media (max-width: 758px) {
      margin-right: 0;
      margin-top: ${Metrics.spaceSm};
    }
  }

  @media (max-width: 758px) {
    margin-left: 0;
    max-width: 400px;
    width: 100%;
    flex-direction: column;
  }
`;

export const TabNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: ${Metrics.space};
`;
