import * as yup from "yup";

const validations = yup.object().shape({
  name: yup.string().required("Campo não-preenchido"),
  email: yup.string().email("Campo inválido").required("Campo não-preenchido"),
  password: yup.string().required("Campo não-preenchido"),
  role: yup.string().required("Campo não-preenchido"),
});

export default validations;
