const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const formatterWithoutSymbol = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export function formatPrice(number) {
  return formatter.format(number);
}

export function formatToUpdate(price) {
  return formatterWithoutSymbol.format(price).replace("R$", "").trim();
}
