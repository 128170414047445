import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { logout, erasedInfoAccount } from "../../services/auth";
import { Toast } from "../../utils/swal";
import convertRoleToPtBR from "../../utils/profile-access";

import { useStore } from "../../store";

/*  */
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
/*  */

import {
  Navbar as NavbarContainer,
  LeftSide,
  RightSide,
  Logo,
  Profile,
  LogoutIcon,
  KeyIcon,
  IconProfileArrow,
} from "./styles";

import logoImg from "../../assets/logomarca.png";
import Avatar from "../../molecules/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  menuKey: {
    fontWeight: 700,
    color: "#005d89",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
  },
  menuLogout: {
    fontWeight: 700,
    color: "#cc2c29",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
  },
}));

function Navbar() {
  const { state } = useStore();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  /* ====================== */

  const history = useHistory();

  function handleLogout() {
    Toast.fire({
      icon: "success",
      title: "Logout realizado. Até breve!",
    });

    logout();
    erasedInfoAccount();

    history.push("/");
  }

  function handleChangePassword() {
    history.push("/change-password");
  }

  return (
    <NavbarContainer>
      <LeftSide>
        <Logo>
          <img src={logoImg} alt="Seg Lavanderia" />
        </Logo>
      </LeftSide>
      <RightSide>
        <Profile>
          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Avatar />
            <p>
              <span className="user-logged">{state.name}</span>
              <span className="user-role">{convertRoleToPtBR(state.role)}</span>
            </p>
            <IconProfileArrow open={open} />
          </Button>
        </Profile>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          className={classes.root}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.menuLogout}
                    >
                      <LogoutIcon /> Sair do sistema
                    </MenuItem>
                    <MenuItem
                      onClick={handleChangePassword}
                      className={classes.menuKey}
                    >
                      <KeyIcon /> Alterar senha
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </RightSide>
    </NavbarContainer>
  );
}

export default Navbar;
