import styled from "styled-components";

import Colors from "../../../settings/colors";
import Fonts from "../../../settings/fonts";
import Metrics from "../../../settings/metrics";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  width: 100%;
  margin: ${Metrics.space} 0;
  grid-gap: ${Metrics.spaceSm};
`;

export const Card = styled.div`
  /* cursor: pointer; */
`;

export const CardContent = styled.div`
  box-shadow: 0 3px 6px ${Colors.greyShadow};
  border-radius: ${Metrics.radiusSm};

  padding: ${Metrics.spaceSm};
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-out;

  > p {
    font-size: ${Fonts.titleXl};
    display: flex;
    flex-direction: column;
    font-weight: ${Fonts.boldWeight};
    transition: all 0.2s ease-out;

    color: ${(props) => {
      if (props.customersActive) {
        return Colors.success;
      } else if (props.customersDefaulters) {
        return Colors.alert;
      } else if (props.receivable) {
        return Colors.warn;
      } else {
        return Colors.lightBlue;
      }
    }};

    > span {
      font-size: ${Fonts.textMd};
      font-weight: ${Fonts.regularWeight};
      color: ${Colors.black};
      transition: all 0.2s ease-out;
    }
  }

  &:hover {
    color: ${Colors.white};
    background: ${(props) => {
      if (props.customersActive) {
        return Colors.success;
      } else if (props.customersDefaulters) {
        return Colors.alert;
      } else if (props.receivable) {
        return Colors.warn;
      } else {
        return Colors.lightBlue;
      }
    }};

    > p {
      color: ${Colors.white};

      > span {
        color: ${Colors.white};
      }
    }
    > a {
      color: ${Colors.white};
    }
  }
`;

export const Link = styled.a`
  margin-top: ${Metrics.spaceSm};
  padding: ${Metrics.spaceSm} ${Metrics.spaceXs};
  border-radius: ${Metrics.radiusSm};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${Fonts.semiBoldWeight};
  font-size: ${Fonts.textLg};

  color: ${(props) => {
    if (props.customersActive) {
      return Colors.success;
    } else if (props.customersDefaulters) {
      return Colors.alert;
    } else if (props.receivable) {
      return Colors.warn;
    } else {
      return Colors.lightBlue;
    }
  }};
`;
