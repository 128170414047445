import React, { useState } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { Container } from "./styles.js";

import api from "../../services/api";
import AwesomeDebouncePromise from "awesome-debounce-promise";

const getClients = (value) => api.get(`/customers?qs=${value}&page=1&limit=10`);
const searchAPIDebounced = AwesomeDebouncePromise(getClients, 600);

export default function AutoSuggestClient({
  suggestItem,
  setSuggestItem,
  placeholder = "",
  value = "",
  setValue,
  name,
  handleChange,
  handleBlur,
}) {
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestionValue = (suggestion) => {
    setSuggestItem(suggestion);
    setValue(name, suggestion.name);

    return suggestion.name;
  };

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.id} - {suggestion.name}
    </div>
  );

  const onSuggestionsFetchRequested = async ({ value }) => {
    const newArray = await searchAPIDebounced(value);

    setSuggestions(newArray.data?.data);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const shouldRenderSuggestions = (value) => {
    return value.trim().length >= 0;
  };

  const inputProps = {
    placeholder,
    value,
    name,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <Container>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        highlightFirstSuggestion={true}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    </Container>
  );
}

AutoSuggestClient.propTypes = {
  suggestItem: PropTypes.object,
  setSuggestItem: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};
