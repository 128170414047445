import React from "react";
import PropTypes from "prop-types";

//services
import { Button } from "../../atoms/Button";
import { IconContainer } from "../../atoms/IconContainer";
import { formatPrice } from "../../utils/price-format";

import DataNotFound from "../../atoms/DataNotFound";

import {
  IconDelete,
  IconEdit,
  IconAcordeon,
  CardsGrid,
  Card,
  Key,
  Code,
  Value,
  ControlAcordeon,
  AccordionShow,
  Acordeon,
  Actions,
  Content,
} from "./styles";

TableProducts.propTypes = {
  products: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleBuy: PropTypes.func.isRequired,
  handleSale: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function TableProducts({
  products,
  handleDelete,
  handleUpdate,
  handleBuy,
  handleSale,
  isSubmitting,
}) {
  return (
    <>
      {products.length === 0 ? (
        <DataNotFound message="Nenhum resultado encontrado" />
      ) : (
        <CardsGrid>
          {products.map((product) => (
            <Card key={product.id}>
              <Code>
                Código &nbsp;<Value>{product.id}</Value>
              </Code>

              <AccordionShow type="checkbox" id={product.id} />
              <ControlAcordeon htmlFor={product.id}>
                <IconAcordeon />
              </ControlAcordeon>

              <Acordeon className="card">
                <Content>
                  <Code>
                    Código &nbsp;<Value>{product.id}</Value>
                  </Code>
                  <Key>
                    Descrição do produto &nbsp;<Value>{product.name}</Value>
                  </Key>
                  <Key>
                    Quantidade &nbsp;<Value>{product.stock}</Value>
                  </Key>
                  <Key>
                    Valor unitário &nbsp;
                    <Value>{formatPrice(product.unit_price)}</Value>
                  </Key>
                </Content>
                <Actions>
                  <Button cash sm onClick={() => handleBuy(product)}>
                    Registrar compra
                  </Button>
                  <Button secondary sm onClick={() => handleSale(product)}>
                    Registrar saída
                  </Button>
                  <div className="icons-container">
                    <IconContainer onClick={() => handleUpdate(product)}>
                      <IconEdit disabled={isSubmitting} />
                    </IconContainer>
                    <IconContainer onClick={() => handleDelete(product.id)}>
                      <IconDelete disabled={isSubmitting} />
                    </IconContainer>
                  </div>
                </Actions>
              </Acordeon>
            </Card>
          ))}
        </CardsGrid>
      )}
    </>
  );
}

export default TableProducts;
