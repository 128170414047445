import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormFormik } from "formik";

import { userRoles } from "../../utils/profile-access";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Form,
} from "./styles";
import { Button } from "../../atoms/Button";
import SelectCustom from "../../atoms/SelectCustom";

import FieldCustom from "../../organisms/FieldCustom";

import validations from "./validations";

ModalUpdateAccess.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function ModalUpdateAccess({
  profile,
  visible,
  handleToggleVisibility,
  handleSubmit,
  isSubmitting,
}) {
  const [initialValues, setInitialValues] = useState({
    id: "",
    name: "",
    email: "",
    role: "",
  });

  useEffect(() => {
    setInitialValues({
      id: profile.id ? profile.id : "",
      name: profile.name ? profile.name : "",
      email: profile.email ? profile.email : "",
      role: profile.role ? (profile.role === "admin" ? "" : profile.role) : "",
    });
  }, [profile]);

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Atualizar acesso</Title>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {({
            dirty,
            isValid,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
          }) => (
            <FormFormik as={Form}>
              <fieldset>
                <FieldCustom
                  name="name"
                  type="text"
                  title="Nome"
                  placeholder="Nome..."
                  touched={touched.name}
                  errors={errors.name}
                  value={values.name}
                />
                <FieldCustom
                  name="email"
                  type="text"
                  title="E-mail"
                  placeholder="E-mail..."
                  touched={touched.email}
                  errors={errors.email}
                  value={values.email}
                />
                <FieldCustom
                  required
                  component={SelectCustom}
                  name="role"
                  title="Perfil de acesso"
                  placeholder="Perfil de acesso..."
                  touched={touched.role}
                  errors={errors.role}
                  value={values.role}
                  data={userRoles}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                />
              </fieldset>
              <Button
                disabled={!dirty || !isValid || isSubmitting}
                type="submit"
                mt="30"
                primary
                upper
                sm
                fullWidth
              >
                Atualizar
              </Button>
            </FormFormik>
          )}
        </Formik>
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalUpdateAccess;
