import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import { useStore } from "../store";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { state } = useStore();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() &&
        (state.role === "admin" || state.role === "manager") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/clients", state: { from: props.location } }}
          />
        )
      }
    />
  );
}
