import React from "react";
import PropTypes from "prop-types";

//services
import { formatPrice } from "../../utils/price-format";

import { Button } from "../../atoms/Button";
import { IconContainer } from "../../atoms/IconContainer";
import DataNotFound from "../../atoms/DataNotFound";

import {
  IconDelete,
  CardsGrid,
  Card,
  Acordeon,
  AccordionShow,
  ControlAcordeon,
  Actions,
  Key,
  Value,
  IconAcordeon,
} from "./styles";

TableServices.propTypes = {
  services: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function TableServices({ services, handleDelete, handleUpdate, isSubmitting }) {
  return (
    <>
      {services.length === 0 ? (
        <DataNotFound message="Nenhum resultado encontrado" />
      ) : (
        <CardsGrid>
          {services.map((service) => (
            <Card key={service.id}>
              <Key>
                Código &nbsp;<Value>{service.id}</Value>
              </Key>
              <AccordionShow type="checkbox" id={service.id} />
              <ControlAcordeon htmlFor={service.id}>
                <IconAcordeon />
              </ControlAcordeon>
              <Acordeon className="card">
                <Key>
                  Tipo de serviço &nbsp;<Value>{service.type}</Value>
                </Key>
                <Key>
                  Descrição &nbsp;<Value>{service.description}</Value>
                </Key>
                {/* <Key>
                  Unidade &nbsp;<Value> {service.unit}</Value>
                </Key> */}
                <Key>
                  Preço unitário &nbsp;
                  <Value> {formatPrice(service.unit_price)}</Value>
                </Key>
                <Actions>
                  <Button
                    onClick={() => handleUpdate(service)}
                    disabled={isSubmitting}
                    tertiary
                    sm
                  >
                    Editar serviço
                  </Button>
                  <IconContainer onClick={() => handleDelete(service.id)}>
                    <IconDelete disabled={isSubmitting} />
                  </IconContainer>
                </Actions>
              </Acordeon>
            </Card>
          ))}
        </CardsGrid>
      )}
    </>
  );
}

export default TableServices;
