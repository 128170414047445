import * as yup from "yup";
import {
  cpfAndCnpjRegex,
  phoneAndFixedRegex,
  cepRegex,
} from "../../utils/regex";

const validations = yup.object().shape({
  name: yup.string().required("Campo não-preenchido"),
  cpf: yup.string().matches(cpfAndCnpjRegex, "Formato inválido"),
  phone_number: yup.string().matches(phoneAndFixedRegex, "Formato inválido"),
  /* email: yup.string().email("Campo inválido"), */
  cep: yup.string().matches(cepRegex, "Formato inválido"),
  city: yup.string(),
  neighborhood: yup.string(),
  address: yup.string(),
});

export default validations;
