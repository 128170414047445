export default {
  // ========= Body
  textXs: "10px",
  textSm: "12px",
  textMd: "14px",
  textLg: "16px",

  // ========= Títles
  titleXs: "16px",
  titleSm: "18px",
  titleMd: "24px",
  titleLg: "30px",
  titleXl: "36px",

  titleMax: "60px",
  titleMin: "12px",

  // ========= spacing and weight
  titleSpacingS: "1.2px",
  titleSpacingM: "1.8px",
  titleSpacingX: "2.4px",

  boldWeight: 900,
  regularWeight: 400,
  semiBoldWeight: 600,
};
