import styled from "styled-components";

import colors from "../../settings/colors";
import metrics from "../../settings/metrics";
import fonts from "../../settings/fonts";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  display: grid;
  place-items: center;
  padding: ${metrics.space} 0 ${metrics.spaceXl};
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.div`
  margin: ${metrics.space} 0;

  @media (max-width: 538px) {
    img {
      max-width: 280px;
    }
  }
`;

export const Title = styled.div`
  margin: ${metrics.space} 0 ${metrics.spaceLg};

  h1 {
    font-size: 24px;
    color: ${colors.lightBlue};
  }

  p {
    font-size: ${fonts.textLg};
    color: ${colors.black};
    margin-top: ${metrics.space};
  }
`;

export const FormContainer = styled.div`
  max-width: 480px;
  width: 94%;
  background: ${colors.white};
  box-shadow: 0 3px 6px ${colors.greyShadow};
  padding: ${metrics.space} ${metrics.spaceLg};
  border-radius: ${metrics.radiusSm};
`;
