import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "Application/Json; charset=utf-8",
  },
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const zone = () => {
    const zone = new Date().getTimezoneOffset() / 60;

    if (zone > 0) {
      if (zone.toString().length <= 1) {
        return `-0${zone}:00`;
      } else {
        return `-${zone}:00`;
      }
    } else {
      if (zone.toString().length <= 1) {
        return `+0${zone}:00`;
      } else {
        return `+${zone}:00`;
      }
    }
  };

  config.headers.timezone = zone();
  return config;
});

export default api;
