import React from "react";
import PropTypes from "prop-types";

import { Container, Wrapper, ButtonsControl } from "./styles";
import { FiPlus, FiMinus } from "react-icons/fi";
import { Input } from "../../atoms/Input";

InputNumber.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any.isRequired,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

function InputNumber({
  name,
  value,
  handleBlur,
  handleChange,
  increment,
  decrement,
  placeholder,
  type = "number",
}) {
  return (
    <Container>
      <Wrapper>
        <Input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ButtonsControl>
          <button type="button" className="plus" onClick={increment}>
            <FiPlus />
          </button>
          <button type="button" className="minus" onClick={decrement}>
            <FiMinus />
          </button>
        </ButtonsControl>
      </Wrapper>
    </Container>
  );
}

export default InputNumber;
