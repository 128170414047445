import styled, { css } from "styled-components";

import { FiX } from "react-icons/fi";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";
import Fonts from "../../settings/fonts";

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1299;

  display: ${(props) => (props.visible ? "flex" : "none")};

  justify-content: center;

  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ModalContent = styled.div`
  position: absolute;
  left: 50%;

  transform: translate(-50%, 0px);
  padding: ${Metrics.space} ${Metrics.spaceLg};

  max-width: 480px;
  width: 100%;
  min-height: 500px;

  border-radius: ${Metrics.radiusSm};
  background: ${Colors.white};
  margin: ${Metrics.space} 0 150px;

  fieldset {
    margin: ${Metrics.space} auto;

    legend {
      color: ${Colors.darkGrey};
      font-size: ${Fonts.textLg};
      font-weight: ${Fonts.boldWeight};
      margin: ${Metrics.space} 0;
    }
  }

  @media (max-width: 600px) {
    max-width: 500px;
    width: 94%;
    padding: ${Metrics.space};
  }
`;

export const Title = styled.h4`
  font-size: ${Fonts.titleMd};
  color: ${Colors.lightBlue};

  @media (max-width: 404px) {
    width: 180px;
  }
`;

export const Form = styled.form`
  margin-top: ${Metrics.spaceSm};
  display: flex;
  flex-direction: column;
`;

export const GroupContainer = styled.div`
  max-width: 400px;
  width: 100%;

  display: grid;
  grid-gap: ${Metrics.spaceMd};
  align-items: center;
  grid-template-columns: 85% 15%;

  @media (max-width: 600px) {
    grid-template-columns: 80% 20%;
  }
`;
/*
export const ProductCount = styled.div`
  width: 400px;
  margin: 0 auto ${Metrics.space};
  min-height: 50px;
  padding: 15px 20px;

  background: #fff;
  box-shadow: 0 3px 6px ${Colors.greyShadow};
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + div {
    margin-top: 20px auto;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 400px;
    padding: 10px 15px;
  }
`; */
export const Item = styled.div`
  width: 400px;
  margin: 0 auto ${Metrics.space};
  min-height: 50px;
  padding: 15px 20px;

  background: #fff;
  box-shadow: 0 3px 6px ${Colors.greyShadow};
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 100%;
    max-width: 400px;
    padding: 10px 15px;
  }
`;

export const ProductCount = styled.div`
  width: 400px;
  margin: 0 auto ${Metrics.space};
  min-height: 50px;
  padding: 15px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & + div {
    margin-top: 20px auto;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 400px;
    padding: 10px 15px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
  flex-wrap: wrap;
  max-width: 70%;

  span {
    color: ${Colors.darkGrey};
    margin-bottom: 10px;
  }
  p {
    color: ${Colors.lightBlue};
  }

  @media (max-width: 600px) {
    span {
      font-size: ${Fonts.textMd};
    }
    p {
      font-size: ${Fonts.textMd};
    }
  }
`;

export const Controls = styled.div`
  flex: 0;
  display: flex;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background: transparent;
    margin-right: calc(${Metrics.space} - 5px);
    border-radius: ${Metrics.radiusCircle};
    font-size: calc(${Fonts.textXs} + ${Fonts.textSm});
    color: #fff;
    font-weight: bold;
    color: ${Colors.darkBlue};
    cursor: pointer;

    transition: background 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
      color 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      background: ${Colors.lightGrey};
      color: ${Colors.lightBlue};
    }
    &:active {
      background: ${Colors.lightBlue};
      color: ${Colors.white};
    }

    > svg {
      stroke-width: 3px;
    }

    @media (max-width: 600px) {
      margin-right: ${Metrics.spaceSm};
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const PriceCustom = styled.div`
  display: flex;
  flex-direction: column;

  fieldset {
    margin: 0 auto;
  }
  em {
    margin: 0 0 ${Metrics.space};
    font-size: ${Fonts.textMd};
  }
`;

export const Total = styled.h4`
  color: ${Colors.darkBlue};
  margin: ${Metrics.space};
`;

/* Close Modal Btn */
export const CloseModal = styled.button`
  background: none;
  border: none;

  position: absolute;
  right: ${Metrics.space};
  top: ${Metrics.space};
`;

const iconCSS = css`
  width: 24px;
  height: 24px;
  color: ${Colors.darkBlue};
`;

export const IconCloseModal = styled(FiX)`
  ${iconCSS};
  stroke-width: 4px;
`;
