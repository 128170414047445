import { createMuiTheme } from "@material-ui/core";

const materialTheme = createMuiTheme({
  typography: {
    body1: {
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Source Sans Pro",
      textTransform: "uppercase",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 600,
      fontFamily: "Source Sans Pro",
    },
    caption: {
      fontSize: "14px",
      fontFamily: "Source Sans Pro",
    },
  },
  overrides: {
    pickerView: {
      borderRadius: "15px",
    },

    MuiTextField: {
      root: {
        maxWidth: "400px",
        width: "100%",
      },
    },

    MuiInputBase: {
      root: {
        maxWidth: "400px",
        width: "100%",

        "&::placeholder": {
          color: "#000",
          lineHeight: "20px",
          textAlign: "left",
          fontWeight: "regular",
          fontStyle: "italic",
        },
      },

      input: {
        boxSizing: "border-box",
        width: "100%",
        minWidth: 200,
        height: "50px",
        borderRadius: "15px",
        padding: "15px 20px",
        fontWeight: 600,
        fontSize: "16px",
        color: "#000",
        boxShadow: "0 3px 6px #00000033",

        "&::placeholder": {
          color: "#000",
          lineHeight: "20px",
          textAlign: "left",
          fontWeight: "regular",
          fontStyle: "italic",
        },

        "@media(max-width: 768px)": {
          maxWidth: "400px",
          width: "100%",
        },
      },
    },

    MuiInputAdornment: {
      positionEnd: {
        marginLeft: "-54px",
      },
    },

    MuiFormControl: {
      root: {
        "@media(max-width: 768px)": {
          maxWidth: "400px",
          width: "100%",
        },
      },
    },

    MuiIconButton: {
      root: {
        color: "#005D89",

        transition: "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

        "&:hover": {
          backgroundColor: "#F2F2F2",
          color: "#0CBCE4",
        },

        "&:active": {
          backgroundColor: "#0CBCE4",
          color: "#fff",
        },
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        display: "none",
      },
    },

    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#000",
        textTransform: "uppercase",
      },
      switchHeader: {
        color: "#141414",
      },
      iconButton: {
        color: "#005D89",

        "&:hover": {
          color: "#0CBCE4",
        },
      },
    },

    MuiPickersYear: {
      root: {
        color: "#000000",
        "&:hover": {
          backgroundColor: "#F2F2F2",
          color: "#0CBCE4",
        },
        "&:active": {
          backgroundColor: "#0CBCE4",
          color: "#F2F2F2",
        },
        transition: "all .2s ease-out",
      },

      yearSelected: {
        color: "#F2F2F2",
        backgroundColor: "#0CBCE4",
        fontSize: 16,
        fontWeight: 400,
      },
    },

    MuiPickersMonth: {
      root: {
        flex: "1 0 55px",
        height: "55px",
        boxSizing: "border-box",
        margin: 10,
        color: "#000000",
        borderRadius: "50%",
        transition: "all .2s ease-out",
        "&:hover": {
          backgroundColor: "#F2F2F2",
          color: "#0CBCE4",
        },
        "&:active": {
          backgroundColor: "#0CBCE4",
          color: "#F2F2F2",
        },
      },
      monthSelected: {
        color: "#F2F2F2",
        backgroundColor: "#0CBCE4",
        fontSize: 16,
        fontWeight: 400,
      },
    },

    // dias selecionáveis
    MuiPickersDay: {
      // um dia
      day: {
        color: "#141414",
        "&:hover": {
          backgroundColor: "#F2F2F2",
          color: "#0CBCE4",
        },
      },
      // o dia selecionado
      daySelected: {
        backgroundColor: "#0CBCE4",

        "&:hover": {
          backgroundColor: "#F2F2F2",
          color: "#0CBCE4",
        },
      },
      // dias desabilitados
      dayDisabled: {
        color: "rgba(0, 0, 0, 0.38)",
      },
      // dia atual
      current: {
        color: "#141414",
      },
    },
  },
});

export default materialTheme;
