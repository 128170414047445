import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import StoreProvider from "../store";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";

import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";

import Home from "../pages/Home";
import Clients from "../pages/Clients";
import Demand from "../pages/Demand";
import Services from "../pages/Services";
import Stock from "../pages/Stock";
import CashFlow from "../pages/CashFlow";
import Configurations from "../pages/Configurations";
import ChangePassword from "../pages/ChangePassword";

const NotFound = () => <h1>Essa página não existe</h1>;

function Routes() {
  return (
    <StoreProvider>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />

          <ProtectedRoute exact path="/home" component={Home} />
          <PrivateRoute path="/clients" component={Clients} />
          <PrivateRoute path="/services" component={Services} />
          <PrivateRoute path="/stock" component={Stock} />
          <PrivateRoute path="/demand" component={Demand} />

          <ProtectedRoute path="/configurations" component={Configurations} />
          {/* <ProtectedRoute path="/cash-flow" component={CashFlow} /> */}
          <PrivateRoute path="/cash-flow" component={CashFlow} />

          <PrivateRoute
            exact
            path="/change-password"
            component={ChangePassword}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    </StoreProvider>
  );
}
export default Routes;
