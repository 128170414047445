/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

//services
import api from "../../services/api";
import swal, { fireAction } from "../../utils/swal";

import { formatDate } from "../../utils/date-format";

/*


*/
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

/*


*/
/* ================= */

import Dashboard from "../../templates/Dashboard";
import { Button } from "../../atoms/Button";
import TableProducts from "../../organisms/TableProducts";
import PaginationTab from "../../atoms/PaginationTab";
import Search from "../../organisms/Search";

import ModalProductUpdate from "../../organisms/ModalProductUpdate";
import ModalProductRegister from "../../organisms/ModalProductRegister";
import ModalProductBuy from "../../organisms/ModalProductBuy";
import ModalProductSale from "../../organisms/ModalProductSale";

/* ================= */

import { Container, Header, ButtonsContainer, TabNavigation } from "./styles";

/*

*/

const AntTabs = withStyles({
  root: {
    width: "max-content",
    borderBottom: "1px solid #e8e8e8",
    marginTop: 20,
  },
  indicator: {
    backgroundColor: "#0CBCE4",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    "@media(max-width: 468px)": {
      maxWidth: 150,
    },
    fontWeight: "bold",
    color: "#005D89",
    opacity: 1,
    fontFamily: [
      '"Source Sans Pro"',
      '"sans-serif"',
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 16,
    transition: "color .2s ease-out",
    "&:hover": {
      color: "#094967",
    },
    "&$selected": {
      color: "#0CBCE4",
    },
    "&:focus": {
      color: "#0CBCE4",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: "#fff",
    "@media(max-width: 768px)": {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr)",
      gridGap: "10px",
      placeItems: "center",
    },
  },
}));

/*

*/
function Stock() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const inventory = React.useMemo(
    () => (selectedTab === 0 ? "consumo" : "venda"),
    [selectedTab]
  );

  const handleChange = (event, newValue) => {
    if (setSearchValue.length > 0) {
      setSearchValue("");
    }
    setSelectedTab(newValue);
  };

  /*  */
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [products, setProducts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [productToUpdate, setProductToUpdate] = useState({});

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [isModalRegisterVisible, setIsModalRegisterVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);

  /* ========== */
  /* MODAL REGISTER BUY */
  /* ========== */
  const [productToBuy, setProductToBuy] = useState({});
  const [isModalBuyVisible, setIsModalBuyVisible] = useState(false);

  const handleToggleModalBuyVisibility = () => {
    setIsModalBuyVisible(!isModalBuyVisible);
  };

  const handleBuyProduct = async (values) => {
    const { id, date, price, amount } = values;

    try {
      await api.put(`/products/${id}/supply`, {
        quantity: amount,
        value: price.replaceAll(".", "").replace(",", "."),
        date: `${formatDate(date, "yyyy-MM-dd").replaceAll("/", "-")}`, // ${new Date().toLocaleTimeString().slice(0, -3)}
      });

      handleToggleModalBuyVisibility();
      fireAction(
        "Tudo certo!",
        "O registro de compra do produto foi realizado",
        "success"
      );

      getProducts(page);
    } catch (err) {
      if (err.response.status === 401) {
        fireAction(
          "Ops, algo deu errado!",
          "Você não tem permissão para realizar esta ação",
          "error"
        );
      } else {
        fireAction(
          "Ops, algo deu errado!",
          "Falha ao registrar a compra do produto",
          "error"
        );
      }

      console.warn("BUY-PRODUCT:" + err);
    } finally {
      handleToggleModalBuyVisibility();
    }
  };

  /* ========== */
  /* MODAL REGISTER SALE */
  /* ========== */
  const [productToSale, setProductToSale] = useState({});
  const [isModalSaleVisible, setIsModalSaleVisible] = useState(false);

  const handleToggleModalSaleVisibility = () => {
    setIsModalSaleVisible(!isModalSaleVisible);
  };

  const handleSaleProduct = async (values) => {
    const { id, amount } = values;

    try {
      await api.put(`/products/${id}/out`, {
        quantity: amount,
      });

      handleToggleModalSaleVisibility();
      fireAction(
        "Tudo certo!",
        "O registro de saída do produto foi realizado",
        "success"
      );

      getProducts(page);
    } catch (err) {
      if (err.response.status === 403) {
        fireAction(
          "Ops, algo deu errado!",
          "Quantidade indisponível no estoque",
          "error"
        );
      } else if (err.response.status === 401) {
        fireAction(
          "Ops, algo deu errado!",
          "Você não tem permissão para realizar esta ação",
          "error"
        );
      } else {
        fireAction(
          "Ops, algo deu errado!",
          "Falha ao registrar a saída do produto",
          "error"
        );
      }
      console.warn("SALE-PRODUCT:" + err);
    } finally {
      handleToggleModalSaleVisibility();
    }
  };
  /* ================================== */

  const getProducts = React.useCallback(
    async (page, query = "") => {
      try {
        const { data } = await api.get(
          `/products?qs=${query}&page=${page}&limit=10&inventory=${inventory}`
        );
        setProducts(data.data);
        setLastPage(data.meta?.last_page);
      } catch (err) {
        console.warn("STOCK-GET:" + err);
      }
    },
    [inventory]
  );

  const handleUpdateProduct = async (values) => {
    setIsSubmitting(true);

    const { id, name, unit_price } = values;

    try {
      await api.put(`/products/${id}`, {
        id,
        name,
        unit_price: unit_price.replaceAll(".", "").replace(",", "."),
      });

      handleToggleModalUpdateVisibility();
      fireAction(
        "Tudo certo!",
        "O cadastro do produto foi atualizado",
        "success"
      );

      getProducts(page);
    } catch (err) {
      handleToggleModalUpdateVisibility();
      fireAction(
        "Ops, algo deu errado!",
        "Falha ao atualizar os dados do produto",
        "error"
      );
      console.warn("STOCK-UPDATE:" + err);
    }
    setIsSubmitting(false);
  };

  const handleRegisterProduct = async (values) => {
    setIsSubmitting(true);

    const { name, unit_price, stock, inventory } = values;

    try {
      await api.post("/products", {
        name: name,
        unit_price: unit_price.replaceAll(".", "").replace(",", "."),
        stock,
        inventory,
      });
      handleToggleModalRegisterVisibility();

      fireAction(
        "Tudo certo!",
        "O cadastro do novo produto foi realizado com sucesso",
        "success"
      );

      getProducts(page);
    } catch (err) {
      handleToggleModalRegisterVisibility();
      fireAction(
        "Ops, algo deu errado!",
        "O cadastro do novo produto falhou",
        "error"
      );
      console.warn("STOCK-NEW:" + err);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    getProducts(page);
  }, [getProducts, page]);

  /* ================================== */

  function handleToggleSearchVisible() {
    if (isSearchVisible) {
      setSearchValue("");
      getProducts(1, "");
    }
    setIsSearchVisible(!isSearchVisible);
  }

  function handleToggleModalUpdateVisibility() {
    setIsModalUpdateVisible(!isModalUpdateVisible);
  }

  function handleToggleModalRegisterVisibility() {
    setIsModalRegisterVisible(!isModalRegisterVisible);
  }

  /* ================================== */

  const handleDelete = async (id) => {
    setIsSubmitting(true);

    swal
      .fire({
        title: "Tem certeza que deseja excluir o produto?",
        text: "Esta ação não poderá ser revertida!",
        icon: "warning",
        customClass: {
          header: "header-alert",
          icon: "icon-alert",
          title: `title-alert-warning`,
          content: "description-alert",
        },
        showCancelButton: true,
        confirmButtonColor: "#6b915c",
        cancelButtonColor: "#cc2c29",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await api.delete(`/products/${id}`);

            fireAction(
              "Tudo certo!",
              "O produto foi removido da base de dados",
              "success"
            );

            const filtered = products.filter((product) => product.id !== id);
            setProducts(filtered);
            if (filtered.length === 0 && page > 1) {
              setPage((page) => page - 1);
            }
          } catch (err) {
            if (err.response?.status === 401) {
              fireAction(
                "Ops, algo deu errado!",
                "Você não tem permissão para realizar esta ação",
                "error"
              );
            } else {
              fireAction(
                "Ops, algo deu errado!",
                "Falha ao remover o produto da base de dados",
                "error"
              );
            }

            console.warn("SERVICE-DELETE:" + err);
          }
        }
      });

    setIsSubmitting(false);
  };

  const handleUpdate = (productToUpdate) => {
    const product = products.find(
      (product) => product.id === productToUpdate.id
    );
    setProductToUpdate(product);
    handleToggleModalUpdateVisibility();
  };

  const handleBuy = (productToUpdate) => {
    const product = products.find(
      (product) => product.id === productToUpdate.id
    );
    setProductToBuy(product);
    handleToggleModalBuyVisibility();
  };

  const handleSale = (productToUpdate) => {
    const product = products.find(
      (product) => product.id === productToUpdate.id
    );
    setProductToSale(product);
    handleToggleModalSaleVisibility();
  };

  // decidir por auto pesquisar ou por submit
  const handleSubmit = (e) => {
    e.preventDefault();

    getProducts(1, searchValue);
  };

  return (
    <Dashboard>
      <ModalProductRegister
        visible={isModalRegisterVisible}
        handleToggleVisibility={handleToggleModalRegisterVisibility}
        isSubmitting={isSubmitting}
        handleSubmit={handleRegisterProduct}
      />
      <ModalProductUpdate
        visible={isModalUpdateVisible}
        handleToggleVisibility={handleToggleModalUpdateVisibility}
        product={productToUpdate}
        isSubmitting={isSubmitting}
        handleSubmit={handleUpdateProduct}
      />
      <ModalProductBuy
        visible={isModalBuyVisible}
        handleToggleVisibility={handleToggleModalBuyVisibility}
        product={productToBuy}
        isSubmitting={isSubmitting}
        handleSubmit={handleBuyProduct}
      />
      <ModalProductSale
        visible={isModalSaleVisible}
        handleToggleVisibility={handleToggleModalSaleVisibility}
        product={productToSale}
        isSubmitting={isSubmitting}
        handleSubmit={handleSaleProduct}
      />
      <Container>
        <Header>
          <h1>Estoque</h1>
          <ButtonsContainer>
            <Button primary sm onClick={handleToggleModalRegisterVisibility}>
              Adicionar produto
            </Button>
            <Button tertiary sm onClick={handleToggleSearchVisible}>
              {isSearchVisible ? "Fechar busca" : "Buscar produto"}
            </Button>
          </ButtonsContainer>
        </Header>
        <Search
          visible={isSearchVisible}
          handleSubmit={handleSubmit}
          name="Pesquisar por código ou nome..."
          value={searchValue}
          handleChange={setSearchValue}
        />
        <div className={classes.demo1}>
          <AntTabs value={selectedTab} onChange={handleChange}>
            <AntTab label="Estoque de Consumo" />
            <AntTab label="Estoque de Venda" />
          </AntTabs>
        </div>
        {selectedTab === 0 && (
          <>
            <TableProducts
              products={products}
              handleUpdate={handleUpdate}
              handleBuy={handleBuy}
              handleSale={handleSale}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
            />
            {lastPage > 1 && (
              <TabNavigation>
                <PaginationTab
                  count={lastPage}
                  page={page}
                  handleChange={(event, value) => {
                    setPage(value);
                  }}
                />
              </TabNavigation>
            )}
          </>
        )}
        {selectedTab === 1 && (
          <>
            <TableProducts
              products={products}
              handleUpdate={handleUpdate}
              handleBuy={handleBuy}
              handleSale={handleSale}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
            />
            {lastPage > 1 && (
              <TabNavigation>
                <PaginationTab
                  count={lastPage}
                  page={page}
                  handleChange={(event, value) => {
                    setPage(value);
                  }}
                />
              </TabNavigation>
            )}
          </>
        )}
      </Container>
    </Dashboard>
  );
}

export default Stock;
