import React from "react";

import { Container } from "./styles";
import DatePickerCustom from "../../atoms/DatePickerCustom";

function SearchFilter({ dateFilter, setDateFilter, placeholder, ...rest }) {
  return (
    <Container>
      <DatePickerCustom
        currentValue={dateFilter}
        handleDateChange={setDateFilter}
        placeholder={placeholder}
        {...rest}
      />
    </Container>
  );
}

export default SearchFilter;
