import styled, { css } from "styled-components";

import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";
import Metrics from "../../settings/metrics";

export const Button = styled.button`
  border-radius: ${Metrics.radiusSm};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(${Metrics.spaceSm} - 2px) calc(${Metrics.spaceMd} + 5px);

  box-shadow: 0px 3px 6px ${Colors.greyShadow};
  transition: background 0.2s cubic-bezier(0.165, 0.84, 0.44, 1),
    color 0.2s cubic-bezier(0.77, 0, 0.175, 1),
    border 0.2s cubic-bezier(0.77, 0, 0.175, 1);

  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};

  /* ==========*/
  /* Hierarchy */
  /* ==========*/
  ${(props) =>
    props.primary &&
    css`
      background: ${Colors.darkBlue};
      border: 1px solid ${Colors.darkBlue};
      color: ${Colors.white};

      &:disabled {
        background: ${Colors.grey};
        border-color: ${Colors.grey};
      }
    `}

  ${(props) =>
    props.cash &&
    css`
      background: ${Colors.lightBlue};
      border: 1px solid ${Colors.lightBlue};
      color: ${Colors.white};

      &:disabled {
        background: ${Colors.grey};
        border-color: ${Colors.grey};
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: ${Colors.orange};
      border: 1px solid ${Colors.orange};
      color: ${Colors.white};

      &:disabled {
        background: ${Colors.grey};
        border-color: ${Colors.grey};
      }
    `}

  ${(props) =>
    props.tertiary &&
    css`
      background: ${Colors.white};
      color: ${Colors.lightBlue};
      border: 1px solid ${Colors.white};

      &:disabled {
        background: transparent;
        border-color: ${Colors.grey};
        color: ${Colors.grey};
      }
    `}
/* ===========*/
/* Variations */
/* ===========*/

  /* Floating */
  ${(props) =>
    props.floating &&
    css`
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: ${Metrics.radiusCircle};
      font-size: ${Fonts.titleSm};
    `}

  /* Small */
  ${(props) =>
    props.sm &&
    css`
      min-width: 150px;
      min-height: 40px;
      font-size: ${Fonts.titleSm};
      text-transform: capitalize;
    `}

  /* Medium */
  ${(props) =>
    props.md &&
    css`
      width: 300px;
      height: 50px;
      font-size: ${Fonts.titleLg};
    `}

  /* Capitalize */
  ${(props) =>
    props.cap &&
    css`
      text-transform: capitalize;
    `}

  /* Uppercase */
  ${(props) =>
    props.upper &&
    css`
      text-transform: uppercase;
      letter-spacing: ${Fonts.titleSpacingX};
    `}

    ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}


/* =============*/
/* Interactions */
/* =============*/

  &:hover:not(:disabled) {
    background: ${Colors.lightGrey};
    border-color: ${Colors.lightGrey};
    color: ${Colors.lightBlue};
  }

  &:active {
    background: ${Colors.lightBlue};
    border-color: ${Colors.lightBlue};
    color: ${Colors.white};
  }
`;
