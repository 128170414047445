import styled, { css } from "styled-components";

import Colors from "../../../settings/colors";
import Fonts from "../../../settings/fonts";
import Metrics from "../../../settings/metrics";

export const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr 1fr;
  grid-gap: ${Metrics.spaceXs};

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  padding: ${Metrics.spaceSm};
  margin-top: ${Metrics.space};

  > p {
    display: flex;
    flex-direction: column;
    font-weight: ${Fonts.boldWeight};
    font-size: ${Fonts.textLg};
    color: ${Colors.darkGrey};

    span {
      color: ${Colors.black};
      font-size: ${Fonts.titleSm};
    }

    @media (max-width: 800px) {
      margin-bottom: ${Metrics.space};

      span {
        font-weight: ${Fonts.boldWeight};
        font-size: ${Fonts.textLg};
      }
    }
  }

  ${(props) =>
    props.isModal &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      padding: ${Metrics.spaceSm};
      margin-top: ${Metrics.space};

      > p {
        display: flex;
        flex-direction: column;
        font-weight: ${Fonts.boldWeight};
        font-size: ${Fonts.textLg};
        color: ${Colors.darkGrey};

        span {
          color: ${Colors.black};
          font-size: ${Fonts.titleSm};
        }

        @media (max-width: 800px) {
          margin-bottom: ${Metrics.space};

          span {
            font-weight: ${Fonts.boldWeight};
            font-size: ${Fonts.textLg};
          }
        }
      }
    `};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > p {
    font-weight: ${Fonts.boldWeight};
    font-size: ${Fonts.textLg};
    color: ${Colors.darkGrey};
    display: flex;
    flex-wrap: wrap;
    flex: 0;
  }
  > p + p {
    margin-top: calc(${Metrics.spaceSm} + ${Metrics.spaceXs});
  }
`;

export const InputInfo = styled.p`
  span {
    color: ${Colors.lightBlue};
    margin-left: ${Metrics.spaceXs};
  }
`;

export const OutputInfo = styled.p`
  span {
    color: ${Colors.orange};
    margin-left: ${Metrics.spaceXs};
  }
`;

export const Gain = styled.p`
  span {
    color: ${Colors.success};
    position: relative;
    margin-left: ${Metrics.spaceXs};

    em {
      margin-left: ${Metrics.spaceSm};
      font-size: ${Fonts.textSm};
      letter-spacing: ${Fonts.titleSpacingX};
      text-transform: uppercase;
      font-weight: ${Fonts.boldWeight};
      font-style: normal;
    }
  }
`;

export const Losses = styled.p`
  span {
    color: ${Colors.warn};
    position: relative;
    margin-left: ${Metrics.spaceXs};

    em {
      margin-left: ${Metrics.spaceSm};
      font-size: ${Fonts.textSm};
      letter-spacing: ${Fonts.titleSpacingX};
      text-transform: uppercase;
      font-weight: ${Fonts.boldWeight};
      font-style: normal;
    }
  }
`;
