import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { fireAction } from "../../../utils/swal";
import { formatDate } from "../../../utils/date-format";
import { formatPrice } from "../../../utils/price-format";

import TableOutputCash from "../../../organisms/TableOutputCash";
import PaginationTab from "../../../atoms/PaginationTab";
import { Button } from "../../../atoms/Button";

import ModalOutputRegister from "../../../organisms/ModalOutputRegister";

import api from "../../../services/api";
import { useStore } from "../../../store";

import {
  Title,
  DateTime,
  Subtotal,
  TableBox,
  Actions,
  TabNavigation,
} from "./styles";

OutputCash.propTypes = {
  dateToFilter: PropTypes.any.isRequired,
};

function OutputCash({ dateToFilter }) {
  const { state, dispatch } = useStore();

  // eslint-disable-next-line no-unused-vars
  const [modalOutputRegister, setModalOutputRegister] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [outputCash, setOutputCash] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);

  const getOutputCash = React.useCallback(
    async (page, date = "") => {
      const { data } = await api.get(
        `/transactions/out?day=${formatDate(date, "yyyy-MM-dd").replaceAll(
          "/",
          "-"
        )}&page=${page}&limit=10`
      );

      dispatch({
        type: "SET_OUTPUT_TOTAL",
        outputTotal: data?.total,
      });

      setOutputCash(data.data);
      setLastPage(data.meta?.last_page);
    },
    [dispatch]
  );

  useEffect(() => {
    getOutputCash(page, dateToFilter);
  }, [page, getOutputCash, dateToFilter]);

  const handleToggleRegisterVisibility = () => {
    setModalOutputRegister(!modalOutputRegister);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const {
      cost_center_id,
      interested,
      description,
      payment_type,
      value,
    } = values;

    try {
      await api.post("/transactions/out", {
        payment_type,
        description,
        value: value.replaceAll(".", "").replaceAll(",", "."),
        date: formatDate(null, "yyyy-MM-dd TT").replaceAll("/", "-"),
        interested,
        cost_center_id,
      });

      dispatch({
        type: "SET_DIRTY_CASH",
        dirty: state.closed,
      });

      fireAction(
        "Tudo certo!",
        "O cadastro do nova saída foi realizado com sucesso",
        "success"
      );
    } catch (err) {
      fireAction(
        "Ops, algo deu errado!",
        "O cadastro da nova saída falhou",
        "error"
      );
      console.log("REGISTER OUTPUT:" + err);
    }
    getOutputCash(page, dateToFilter);
    setIsSubmitting(false);
  };

  return (
    <>
      <ModalOutputRegister
        visible={modalOutputRegister}
        handleToggleVisibility={handleToggleRegisterVisibility}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <TableBox>
        <Actions>
          <Title cashType="outputCash">
            Saídas
            <DateTime cashType="outputCash">
              {formatDate(dateToFilter, "day dd-MM-yyyy")}
            </DateTime>
          </Title>
          <Subtotal cashType="outputCash">
            <p>SubTotal de Saídas</p>
            <p>{formatPrice(state.outputTotal)}</p>
          </Subtotal>
          <Button
            secondary
            sm
            onClick={handleToggleRegisterVisibility}
            disabled={
              new Date(dateToFilter).toDateString() !==
              new Date().toDateString()
            }
          >
            {new Date(dateToFilter).toDateString() !== new Date().toDateString()
              ? "Retorne para o dia atual"
              : "Adicionar saída"}
          </Button>
        </Actions>
        <TableOutputCash title="Saídas" type="outputCash" items={outputCash} />
        {lastPage > 1 && (
          <TabNavigation>
            <PaginationTab
              labelSecondary
              count={lastPage}
              page={page}
              handleChange={(event, value) => {
                setPage(value);
              }}
            />
          </TabNavigation>
        )}
      </TableBox>
    </>
  );
}

export default OutputCash;
