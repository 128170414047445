import React from "react";

// services
import { formatDate } from "../../utils/date-format";
import { formatPhone } from "../../utils/phone-format";

import { Button } from "../../atoms/Button";
import { IconContainer } from "../../atoms/IconContainer";
import DataNotFound from "../../atoms/DataNotFound";

import {
  IconDelete,
  IconAcordeon,
  CardsGrid,
  Card,
  Key,
  Code,
  Value,
  ControlAcordeon,
  AccordionShow,
  Acordeon,
  Actions,
} from "./styles";

function TableClients({ customers, handleDelete, handleUpdate, isSubmitting }) {
  return (
    <>
      {customers.length === 0 ? (
        <DataNotFound message="Nenhum resultado encontrado" />
      ) : (
        <CardsGrid>
          {customers.map((customer) => (
            <Card key={customer.id}>
              <Code>
                Código &nbsp;<Value>{customer.id}</Value>
              </Code>

              <AccordionShow type="checkbox" id={customer.id} />
              <ControlAcordeon htmlFor={customer.id}>
                <IconAcordeon />
              </ControlAcordeon>

              <Acordeon className="card">
                <Key>
                  Nome &nbsp;<Value>{customer.name}</Value>
                </Key>
                <Key>
                  Contato &nbsp;
                  <Value>
                    {customer.phone_number &&
                      formatPhone(customer.phone_number)}
                  </Value>
                </Key>
                <Key>
                  Data de criação &nbsp;
                  <Value>
                    {formatDate(customer.created_at, "dd-month-yyyy")}
                  </Value>
                </Key>

                <Actions>
                  <Button
                    tertiary
                    sm
                    fullWidth
                    onClick={() => handleUpdate(customer)}
                    disabled={isSubmitting}
                  >
                    Editar cliente
                  </Button>
                  <IconContainer onClick={() => handleDelete(customer.id)}>
                    <IconDelete disabled={isSubmitting} />
                  </IconContainer>
                </Actions>
              </Acordeon>
            </Card>
          ))}
        </CardsGrid>
      )}
    </>
  );
}

export default TableClients;
