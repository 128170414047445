import styled from "styled-components";

import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";
import Metrics from "../../settings/metrics";

export const Label = styled.label`
  font-size: ${Fonts.textLg};
  font-weight: ${Fonts.boldWeight};
  /* color: ${(props) =>
    props.error ? Colors.darkWarn : Colors.lightBlue};
   */

  color: ${(props) => {
    if (props.error) {
      return Colors.darkWarn;
    } else if (props.labelSecondary) {
      return Colors.orange;
    } else {
      return Colors.lightBlue;
    }
  }};

  margin-bottom: calc(${Metrics.spaceSm} + 8px);
  margin-left: ${Metrics.space};

  &.error {
    color: ${Colors.darkWarn};
  }
`;
