import * as yup from "yup";

const validations = yup.object().shape({
  description: yup
    .string()
    .max(150, "Limite máximo de caracteres excedido")
    .required("Campo não-preenchido"),
  payment_type: yup.string().required("Campo não-preenchido"),
  value: yup
    .string()
    .required("Campo não-preenchido")
    .matches(
      /(?=.*\d)^\$?(([1-9]\d{0,2}(.\d{3})*)|0)?(,\d{1,2})?$/,
      "Insira um valor positivo no formato R$ 0.000,00"
    ),
  cost_center_id: yup.string().required("Campo não-preenchido"),
  interested: yup.string().max(150, "Limite máximo de caracteres excedido"),
});

export default validations;
