import styled, { css } from "styled-components";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";

const iconCSS = css`
  width: 22px;
  height: 22px;
`;

export const Container = styled.button`
  position: absolute;
  background: none;
  border: 0;
  right: ${Metrics.spaceSm};
  top: 28px;
  transform: translateY(-50%);

  padding: 5px;
  background: ${(props) =>
    props.selectopen ? `${Colors.lightBlue}` : `transparent`};
  border-radius: 50%;
  display: grid;
  place-items: center;

  > svg {
    ${iconCSS};
    color: ${(props) =>
      props.selectopen ? `${Colors.white}` : `${Colors.darkBlue}`};
  }
`;
