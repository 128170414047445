import styled, { css } from "styled-components";

import { FiChevronLeft } from "react-icons/fi";

import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";
import Metrics from "../../settings/metrics";

export const Container = styled.div`
  overflow: hidden;
  margin-bottom: 40px;
  padding: ${Metrics.spaceXs};
`;

export const Title = styled.p`
  display: flex;
  align-items: center;
  font-size: ${Fonts.titleMd};
  font-weight: ${Fonts.boldWeight};
  letter-spacing: ${Fonts.titleSpacingS};

  ${(props) =>
    props.cashType === "inputCash" &&
    css`
      color: ${Colors.lightBlue};
    `}

  ${(props) =>
    props.cashType === "outputCash" &&
    css`
      color: ${Colors.orange};
    `}
`;

export const Actions = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const PickerContainer = styled.div`
  width: 100%;
  max-width: 300px;
`;

export const DateTime = styled.span`
  font-size: ${Fonts.titleSm};
  margin-left: ${Metrics.space};
  color: ${Colors.darkGrey};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > h1 {
    color: ${Colors.lightBlue};
    font-size: ${Fonts.titleXl};
  }

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > button {
    margin-right: ${Metrics.spaceLg};

    @media (max-width: 900px) {
      width: 100%;
      margin-right: 0;
      margin-top: ${Metrics.spaceSm};
    }
  }

  @media (max-width: 900px) {
    margin-left: 0;
    max-width: 300px;
    width: 100%;
    flex-direction: column;
  }
`;

export const TableGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: auto;
  margin: ${Metrics.space} 0;
`;

export const TableBox = styled.div`
  min-height: 400px;
  padding: ${Metrics.spaceSm};
`;

export const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr 1fr;
  grid-gap: ${Metrics.spaceXs};

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  padding: ${Metrics.spaceSm};
  margin-top: ${Metrics.space};

  > p {
    display: flex;
    flex-direction: column;
    font-weight: ${Fonts.boldWeight};
    font-size: ${Fonts.textLg};
    color: ${Colors.darkGrey};

    span {
      color: ${Colors.black};
      font-size: ${Fonts.titleSm};
    }

    @media (max-width: 800px) {
      margin-bottom: ${Metrics.space};

      span {
        font-weight: ${Fonts.boldWeight};
        font-size: ${Fonts.textLg};
      }
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > p {
    font-weight: ${Fonts.boldWeight};
    font-size: ${Fonts.textLg};
    color: ${Colors.darkGrey};
    display: flex;
    flex-wrap: wrap;
    flex: 0;
  }
  > p + p {
    margin-top: calc(${Metrics.spaceSm} + ${Metrics.spaceXs});
  }
`;

export const InputInfo = styled.p`
  span {
    color: ${Colors.lightBlue};
    margin-left: ${Metrics.spaceXs};
  }
`;

export const OutputInfo = styled.p`
  span {
    color: ${Colors.orange};
    margin-left: ${Metrics.spaceXs};
  }
`;

export const Gain = styled.p`
  span {
    color: ${Colors.success};
    position: relative;
    margin-left: ${Metrics.spaceXs};

    em {
      margin-left: ${Metrics.spaceSm};
      font-size: ${Fonts.textSm};
      letter-spacing: ${Fonts.titleSpacingX};
      text-transform: uppercase;
      font-weight: ${Fonts.boldWeight};
      font-style: normal;
    }
  }
`;

export const Losses = styled.p`
  span {
    color: ${Colors.warn};
    position: relative;
    margin-left: ${Metrics.spaceXs};

    em {
      margin-left: ${Metrics.spaceSm};
      font-size: ${Fonts.textSm};
      letter-spacing: ${Fonts.titleSpacingX};
      text-transform: uppercase;
      font-weight: ${Fonts.boldWeight};
      font-style: normal;
    }
  }
`;

export const Subtotal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${Metrics.spaceMd} ${Metrics.space};

  > p {
    font-weight: ${Fonts.boldWeight};
    font-size: ${Fonts.textLg};

    ${(props) =>
      props.cashType === "inputCash" &&
      css`
        color: ${Colors.lightBlue};
      `}

    ${(props) =>
      props.cashType === "outputCash" &&
      css`
        color: ${Colors.orange};
      `}
  }
  p + p {
    margin-left: ${Metrics.space};
  }
`;

export const TabNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: ${Metrics.space};
`;

export const GoBack = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${Colors.darkBlue};
  font-size: ${Fonts.textMd};
  padding: ${Metrics.spaceSm};
`;

export const IconGoBack = styled(FiChevronLeft)`
  color: ${Colors.darkBlue};
  width: 24px;
  height: 240x;
`;
