import React, { createContext, useContext, useReducer } from "react";
import { userAccount, userName, userEmail } from "../services/auth";

const StoreContext = createContext();

const initialState = {
  /*  */
  /* BALANÇO */
  /*  */
  initialValue: 0,
  inputTotal: 0,
  outputTotal: 0,
  final: 0,
  closed: false,
  dirty: false,
  /*  */
  /* Account */
  /*  */
  role: userAccount() || "secretary",
  name: userName() || "Usuário",
  email: userEmail() || "seg@email.com",

  /*  */
  /* MENU LATERAL */
  /*  */
  isLateralMenuVisible: false,
};

const reducer = (state, action) => {
  const mappedAction = {
    /*  */
    /* BALANÇO */
    /*  */
    GET_BALANCE: {
      initialValue: state.initialValue,
      inputTotal: state.inputTotal,
      outputTotal: state.outputTotal,
      final: state.final,
      closed: state.closed,
      dirty: state.dirty,
    },
    SET_BALANCE: {
      ...state,
      initialValue: action.initialValue,
      inputTotal: action.inputTotal,
      outputTotal: action.outputTotal,
      final: action.final,
      closed: action.closed,
      dirty: action.dirty,
    },
    /*  */
    /*  */
    SET_INITIAL_VALUE: {
      ...state,
      initialValue: action.initialValue,
    },
    SET_INPUT_TOTAL: {
      ...state,
      inputTotal: action.inputTotal,
    },
    SET_OUTPUT_TOTAL: {
      ...state,
      outputTotal: action.outputTotal,
    },
    SET_BALANCE_STATUS: {
      ...state,
      closed: action.closed,
      dirty: action.dirty,
    },
    SET_DIRTY_CASH: {
      ...state,
      dirty: action.dirty,
    },
    SET_USER_ACCOUNT: {
      ...state,
      role: action.role,
      name: action.name,
      email: action.email,
    },
    /*  */
    /* MENU LATERAL */
    /*  */
    GET_LATERAL_MENU_STATE: {
      isLateralMenuVisible: state.isLateralMenuVisible,
    },
    SET_LATERAL_MENU_STATE: {
      ...state,
      isLateralMenuVisible: action.visible,
    },
  };

  return mappedAction[action.type] || state;
};

export default function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
}
export const useStore = () => useContext(StoreContext);
