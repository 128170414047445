import styled, { css } from "styled-components";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";
import Fonts from "../../settings/fonts";
import { FaSignOutAlt, FaKey } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";

/* =========== */
/* Navigations */
export const Navbar = styled.nav`
  width: 100%;
  padding: ${Metrics.spaceSm} ${Metrics.spaceXl};
  height: ${Metrics.marginNavbarToBody};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 6px ${Colors.greyShadow};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @media (max-width: 430px) {
    padding: ${Metrics.spaceSm} ${Metrics.spaceMd};
    justify-content: flex-end;
  }
`;

/* =========== */
/* Left Side Navbar */

export const LeftSide = styled.div`
  display: flex;

  @media (max-width: 430px) {
    flex: none;
  }
`;

export const Logo = styled.div`
  width: 160px;

  > img {
    max-width: 160px;
  }

  @media (max-width: 800px) {
    > img {
      max-width: 100px;
      margin: 0 auto;
      display: block;
    }
  }

  @media (max-width: 330px) {
    > img {
      max-width: 70px;
    }
  }
`;

/* Right Side Navbar */
export const RightSide = styled.div`
  display: flex;
`;
export const Profile = styled.div`
  display: flex;
  align-items: center;

  p {
    text-align: left;
    display: flex;
    flex-direction: column;

    @media (max-width: 520px) {
      display: none;
    }

    span.user-logged {
      display: block;
      position: relative;
      font-size: ${Fonts.textMd};
      color: ${Colors.darkBlue};
      font-weight: ${Fonts.boldWeight};
      margin: 0 ${Metrics.spaceXs};
      cursor: pointer;
      text-transform: none;
      margin-left: 5px;

      max-width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span.user-role {
      display: block;
      font-size: ${Fonts.textSm};
      color: ${Colors.darkBlue};
      font-weight: ${Fonts.normalWeight};
      margin: 0 ${Metrics.spaceXs};
      cursor: pointer;
      text-transform: none;
      margin-left: 5px;

      max-width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const iconCSS = css`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const LogoutIcon = styled(FaSignOutAlt)`
  ${iconCSS};
  color: ${Colors.darkWarn};
`;

export const KeyIcon = styled(FaKey)`
  ${iconCSS};
  color: ${Colors.darkBlue};
`;

export const IconProfileArrow = styled(FiChevronDown)`
  width: 18px;
  height: 18px;
  transition: transform 0.2s ease-out;
  color: ${Colors.darkBlue};
  transform: ${(props) => (props.open ? "rotate(-180deg)" : "rotate(0deg)")};
`;
