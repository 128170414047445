import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { formatDate } from "../../../utils/date-format";

import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from "recharts";

import { GraphicContainer } from "../styles";

const GraphProducts = ({ date }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getProducts = async (date = "") => {
      const { data } = await api.get(
        `/dashboard/most-wanted-products?day=${formatDate(
          date,
          "yyyy-MM-dd"
        ).replaceAll("/", "-")}`
      );
      setProducts(data);
      /* console.log(JSON.stringify(data, null, 2)); */
    };
    getProducts(date);
  }, [date]);

  const colors = ["#8ECC51", "#F56662", "#FFC06A", "#0CBCE4", "#CCCCCC"];

  return (
    <GraphicContainer>
      <h3>Top 4 Produtos mais usados (quantidade)</h3>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie dataKey="count" data={products} outerRadius={100} label>
            {products.length > 0 &&
              products.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </GraphicContainer>
  );
};

export default GraphProducts;
