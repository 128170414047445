export const increment = (value) => {
  let fieldValue = (
    parseFloat(value.replaceAll(".", "").replace(",", ".")) + 1
  ).toFixed(2);

  return fieldValue;
};

export const decrement = (value) => {
  let fieldValue = (
    parseFloat(value.replaceAll(".", "").replace(",", ".")) - 1
  ).toFixed(2);

  if (fieldValue.includes("-")) {
    fieldValue = "000";
  }

  return fieldValue;
};
