import React, { useState, useEffect } from "react";

//services
import api from "../../services/api";
import swal, { fireAction } from "../../utils/swal";

/* ================= */

import Dashboard from "../../templates/Dashboard";
import { Button } from "../../atoms/Button";
import TableServices from "../../organisms/TableServices";
import PaginationTab from "../../atoms/PaginationTab";
import ModalServiceRegister from "../../organisms/ModalServiceRegister";
import ModalServiceUpdate from "../../organisms/ModalServiceUpdate";
import Search from "../../organisms/Search";

/* ================= */

import { Container, Header, ButtonsContainer, TabNavigation } from "./styles";

function Services() {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [services, setServices] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serviceToUpdate, setServiceToUpdate] = useState({});
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isModalRegisterVisible, setIsModalRegisterVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  /* ================================== */

  const getServices = async (page, query = "") => {
    try {
      const { data } = await api.get(
        `/services?qs=${query}&page=${page}&limit=10`
      );
      setServices(data.data);
      setLastPage(data.meta?.last_page);
    } catch (err) {
      console.warn("SERVICE-GET:" + err);
    }
  };

  const handleUpdateService = async (values) => {
    setIsSubmitting(true);

    const { id, type, description, unit_price, unit } = values;

    try {
      await api.put(`/services/${id}`, {
        type,
        name: description,
        description,
        unit_price: unit_price.replaceAll(".", "").replace(",", "."),
        unit,
      });

      handleToggleModalUpdateVisibility();
      fireAction(
        "Tudo certo!",
        "O cadastro do serviço foi atualizado",
        "success"
      );

      getServices(page);
    } catch (err) {
      handleToggleModalUpdateVisibility();
      fireAction(
        "Ops, algo deu errado!",
        "Falha ao atualizar os dados do serviço",
        "error"
      );
      console.warn("SERVICE-UPDATE:" + err);
    }
    setIsSubmitting(false);
  };

  const handleRegisterService = async (values) => {
    setIsSubmitting(true);

    const { type, description, unit_price, unit } = values;

    try {
      await api.post("/services", {
        type,
        name: description,
        description,
        unit_price: unit_price.replaceAll(".", "").replace(",", "."),
        unit,
      });
      handleToggleModalRegisterVisibility();

      fireAction(
        "Tudo certo!",
        "O cadastro do novo serviço foi realizado com sucesso",
        "success"
      );

      getServices(page);
    } catch (err) {
      handleToggleModalRegisterVisibility();
      fireAction(
        "Ops, algo deu errado!",
        "O cadastro do novo serviço falhou",
        "error"
      );
      console.warn("SERVICE-NEW:" + err);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    getServices(page);
  }, [page]);

  /* ================================== */

  function handleToggleSearchVisible() {
    if (isSearchVisible) {
      setSearchValue("");
      getServices(1, "");
    }
    setIsSearchVisible(!isSearchVisible);
  }

  function handleToggleModalUpdateVisibility() {
    setIsModalUpdateVisible(!isModalUpdateVisible);
  }

  function handleToggleModalRegisterVisibility() {
    setIsModalRegisterVisible(!isModalRegisterVisible);
  }

  /* ================================== */

  const handleDelete = async (id) => {
    setIsSubmitting(true);

    swal
      .fire({
        title: "Tem certeza que deseja excluir o serviço?",
        text: "Esta ação não poderá ser revertida!",
        icon: "warning",
        customClass: {
          header: "header-alert",
          icon: "icon-alert",
          title: `title-alert-warning`,
          content: "description-alert",
        },
        showCancelButton: true,
        confirmButtonColor: "#6b915c",
        cancelButtonColor: "#cc2c29",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await api.delete(`/services/${id}`);

            fireAction(
              "Tudo certo!",
              "O serviço foi removido da base de dados",
              "success"
            );

            const filtered = services.filter((service) => service.id !== id);
            setServices(filtered);
            if (filtered.length === 0 && page > 1) {
              setPage((page) => page - 1);
            }
          } catch (err) {
            if (err.response?.status === 401) {
              fireAction(
                "Ops, algo deu errado!",
                "Você não tem permissão para realizar esta ação",
                "error"
              );
            } else {
              fireAction(
                "Ops, algo deu errado!",
                "Falha ao remover o serviço da base de dados",
                "error"
              );
            }

            console.warn("SERVICE-DELETE:" + err);
          }
        }
      });

    setIsSubmitting(false);
  };

  // ver o uso de useCallback nessas funções
  const handleUpdate = (dataToUpdate) => {
    const service = services.find((service) => service.id === dataToUpdate.id);
    setServiceToUpdate(service);
    handleToggleModalUpdateVisibility();
  };

  // decidir por auto pesquisar ou por submit
  const handleSubmit = (e) => {
    e.preventDefault();
    getServices(1, searchValue);
  };

  return (
    <Dashboard>
      <ModalServiceRegister
        visible={isModalRegisterVisible}
        handleToggleVisibility={handleToggleModalRegisterVisibility}
        isSubmitting={isSubmitting}
        handleSubmit={handleRegisterService}
      />
      <ModalServiceUpdate
        visible={isModalUpdateVisible}
        handleToggleVisibility={handleToggleModalUpdateVisibility}
        service={serviceToUpdate}
        isSubmitting={isSubmitting}
        handleSubmit={handleUpdateService}
      />
      <Container>
        <Header>
          <h1>Serviços</h1>
          <ButtonsContainer>
            <Button primary sm onClick={handleToggleModalRegisterVisibility}>
              Adicionar Serviço
            </Button>
            <Button tertiary sm onClick={handleToggleSearchVisible}>
              {isSearchVisible ? "Fechar busca" : "Buscar Serviço"}
            </Button>
          </ButtonsContainer>
        </Header>
        <Search
          visible={isSearchVisible}
          handleSubmit={handleSubmit}
          name="Pesquisar por código ou nome..."
          value={searchValue}
          handleChange={setSearchValue}
        />
        <TableServices
          services={services}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          isSubmitting={isSubmitting}
        />
        {lastPage > 1 && (
          <TabNavigation>
            <PaginationTab
              count={lastPage}
              page={page}
              handleChange={(event, value) => {
                setPage(value);
              }}
            />
          </TabNavigation>
        )}
      </Container>
    </Dashboard>
  );
}

export default Services;
