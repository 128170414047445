import React from "react";
import { useStore } from "../../store";
import convertRoleToPtBR from "../../utils/profile-access";

import { NavLink } from "react-router-dom";
import Avatar from "../../molecules/Avatar";

import {
  LateralMenu as Lateral,
  Header,
  ProfileContainer,
  Profile,
  IconMenu,
  IconControls,
  IconUsers,
  IconList,
  IconTShirt,
  IconBox,
  IconDollar,
  IconConfiguration,
  MenuContainer,
  MenuItem,
} from "./styles";

import logoWhiteImg from "../../assets/logomarca-white.png";

function LateralMenu() {
  const { state, dispatch } = useStore();

  function handleToggleVisibleLateralMenu() {
    dispatch({
      type: "SET_LATERAL_MENU_STATE",
      visible: !state.isLateralMenuVisible,
    });
  }

  return (
    <>
      <IconMenu
        isVisible={state.isLateralMenuVisible}
        onClick={handleToggleVisibleLateralMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </IconMenu>
      <Lateral isVisible={state.isLateralMenuVisible}>
        <Header isVisible={state.isLateralMenuVisible}>
          <img src={logoWhiteImg} alt="Seg Lavanderia" />
        </Header>
        <ProfileContainer isVisible={state.isLateralMenuVisible}>
          <Profile>
            <Avatar />
            <p>
              <span className="user-logged">{state.name}</span>
              <span className="user-role">{convertRoleToPtBR(state.role)}</span>
            </p>
          </Profile>
        </ProfileContainer>
        <MenuContainer isVisible={state.isLateralMenuVisible}>
          {(state.role === "admin" || state.role === "manager") && (
            <MenuItem
              onClick={() => {
                if (state.isLateralMenuVisible) {
                  dispatch({
                    type: "SET_LATERAL_MENU_STATE",
                    visible: false,
                  });
                }
              }}
            >
              <NavLink to="/home" activeClassName="active">
                <IconControls />
                <span>Início/Controles</span>
              </NavLink>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              if (state.isLateralMenuVisible) {
                dispatch({
                  type: "SET_LATERAL_MENU_STATE",
                  visible: false,
                });
              }
            }}
          >
            <NavLink to="/clients" activeClassName="active">
              <IconUsers />
              <span>Clientes</span>
            </NavLink>
          </MenuItem>

          <MenuItem
            onClick={() => {
              if (state.isLateralMenuVisible) {
                dispatch({
                  type: "SET_LATERAL_MENU_STATE",
                  visible: false,
                });
              }
            }}
          >
            <NavLink to="/demand" activeClassName="active">
              <IconList />
              <span>Pedidos</span>
            </NavLink>
          </MenuItem>

          <MenuItem
            onClick={() => {
              if (state.isLateralMenuVisible) {
                dispatch({
                  type: "SET_LATERAL_MENU_STATE",
                  visible: false,
                });
              }
            }}
          >
            <NavLink to="/services" activeClassName="active">
              <IconTShirt />
              <span>Serviços</span>
            </NavLink>
          </MenuItem>

          <MenuItem
            onClick={() => {
              if (state.isLateralMenuVisible) {
                dispatch({
                  type: "SET_LATERAL_MENU_STATE",
                  visible: false,
                });
              }
            }}
          >
            <NavLink to="/stock" activeClassName="active">
              <IconBox />
              <span>Estoque</span>
            </NavLink>
          </MenuItem>

          <MenuItem
            onClick={() => {
              if (state.isLateralMenuVisible) {
                dispatch({
                  type: "SET_LATERAL_MENU_STATE",
                  visible: false,
                });
              }
            }}
          >
            <NavLink to="/cash-flow" activeClassName="active">
              <IconDollar />
              <span>Fluxo de Caixa</span>
            </NavLink>
          </MenuItem>

          {(state.role === "admin" || state.role === "manager") && (
            <MenuItem
              onClick={() => {
                if (state.isLateralMenuVisible) {
                  dispatch({
                    type: "SET_LATERAL_MENU_STATE",
                    visible: false,
                  });
                }
              }}
            >
              <NavLink to="/configurations" activeClassName="active">
                <IconConfiguration />
                <span>Configurações</span>
              </NavLink>
            </MenuItem>
          )}
        </MenuContainer>
      </Lateral>
    </>
  );
}

export default LateralMenu;
