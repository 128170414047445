import styled, { css } from "styled-components";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";
import Fonts from "../../settings/fonts";

import { FiTrash2, FiChevronDown, FiEdit } from "react-icons/fi";

const iconCSS = css`
  color: ${Colors.darkBlue};
  width: 22px;
  height: 22px;
`;

export const IconDelete = styled(FiTrash2)`
  ${iconCSS};
  cursor: pointer;
  transition: color 0.2s ease-out;
`;

export const IconUpdate = styled(FiEdit)`
  ${iconCSS};
  cursor: pointer;
  transition: color 0.2s ease-out;
`;

export const IconAcordeon = styled(FiChevronDown)`
  ${iconCSS};

  translate: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
`;

/** RESPONSIVIDADE CARDS */

export const CardsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
  margin-top: 40px;
  margin-bottom: 20px;
  grid-gap: 10px;
`;

export const Card = styled.div`
  width: 100%;
  background: ${Colors.white};
  border-radius: ${Metrics.radiusSm};
  box-shadow: 0 3px 6px ${Colors.greyShadow};
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 936px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ControlAcordeon = styled.label`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;

  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  transition: background 0.2s cubic-bezier(0.47, 0, 0.745, 0.715),
    color 0.2s cubic-bezier(0.47, 0, 0.745, 0.715),
    transform 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);

  &:hover {
    background: ${Colors.lightGrey};

    > svg {
      color: ${Colors.lightBlue};
    }
  }

  &:active {
    background: ${Colors.lightBlue};

    > svg {
      color: ${Colors.white};
    }
  }

  display: none;

  @media (max-width: 936px) {
    display: flex;
  }
`;

export const AccordionShow = styled.input`
  display: none;

  &:checked ~ .card {
    visibility: visible;
    opacity: 1;
    display: flex;
  }
  &:checked ~ label {
    transform: rotate(180deg);
  }
`;

export const Acordeon = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 5px;

  @media (max-width: 936px) {
    flex-direction: column;
    margin-top: 10px;
    padding-top: 10px;

    visibility: hidden;
    opacity: 0;
    display: none;
  }
`;

export const Code = styled.p`
  color: ${Colors.lightBlue};
  font-weight: ${Fonts.boldWeight};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 150px;
  width: 100%;
  padding: 5px;

  @media (max-width: 936px) {
    padding: 0;
  }
`;

export const Key = styled.p`
  color: ${Colors.lightBlue};
  font-weight: ${Fonts.boldWeight};
  display: flex;
  align-items: center;

  max-width: 200px;
  width: 100%;

  flex-wrap: wrap;

  padding: 5px;

  @media (max-width: 936px) {
    padding: 0;
    max-width: unset;
    min-width: 200px;
    width: 100%;
  }
`;

export const Value = styled.span`
  color: ${Colors.black};
  font-weight: ${Fonts.regularWeight};
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 936px) {
    max-width: 200px;
    width: 100%;
  }
`;

export const Actions = styled.div`
  width: 100%;
  max-width: 200px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
  place-items: center;

  @media (max-width: 936px) {
    margin: 20px auto 0;
  }
`;
