export default function convertRoleToPtBR(item) {
  if (item === "manager") {
    return "Gerente";
  } else if (item === "admin") {
    return "Administrador";
  } else if (item === "secretary") {
    return "Funcionário";
  } else {
    return "";
  }
}

export const userRoles = [
  {
    name: "Gerente",
    value: "manager",
  },
  /*   {
    name: "Administrador",
    value: "admin",
  }, */
  {
    name: "Funcionário",
    value: "secretary",
  },
];
