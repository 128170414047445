import styled from "styled-components";

import Metrics from "../../settings/metrics";
import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";

export const Container = styled.div`
  margin: ${Metrics.spaceMd} 0;

  color: ${(props) =>
    props.labelSecondary ? `${Colors.orange}` : `${Colors.lightBlue}`};

  > span {
    font-size: ${Fonts.textLg};
    font-weight: ${Fonts.semiBoldWeight};
  }
`;
