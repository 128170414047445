import React from "react";
import Dashboard from "../../templates/Dashboard";

import {
  Container,
  Header,
  Actions,
  GraphicsGrid,
  PickerContainer,
  GoBack,
  IconGoBack,
} from "./styles";

import { Error } from "../../atoms/Error";
import SearchFilter from "../../organisms/SearchFilter";

/* ===== GRAPHICS ====== */
/* ===================== */
import Resume from "./Resume";
import GraphBalance from "./GraphBalance";
import GraphSelling from "./GraphSelling";
import GraphDemands from "./GraphDemands";
import GraphProducts from "./GraphProducts";
import GraphServices from "./GraphServices";
/* ===================== */
/* ===================== */

function Home() {
  const [date, setDate] = React.useState(new Date());
  const [error, setError] = React.useState("");

  return (
    <Dashboard>
      <Container>
        <Header>
          <h1>Dashboard de Controle</h1>
          <Actions>
            <PickerContainer>
              <SearchFilter
                dateFilter={date}
                setDateFilter={(date) => {
                  if (date?._isValid) {
                    setError("");
                    setDate(date);
                  } else {
                    setError("Insira uma data válida");
                  }
                }}
                helperText={<Error>{error}</Error>}
                placeholder="Filtrar estatísticas"
                disableFuture={true}
              />
            </PickerContainer>

            <div>
              {new Date(date).toDateString() !== new Date().toDateString() && (
                <GoBack onClick={() => setDate(new Date())}>
                  <IconGoBack />
                  Voltar para o dia atual
                </GoBack>
              )}
            </div>
          </Actions>
        </Header>
        <Resume date={date} />
        <GraphicsGrid>
          <GraphBalance date={date} />
          <GraphSelling date={date} />
          <GraphDemands date={date} />
          <GraphProducts date={date} />
          <GraphServices date={date} />
        </GraphicsGrid>
      </Container>
    </Dashboard>
  );
}

export default Home;
