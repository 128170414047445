import React from "react";
import PropTypes from "prop-types";

import { Field, ErrorMessage } from "formik";

import { Label } from "../../atoms/Label";

import { Input } from "../../atoms/Input";

import { Error } from "../../atoms/Error";
import { InputField } from "../../molecules/InputField";

FieldCustom.propTypes = {
  touched: PropTypes.bool,
  errors: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.node,
  component: PropTypes.any,
  rest: PropTypes.any,
};

function FieldCustom({
  touched,
  errors,
  name,
  title,
  labelSecondary,
  placeholder,
  icon,
  component = Input,
  children,
  value,
  ...rest
}) {
  return (
    <>
      {/* {(value || (touched && errors)) && (
        <Label
          labelSecondary={labelSecondary}
          htmlFor={name}
          error={touched && errors}
        >
          {title}
        </Label>
      )} */}
      <Label
        labelSecondary={labelSecondary}
        htmlFor={name}
        error={touched && errors}
      >
        {title}
      </Label>
      <InputField>
        <Field
          as={component}
          placeholder={placeholder}
          name={name}
          children={children}
          labelSecondary={labelSecondary}
          {...rest}
        />
        {icon}
        <Error>
          <ErrorMessage name={name} />
        </Error>
      </InputField>
    </>
  );
}

export default FieldCustom;
