const TOKEN = "@SEG-TOKEN";
const USER = "@SEG-USER";
const ROLE = "@SEG-ROLE";
const EMAIL = "@SEG-USER-EMAIL";

export const isAuthenticated = () => sessionStorage.getItem(TOKEN) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN);

export const login = (token) => sessionStorage.setItem(TOKEN, token);
export const logout = () => sessionStorage.removeItem(TOKEN);

export const userAccount = () => sessionStorage.getItem(ROLE);
export const userName = () => sessionStorage.getItem(USER);
export const userEmail = () => sessionStorage.getItem(EMAIL);

export const saveUserInfo = (role, name, email) => {
  sessionStorage.setItem(ROLE, role);
  sessionStorage.setItem(USER, name);
  sessionStorage.setItem(EMAIL, email);
};

export const erasedInfoAccount = () => {
  sessionStorage.removeItem(ROLE);
  sessionStorage.removeItem(USER);
  sessionStorage.removeItem(EMAIL);
};
