import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import IOSSwitch from "./IOSSwitch";

function Switch({ value, handleChange, ...rest }) {
  return (
    <Typography component="div">
      <Grid item>
        <IOSSwitch checked={value} onChange={handleChange} {...rest} />
      </Grid>
    </Typography>
  );
}

export default Switch;
