import React from "react";
import "moment/locale/pt-br";

import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { Error } from "../Error";

import materialTheme from "./overrides";

function DatePickerCustom({
  currentValue,
  handleDateChange,
  placeholder,
  ...rest
}) {
  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          as="input"
          autoOk="true"
          value={currentValue}
          placeholder={placeholder}
          variant="inline"
          format="DD/MM/YYYY"
          onChange={(date) => handleDateChange(date)}
          invalidDateMessage={<Error>Insira uma data válida</Error>}
          maxDateMessage={<Error>Datas futuras são inválidas</Error>}
          minDateMessage={<Error>Data mínima ultrapassada</Error>}
          InputProps={{
            disableUnderline: true,
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default DatePickerCustom;
