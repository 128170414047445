import styled from "styled-components";

import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";
import Metrics from "../../settings/metrics";

export const Container = styled.div``;

export const Wrapper = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;

  textarea {
    padding-right: ${Metrics.spaceXl};
    resize: none;
    min-height: 40px;
    height: auto;

    ::-webkit-scrollbar {
      display: none;
    }
    ::-webkit-thumb {
      display: none;
    }

    appearance: none;
    -moz-appearance: none;

    @media (max-width: 768px) {
      padding-right: calc(${Metrics.spaceLg} + ${Metrics.space});
    }
  }
`;

export const TextControl = styled.div`
  position: absolute;
  display: flex;
  z-index: 20;
  bottom: ${Metrics.space};
  right: 0;
  pointer-events: none;

  @media (max-width: 768px) {
    justify-content: flex-end;
    align-items: center;
  }
`;

export const Count = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(${Metrics.space} - 2px);
  font-size: ${Fonts.textMd};
  color: ${Colors.white};
  font-weight: ${Fonts.boldWeight};
  /* color: ${(props) =>
    props.error ? `${Colors.darkWarn}` : `${Colors.lightBlue}`};
 */
  color: ${(props) => {
    if (props.error) {
      return Colors.darkWarn;
    } else if (props.secondary) {
      return Colors.orange;
    } else {
      return Colors.lightBlue;
    }
  }};

  @media (max-width: 768px) {
    margin-right: ${Metrics.spaceSm};
  }
`;
