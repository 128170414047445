import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { formatDate, getMonthlyName } from "../../../utils/date-format";

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Tooltip,
  Bar,
  Legend,
} from "recharts";

import { GraphicContainer } from "../styles";

const GraphBalance = ({ date }) => {
  const [demands, setDemands] = useState([]);

  useEffect(() => {
    const getDemands = async (date = "") => {
      const { data } = await api.get(
        `/dashboard/last3month-balance?day=${formatDate(
          date,
          "yyyy-MM-dd"
        ).replaceAll("/", "-")}`
      );

      const filtered = data.map((item) => {
        return {
          ...item,
          month: getMonthlyName(item.month),
        };
      });

      setDemands(filtered);
    };

    getDemands(date);
  }, [date]);

  return (
    <GraphicContainer>
      <h3>Total de entradas e saídas (R$ BRL)</h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={demands}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar name="Entradas (R$)" dataKey="in" barSize={20} fill="#0cbce4" />
          <Bar name="Saídas (R$)" dataKey="out" barSize={20} fill="#ffc06a" />
        </BarChart>
      </ResponsiveContainer>
    </GraphicContainer>
  );
};

export default GraphBalance;
