import React, { useState } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { Container } from "./styles.js";

import { formatPrice } from "../../utils/price-format";
import api from "../../services/api";

import AwesomeDebouncePromise from "awesome-debounce-promise";

const getItems = (value) => api.get(`/orders/suggestions?qs=${value}`);
const searchAPIDebounced = AwesomeDebouncePromise(getItems, 600);

export default function AutoSuggestOrder({
  suggestItem,
  setSuggestItem,
  placeholder = "",
  value = "",
  setValue,
  name,
}) {
  const [suggestions, setSuggestions] = useState([]);

  const onChange = async (event, { newValue }) => {
    setValue(newValue);
  };

  const getSuggestionValue = (suggestion) => {
    setSuggestItem(suggestion);
    setValue(suggestion.name);

    return `${suggestion.service_id || suggestion.product_id} ${
      suggestion.name
    }${suggestion.type ? ` - ${suggestion.type}` : ""} - ${formatPrice(
      suggestion.unit_price
    )}`;
  };

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.service_id || suggestion.product_id} {suggestion.name}
      {suggestion.type ? ` - ${suggestion.type}` : ""} -{" "}
      {formatPrice(suggestion.unit_price)}
    </div>
  );

  const onSuggestionsFetchRequested = async ({ value }) => {
    const newArray = await searchAPIDebounced(value);

    setSuggestions(newArray.data);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  /* NOVO  */
  const shouldRenderSuggestions = (value) => {
    return value.trim().length >= 0;
  };
  /*   */

  const inputProps = {
    placeholder,
    value,
    onChange,
    name,
  };

  return (
    <Container>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        highlightFirstSuggestion={true}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    </Container>
  );
}

AutoSuggestOrder.propTypes = {
  suggestItem: PropTypes.object,
  setSuggestItem: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string,
};
