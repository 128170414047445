import styled from "styled-components";

import Metrics from "../../settings/metrics";

export const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: ${Metrics.radiusCircle};

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
