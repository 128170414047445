import React from "react";
import api from "../../../services/api";
import { formatDate, getMonthlyName } from "../../../utils/date-format";

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  Legend,
  ComposedChart,
  Line,
} from "recharts";

import { GraphicContainer } from "../styles";

const GraphSelling = ({ date }) => {
  const [selling, setSelling] = React.useState([]);

  React.useEffect(() => {
    const getSelling = async (date = "") => {
      const { data } = await api.get(
        `/dashboard/last3month-selling?day=${formatDate(
          date,
          "yyyy-MM-dd"
        ).replaceAll("/", "-")}`
      );

      const filtered = data.map((item) => {
        return {
          ...item,
          month: getMonthlyName(item.month),
        };
      });

      setSelling(filtered);
    };

    getSelling(date);
  }, [date]);

  return (
    <GraphicContainer>
      <h3>Total de vendas (R$ BRL)</h3>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={selling}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar name="Valor (R$)" dataKey="total" barSize={20} fill="#8ECC51" />
          <Line
            name="Valor (R$)"
            dataKey="total"
            type="monotone"
            stroke="#6B915C"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </GraphicContainer>
  );
};

export default GraphSelling;
