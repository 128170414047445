import React from "react";

//services
import { formatPrice } from "../../utils/price-format";
import { formatDate } from "../../utils/date-format";

import DataNotFound from "../../atoms/DataNotFound";

import {
  CardsGrid,
  Card,
  Acordeon,
  AccordionShow,
  ControlAcordeon,
  Key,
  Value,
  IconAcordeon,
} from "./styles";

function TableOutputCash({ items }) {
  return (
    <>
      {items.length === 0 ? (
        <DataNotFound labelSecondary message="Nenhum resultado encontrado" />
      ) : (
        <CardsGrid>
          {items.map((item) => (
            <Card key={item.id}>
              <Key>
                Data/Horário{" "}
                <Value>{formatDate(item.date, "dd-MM-yyyy-TT")}</Value>
              </Key>

              <AccordionShow type="checkbox" id={item.id} />
              <ControlAcordeon htmlFor={item.id}>
                <IconAcordeon />
              </ControlAcordeon>

              <Acordeon className="card">
                <Key>
                  Descrição <Value>{item.description}</Value>
                </Key>
                <Key>
                  Observações{" "}
                  <Value>{item.interested && item.interested}</Value>
                </Key>
                <Key>
                  Forma de pagamento <Value>{item.payment_type}</Value>
                </Key>
                <Key>
                  Valor <Value>{formatPrice(item.value)}</Value>
                </Key>
              </Acordeon>
            </Card>
          ))}
        </CardsGrid>
      )}
    </>
  );
}

export default TableOutputCash;
