import React from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormFormik } from "formik";
import VMasker from "vanilla-masker";

import { increment, decrement } from "../../utils/controls-input-number";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Form,
} from "./styles";
import { Button } from "../../atoms/Button";

import FieldCustom from "../../organisms/FieldCustom";
import InputNumber from "../../atoms/InputNumber";
import TextArea from "../../atoms/TextArea";
import SelectCustom from "../../atoms/SelectCustom";

import validations from "./validations";

ModalProductRegister.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function ModalProductRegister({
  visible,
  handleToggleVisibility,
  handleSubmit,
  isSubmitting,
}) {
  const initialValues = {
    name: "",
    unit_price: "",
    stock: "",
    inventory: "",
  };

  const inventoryOptions = [
    {
      name: "Para venda",
      value: "venda",
    },
    {
      name: "Para consumo",
      value: "consumo",
    },
  ];

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Adicionar novo produto</Title>
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm({
              name: "",
              unit_price: "",
              stock: "",
              inventory: "",
            });
          }}
        >
          {({
            dirty,
            isValid,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
          }) => (
            <FormFormik as={Form}>
              <fieldset>
                <FieldCustom
                  as={TextArea}
                  name="name"
                  type="text"
                  title="Descrição"
                  placeholder="Descrição..."
                  touched={touched.name}
                  errors={errors.name}
                  value={values.name}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                  maxLength={150}
                />
                <FieldCustom
                  as={InputNumber}
                  name="unit_price"
                  type="text"
                  title="Valor unitário"
                  placeholder="Valor unitário (reais)"
                  touched={touched.unit_price}
                  errors={errors.unit_price}
                  value={values.unit_price}
                  handleChange={(e) => {
                    setFieldValue(
                      "unit_price",
                      VMasker.toMoney(e.target.value)
                    );
                  }}
                  handleBlur={(value) => handleBlur(value)}
                  increment={() =>
                    setFieldValue(
                      "unit_price",
                      VMasker.toMoney(increment(values.unit_price))
                    )
                  }
                  decrement={() =>
                    setFieldValue(
                      "unit_price",
                      VMasker.toMoney(decrement(values.unit_price))
                    )
                  }
                />
                <FieldCustom
                  as={InputNumber}
                  name="stock"
                  type="number"
                  title="Quantidade"
                  placeholder="Quantidade"
                  touched={touched.stock}
                  errors={errors.stock}
                  value={values.stock}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                  increment={() =>
                    setFieldValue("stock", Number(values.stock) + 1)
                  }
                  decrement={() => {
                    if (values.stock <= 1) {
                      return;
                    }
                    setFieldValue("stock", Number(values.stock) - 1);
                  }}
                />
                <FieldCustom
                  required
                  component={SelectCustom}
                  name="inventory"
                  title="Estoque"
                  placeholder="Estoque..."
                  touched={touched.inventory}
                  errors={errors.inventory}
                  value={values.inventory}
                  data={inventoryOptions}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                />
              </fieldset>
              <Button
                disabled={!dirty || !isValid || isSubmitting}
                type="submit"
                mt="30"
                primary
                upper
                sm
                fullWidth
              >
                Adicionar produto
              </Button>
            </FormFormik>
          )}
        </Formik>
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalProductRegister;
