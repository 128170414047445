import React from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormFormik } from "formik";
import VMasker from "vanilla-masker";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Form,
  Group,
} from "./styles";
import { InputGroup } from "../../molecules/InputField";
import { Button } from "../../atoms/Button";

import FieldCustom from "../../organisms/FieldCustom";

import validations from "./validations";
import {
  handleChangeCpfMask,
  handleChangePhoneMask,
} from "../../utils/validate";

ModalClientRegister.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function ModalClientRegister({
  visible,
  handleToggleVisibility,
  handleSubmit,
  isSubmitting,
}) {
  const initialValues = {
    name: "",
    cpf: "",
    phone_number: "",
    cep: "",
    city: "",
    neighborhood: "",
    address: "",
  };

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Cadastro de Novo Cliente</Title>
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm({
              name: "",
              cpf: "",
              phone_number: "",
              cep: "",
              city: "",
              neighborhood: "",
              address: "",
            });
          }}
        >
          {({ dirty, isValid, touched, errors, setFieldValue, values }) => (
            <FormFormik as={Form}>
              <fieldset>
                <legend>Informações Pessoais</legend>
                <FieldCustom
                  name="name"
                  type="text"
                  title="Nome"
                  placeholder="Nome do cliente..."
                  touched={touched.name}
                  errors={errors.name}
                  value={values.name}
                />
                <FieldCustom
                  name="cpf"
                  type="text"
                  title="CPF"
                  placeholder="CPF..."
                  touched={touched.cpf}
                  errors={errors.cpf}
                  value={values.cpf}
                  onChange={(e) =>
                    setFieldValue(
                      "cpf",
                      VMasker.toPattern(
                        e.target.value,
                        handleChangeCpfMask(e.target.value)
                      )
                    )
                  }
                />
                <FieldCustom
                  name="phone_number"
                  type="text"
                  title="Contato"
                  placeholder="Contato, (00) 90000-0000..."
                  touched={touched.phone_number}
                  errors={errors.phone_number}
                  value={values.phone_number}
                  onChange={(e) => {
                    setFieldValue(
                      "phone_number",
                      VMasker.toPattern(
                        e.target.value,
                        handleChangePhoneMask(e.target.value)
                      )
                    );
                  }}
                />
                {/*                 <FieldCustom
                  name="email"
                  type="email"
                  title="E-mail"
                  placeholder="E-mail..."
                  touched={touched.email}
                  errors={errors.email}
                /> */}
              </fieldset>
              <fieldset>
                <legend>Localização</legend>
                <FieldCustom
                  name="cep"
                  type="text"
                  title="CEP"
                  placeholder="CEP..."
                  touched={touched.cep}
                  errors={errors.cep}
                  value={values.cep}
                  onChange={(e) => {
                    setFieldValue(
                      "cep",
                      VMasker.toPattern(e.target.value, "99999-999")
                    );
                  }}
                />
                <InputGroup>
                  <Group>
                    <FieldCustom
                      name="city"
                      type="text"
                      title="Cidade"
                      placeholder="Cidade..."
                      touched={touched.city}
                      errors={errors.city}
                      value={values.city}
                      group
                    />
                  </Group>
                  <Group>
                    <FieldCustom
                      name="neighborhood"
                      type="text"
                      title="Bairro"
                      placeholder="Bairro..."
                      touched={touched.neighborhood}
                      errors={errors.neighborhood}
                      value={values.neighborhood}
                      group
                    />
                  </Group>
                </InputGroup>
                <FieldCustom
                  name="address"
                  type="text"
                  title="Endereço"
                  placeholder="Endereço..."
                  touched={touched.address}
                  errors={errors.address}
                  value={values.address}
                />
              </fieldset>
              <Button
                disabled={!dirty || !isValid || isSubmitting}
                type="submit"
                mt="30"
                primary
                upper
                sm
                fullWidth
              >
                Cadastrar
              </Button>
            </FormFormik>
          )}
        </Formik>
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalClientRegister;
