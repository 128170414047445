import React, { useState, useEffect } from "react";
import api from "../../services/api";

import swal, { fireAction } from "../../utils/swal";
/* ================= */

import Dashboard from "../../templates/Dashboard";
import ModalRegisterAccess from "../../organisms/ModalRegisterAccess";
import ModalUpdateAccess from "../../organisms/ModalUpdateAccess";
/* ================= */
import { Button } from "../../atoms/Button";
import PaginationTab from "../../atoms/PaginationTab";
import TableAccess from "../../organisms/TableAccess";

import {
  Container,
  Header,
  HeaderControl,
  ButtonsContainer,
  TabNavigation,
  IconControls,
} from "./styles";

function Configurations() {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [accounts, setAccounts] = useState([]);
  const [profileToUpdate, setProfileToUpdate] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  /* ==== REGISTER */
  const [
    modalRegisterAccessVisibility,
    setModalRegisterAccessVisibility,
  ] = useState(false);

  const handleToggleModalRegisterAccessVisibility = () => {
    setModalRegisterAccessVisibility(!modalRegisterAccessVisibility);
  };
  /* ==== UPDATE */
  const [
    modalUpdateAccessVisibility,
    setModalUpdateAccessVisibility,
  ] = useState(false);

  const handleToggleModalUpdateAccessVisibility = () => {
    setModalUpdateAccessVisibility(!modalUpdateAccessVisibility);
  };

  /* ========= GET ACOUNTS ======== */
  const getAccounts = async (page) => {
    try {
      const { data } = await api.get(`/users?page=${page}&limit=10`);

      setAccounts(data.data);
      setLastPage(data.meta?.last_page);
    } catch (err) {
      console.warn("ACCOUNT-GET-PROFILES:" + err);
    }
  };

  useEffect(() => {
    getAccounts(page);
  }, [page]);

  /* REGISTER ACCOUNT CALL */
  async function handleRegisterUserAccount(values) {
    setIsSubmitting(true);

    const { name, email, password, role } = values;

    const data = {
      name,
      email,
      password,
      password_confirmation: password,
      role,
    };
    try {
      const res = await api.post("/register", data);

      if (res.status === 200) {
        fireAction(
          "Tudo certo!",
          "O cadastro da nova conta de acesso foi realizado com sucesso",
          "success"
        );

        getAccounts(page);
      }
    } catch (err) {
      const { data } = err.response;
      const fields = data.errors?.map((error) => error.field);

      if (fields) {
        fireAction(
          "Ops, algo deu errado!",
          `Já existe uma conta com o ${fields.join(", ")} informado.`,
          "error"
        );
      } else {
        fireAction(
          "Ops, algo deu errado!",
          "O cadastro da nova conta de acesso falhou",
          "error"
        );
      }

      console.warn("REGISTER-ACCOUNT-ROLE:" + err);
    } finally {
      handleToggleModalRegisterAccessVisibility();
      setIsSubmitting(false);
    }
  }

  /* UPDATE ACCOUNT CALL */
  async function handleUpdateUserAccount(values) {
    setIsSubmitting(true);
    const { id, name, email, role } = values;

    try {
      await api.put(`/users/${id}`, {
        name,
        email,
        role,
      });

      fireAction(
        "Tudo certo!",
        "O cadastro do novo acesso foi atualizado",
        "success"
      );

      getAccounts(page);
    } catch (err) {
      const { response } = err;

      if (response.status === 401) {
        fireAction(
          "Ops, algo deu errado!",
          "Você não tem permissão para realizar esta ação",
          "error"
        );
      } else {
        fireAction(
          "Ops, algo deu errado!",
          "Falha ao atualizar os dados de acesso",
          "error"
        );
      }

      console.warn("ACCOUNT-UPDATE:" + err);
    } finally {
      setIsSubmitting(false);
      handleToggleModalUpdateAccessVisibility();
    }
  }

  /* DELETE ACCOUNT CALL */
  const handleDelete = async (id) => {
    setIsSubmitting(true);

    swal
      .fire({
        title: "Tem certeza que deseja excluir este acesso?",
        text: "Esta ação não poderá ser revertida!",
        icon: "warning",
        customClass: {
          header: "header-alert",
          icon: "icon-alert",
          title: `title-alert-warning`,
          content: "description-alert",
        },
        showCancelButton: true,
        confirmButtonColor: "#6b915c",
        cancelButtonColor: "#cc2c29",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await api.delete(`/users/${id}`);

            fireAction(
              "Tudo certo!",
              "O acesso foi removido da base de dados",
              "success"
            );

            const filtered = accounts.filter((account) => account.id !== id);
            setAccounts(filtered);

            if (filtered.length === 0 && page > 1) {
              setPage((page) => page - 1);
            }
          } catch (err) {
            if (err.response?.status === 401) {
              fireAction(
                "Ops, algo deu errado!",
                "Você não tem permissão para realizar esta ação",
                "error"
              );
            } else {
              fireAction(
                "Ops, algo deu errado!",
                "Falha ao remover o acesso da base de dados",
                "error"
              );
            }

            console.warn("ACCOUNTS-DELETE:" + err);
          }
        }
      });

    setIsSubmitting(false);
  };

  const handleUpdate = (customerToUpdate) => {
    const account = accounts.find(
      (customer) => customer.id === customerToUpdate.id
    );

    setProfileToUpdate(account);
    handleToggleModalUpdateAccessVisibility();
  };

  return (
    <Dashboard>
      <ModalRegisterAccess
        visible={modalRegisterAccessVisibility}
        handleToggleVisibility={handleToggleModalRegisterAccessVisibility}
        handleSubmit={handleRegisterUserAccount}
        isSubmitting={isSubmitting}
      />

      <ModalUpdateAccess
        profile={profileToUpdate}
        visible={modalUpdateAccessVisibility}
        handleToggleVisibility={handleToggleModalUpdateAccessVisibility}
        handleSubmit={handleUpdateUserAccount}
        handleUpdate={handleUpdate}
        isSubmitting={isSubmitting}
      />
      <Container>
        <Header>
          <h1>Configurações</h1>
          <HeaderControl>
            <h2>
              <IconControls /> Listagem de Acessos
            </h2>
            <ButtonsContainer>
              <Button
                primary
                sm
                onClick={handleToggleModalRegisterAccessVisibility}
              >
                Cadastrar acesso
              </Button>
            </ButtonsContainer>
          </HeaderControl>
        </Header>

        {accounts && (
          <TableAccess
            profiles={accounts}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
          />
        )}
        {lastPage > 1 && (
          <TabNavigation>
            <PaginationTab
              count={lastPage}
              page={page}
              handleChange={(event, value) => {
                setPage(value);
              }}
            />
          </TabNavigation>
        )}
      </Container>
    </Dashboard>
  );
}

export default Configurations;
