import React from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormFormik } from "formik";
import VMasker from "vanilla-masker";

import { increment, decrement } from "../../utils/controls-input-number";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Form,
} from "./styles";
import { Button } from "../../atoms/Button";
import SelectCustom from "../../atoms/SelectCustom";

import FieldCustom from "../../organisms/FieldCustom";
import InputNumber from "../../atoms/InputNumber";
import TextArea from "../../atoms/TextArea";

import AutoSuggestCost from "../../atoms/AutoSuggestCost";

import validations from "./validations";
import { payments } from "../../utils/payment_types";

ModalOutputRegister.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function ModalOutputRegister({
  visible,
  handleToggleVisibility,
  handleSubmit,
  isSubmitting,
}) {
  const initialValues = {
    payment_type: "",
    description: "",
    value: "",
    date: "",
    cost_center_id: "",
    interested: "",
  };

  /* --------- */
  const [suggestCost, setSuggestCost] = React.useState({});

  function onSubmit(values) {
    const data = {
      cost_center_id: suggestCost.id,
      description: values.description,
      payment_type: values.payment_type,
      interested: values.interested,
      value: values.value,
    };

    handleToggleVisibility();
    handleSubmit(data);
  }

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Adicionar Nova Saída</Title>
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            resetForm({
              payment_type: "",
              description: "",
              value: "",
              date: "",
              interested: "",
              cost_center_id: "",
            });
            setSuggestCost({});
          }}
        >
          {({
            dirty,
            isValid,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
          }) => (
            <FormFormik as={Form}>
              <fieldset>
                <FieldCustom
                  as={TextArea}
                  labelSecondary
                  name="description"
                  title="Descrição"
                  placeholder="Descrição da Saída..."
                  touched={touched.description}
                  errors={errors.description}
                  value={values.description}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                  maxLength={150}
                />

                <FieldCustom
                  required
                  component={SelectCustom}
                  labelSecondary
                  name="payment_type"
                  title="Forma de pagamento"
                  placeholder="Forma de pagamento..."
                  touched={touched.payment_type}
                  errors={errors.payment_type}
                  value={values.payment_type}
                  data={payments}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                />

                <FieldCustom
                  as={InputNumber}
                  labelSecondary
                  name="value"
                  type="text"
                  title="Valor"
                  placeholder="Valor da saída..."
                  touched={touched.value}
                  errors={errors.value}
                  value={values.value}
                  handleChange={(e) => {
                    setFieldValue("value", VMasker.toMoney(e.target.value));
                  }}
                  handleBlur={(value) => handleBlur(value)}
                  increment={() =>
                    setFieldValue(
                      "value",
                      VMasker.toMoney(increment(values.value))
                    )
                  }
                  decrement={() =>
                    setFieldValue(
                      "value",
                      VMasker.toMoney(decrement(values.value))
                    )
                  }
                />

                <FieldCustom
                  as={AutoSuggestCost}
                  labelSecondary
                  title="Centro de custo"
                  name="cost_center_id"
                  placeholder="Selecione um centro de custo..."
                  suggestItem={suggestCost}
                  setSuggestItem={setSuggestCost}
                  value={values.cost_center_id}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                  touched={touched.cost_center_id}
                  errors={errors.cost_center_id}
                  setValue={setFieldValue}
                />

                <FieldCustom
                  as={TextArea}
                  labelSecondary
                  name="interested"
                  title="Interessado"
                  placeholder="Interessado..."
                  touched={touched.interested}
                  errors={errors.interested}
                  value={values.interested}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                  maxLength={150}
                />
              </fieldset>
              <Button
                disabled={
                  !dirty ||
                  !isValid ||
                  isSubmitting ||
                  Object.keys(suggestCost).length === 0
                }
                type="submit"
                mt="30"
                secondary
                upper
                sm
                fullWidth
              >
                Adicionar Saída
              </Button>
            </FormFormik>
          )}
        </Formik>
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalOutputRegister;
