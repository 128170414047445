import styled from "styled-components";

import Metrics from "../../settings/metrics";

export const Container = styled.form`
  margin-top: 40px;
  display: ${(props) => (props.visible ? "flex" : "none")};

  > input {
    margin-right: 30px;
    text-overflow: ellipsis;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px auto;

    max-width: 400px;
    width: 100%;

    > input {
      margin-right: 0;
      margin-bottom: ${Metrics.spaceSm};
      max-width: 400px;
      width: 100%;
    }
    > button {
      width: 100%;
    }
  }
`;
