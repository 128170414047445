import React from "react";
import PropTypes from "prop-types";

// utils

import { formatDate } from "../../utils/date-format";
import { formatPrice } from "../../utils/price-format";
// services

import { Button } from "../../atoms/Button";
import DataNotFound from "../../atoms/DataNotFound";
import Switch from "../../atoms/Switch";

import {
  CardsGrid,
  Card,
  Acordeon,
  AccordionShow,
  ControlAcordeon,
  Actions,
  Key,
  Value,
  IconAcordeon,
  KeyPayment,
  ValuePayment,
} from "./styles";

TableDemandFinished.propTypes = {
  demands: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

function TableDemandFinished({
  demands,
  handleDelete,
  handleUpdate,
  handleRevert,
  isSubmitting,
  details,
  handleDetail,
  handlePayment,
}) {
  return (
    <>
      {demands.length === 0 ? (
        <DataNotFound message="Nenhum resultado encontrado" />
      ) : (
        <CardsGrid>
          {demands.map((demand) => (
            <Card key={demand.code}>
              <Key>
                Código
                <Value>{demand.code}</Value>
              </Key>

              <AccordionShow type="checkbox" id={demand.code} />
              <ControlAcordeon htmlFor={demand.code}>
                <IconAcordeon />
              </ControlAcordeon>

              <Acordeon className="card">
                <Key>
                  Nome
                  <Value>
                    {demand.customer?.id} - {demand.customer?.name}
                  </Value>
                </Key>

                <Key>
                  Total
                  <Value>{formatPrice(demand.total)}</Value>
                </Key>

                <Key>
                  Data/Horário
                  <Value>
                    {formatDate(demand.created_at, "dd-MM-yyyy-TT")}
                  </Value>
                </Key>

                <Key>
                  Data de pagamento
                  <Value>
                    {demand.payment_date !== null
                      ? formatDate(demand.payment_date, "dd-MM-yyyy")
                      : "Em espera"}
                  </Value>
                </Key>

                <KeyPayment>
                  <p>Pago</p>
                  <ValuePayment>
                    <Switch
                      disabled={isSubmitting}
                      value={demand.paid}
                      handleChange={() =>
                        handlePayment(demand.paid, demand.code)
                      }
                    />
                  </ValuePayment>
                </KeyPayment>

                <Actions>
                  <Button
                    onClick={() => handleDetail(demand.code)}
                    disabled={isSubmitting}
                    tertiary
                    sm
                  >
                    Ver detalhes
                  </Button>
                  <Button
                    onClick={() => handleRevert(demand.code)}
                    disabled={isSubmitting}
                    tertiary
                    sm
                  >
                    Reverter
                  </Button>
                </Actions>
              </Acordeon>
            </Card>
          ))}
        </CardsGrid>
      )}
    </>
  );
}

export default TableDemandFinished;
