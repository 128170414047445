import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { formatDate } from "../../../utils/date-format";

import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from "recharts";

import { GraphicContainer } from "../styles";

const GraphDemands = ({ date }) => {
  const [demands, setDemands] = useState([]);

  useEffect(() => {
    const getDemands = async (date = "") => {
      const { data } = await api.get(
        `/dashboard/order-completed-x-pending?day=${formatDate(
          date,
          "yyyy-MM-dd"
        ).replaceAll("/", "-")}`
      );

      const total =
        data.length > 0 &&
        data.map((item) => item.count).reduce((cur, acc) => cur + acc);

      const calcPercent = (value) => {
        return Math.round((value / total) * 100);
      };

      const filtered = data.map((item) => {
        return {
          ...item,
          name: item.status === "pending" ? "Pendente (%)" : "Concluído (%)",
          count: calcPercent(item.count),
        };
      });

      setDemands(filtered);
      /*       console.log(JSON.stringify(data, null, 2)); */
    };

    getDemands(date);
  }, [date]);

  const colors = ["#0CBCE4", "#005D89", "#FFC06A", "#CC2C29", "#8ECC51"];

  return (
    <GraphicContainer>
      <h3>Relação entre pedidos pendentes e concluídos (%)</h3>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            dataKey="count"
            startAngle={180}
            endAngle={0}
            data={demands}
            outerRadius={100}
            innerRadius={45}
            paddingAngle={5}
            fill="#0CBCE4"
            label
          >
            {demands.length > 0 &&
              demands.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </GraphicContainer>
  );
};

export default GraphDemands;
