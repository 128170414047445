import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { formatDate } from "../../../utils/date-format";

import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from "recharts";

import { GraphicContainer } from "../styles";

const GraphServices = ({ date }) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const getServices = async (date = "") => {
      const { data } = await api.get(
        `/dashboard/most-wanted-services?day=${formatDate(
          date,
          "yyyy-MM-dd"
        ).replaceAll("/", "-")}`
      );
      setServices(data);
      /* console.log(JSON.stringify(data, null, 2)); */
    };

    getServices(date);
  }, [date]);

  const colors = ["#8ECC51", "#F56662", "#FFC06A", "#0CBCE4", "#CCCCCC"];

  return (
    <GraphicContainer>
      <h3>Top 4 Serviços mais buscados (quantidade)</h3>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie dataKey="count" data={services} outerRadius={100} label>
            {services.length > 0 &&
              services.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </GraphicContainer>
  );
};

export default GraphServices;
