import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Formik, Form as FormFormik } from "formik";

// services
import api from "../../services/api";
import { fireAction } from "../../utils/swal";

import validations from "./validations";

// components
import { Button } from "../../atoms/Button";

import FieldCustom from "../../organisms/FieldCustom";

// styles
import {
  Container,
  ContainerImg,
  Description,
  Header,
  GoBack,
  IconGoBack,
} from "./styles";

// assets
import logoImg from "../../assets/logomarca.png";

function ForgotPassword() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const { email } = values;

    try {
      const res = await api.post("/forgot-password", {
        email,
      });

      if (res.status === 200) {
        fireAction(
          "Verifique seu e-mail",
          "Enviamos um link de recuperação de senha para o e-mail informado.",
          "success"
        );
      }
    } catch (err) {
      fireAction(
        "Erro",
        "Falha ao recuperar sua conta, verifique o e-mail informado.",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <ContainerImg>
        <img src={logoImg} alt="Seg Lavanderia" />
      </ContainerImg>
      <Formik
        initialValues={initialValues}
        validationSchema={validations}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values).then(() =>
            resetForm({
              email: "",
            })
          );
        }}
      >
        {({ dirty, isValid, touched, errors, values }) => (
          <FormFormik>
            <Description>
              <Header>
                <h2>Esqueci minha senha</h2>

                <GoBack onClick={handleGoBack}>
                  <IconGoBack /> <span>Voltar</span>
                </GoBack>
              </Header>
              <em>
                Informe seu e-mail para enviarmos o link de alteração de senha.
              </em>
            </Description>
            <FieldCustom
              name="email"
              title="E-mail"
              placeholder="Entre com o e-mail..."
              touched={touched.email}
              errors={errors.email}
              value={values.email}
            />

            <Button
              disabled={!dirty || !isValid || isSubmitting}
              type="submit"
              mt="30"
              primary
              upper
              sm
              fullWidth
            >
              Enviar
            </Button>
          </FormFormik>
        )}
      </Formik>
    </Container>
  );
}

export default ForgotPassword;
