import React from "react";
import PropTypes from "prop-types";

// utils
import { formatDate } from "../../utils/date-format";
import { formatPrice } from "../../utils/price-format";
import { formatPhone } from "../../utils/phone-format";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Detail,
  GroupDetail,
  StatusPayment,
  List,
  Demand,
  Footer,
  Actions,
  Notes,
  IconDelete,
  ButtonsContainer,
} from "./styles";

import Switch from "../../atoms/Switch";
import { Button } from "../../atoms/Button";
import { IconContainer } from "../../atoms/IconContainer";
import { FaWhatsapp } from "react-icons/fa";

ModalOrderDetail.propTypes = {
  details: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleFinished: PropTypes.func,
  handleDelete: PropTypes.func,
  handlePayment: PropTypes.func.isRequired,
  handleRevert: PropTypes.func.isRequired,
  sendMessageOnWpp: PropTypes.func.isRequired,
};

function ModalOrderDetail({
  details,
  visible,
  sendMessageOnWpp,
  handleToggleVisibility,
  handleFinished,
  handleDelete,
  handlePayment,
  handleRevert,
}) {
  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Detalhes do pedido</Title>
        {Object.keys(details).length > 0 ? (
          <Detail>
            <p>
              Código OS <span>{details.code}</span>
            </p>
            <p>
              Data/horário do pedido{" "}
              <span>{formatDate(details.created_at, "dd-MM-yyyy-TT")}</span>
            </p>
            <GroupDetail>
              <p>
                Status{" "}
                <span>
                  {details.status === "pending" ? "Pendente" : "Finalizado"}
                </span>
              </p>
              <StatusPayment>
                <p>Pago </p>
                <Switch
                  value={details.paid || false}
                  handleChange={() => {
                    handlePayment(details.paid, details.code);
                  }}
                />
              </StatusPayment>
            </GroupDetail>
            <p>
              Forma de pagamento <span>{details.payment_type}</span>
            </p>
            {details.payment_date !== null && (
              <p>
                Data/horário do pagamento{" "}
                <span>
                  {formatDate(details?.payment_date, "dd-MM-yyyy-TT")}
                </span>
              </p>
            )}

            <h4>Dados do cliente</h4>

            <p>
              Código <span>{details.customer?.id}</span>
            </p>
            <p>
              Nome do cliente <span>{details.customer?.name}</span>
            </p>
            <div>
              <p>
                CPF{" "}
                <span>{details.customer?.cpf && details.customer?.cpf}</span>
              </p>
              <p>
                Contato{" "}
                <span>
                  {details.customer?.phone_number &&
                    formatPhone(details.customer?.phone_number)}
                </span>
              </p>
            </div>
            <p>
              Endereço{" "}
              <span>{`${
                details.customer?.address?.address &&
                details.customer?.address?.address
              }.
                ${
                  details.customer?.address?.cep &&
                  details.customer?.address?.cep
                }.
                 ${
                   details.customer?.address?.neighborhood &&
                   details.customer?.address?.neighborhood
                 }.
                  ${
                    details.customer?.address?.city &&
                    details.customer?.address?.city
                  }`}</span>
            </p>
            <h4>Lista do pedido</h4>
            <List>
              {details.items?.map((item, index) => {
                if (item.total > 0) {
                  return (
                    <Demand key={index}>
                      <p>
                        {item.service_id === null
                          ? `${item.product?.name} - ${formatPrice(
                              item?.unit_price
                            )}`
                          : `${item.service?.type} - ${
                              item.service?.description
                            } - ${formatPrice(item?.unit_price)}`}
                      </p>
                      <span>
                        {item?.quantity} und. {formatPrice(item?.total)}
                      </span>
                    </Demand>
                  );
                }
              })}
            </List>
            <b>
              Valor Total{" "}
              {formatPrice(
                details.items?.reduce(
                  (total, current) => total + current.total,
                  0
                )
              )}
            </b>
            <Notes>
              <p>
                Observação <span>{details.notes && details.notes}</span>
              </p>
              <em>
                O prazo para recolhimento das peças deixadas em nossa lavanderia
                é de no máximo 90 dias (três meses).
              </em>
            </Notes>
            <Footer>
              {details.status === "pending" ? (
                <>
                  <ButtonsContainer>
                    <Button
                      primary
                      sm
                      onClick={() => handleFinished(details.code)}
                    >
                      Finalizar Pedido
                    </Button>
                    <Button tertiary sm onClick={() => window.print()}>
                      Imprimir
                    </Button>
                  </ButtonsContainer>
                  <Actions>
                    <li>
                      <IconContainer
                        onClick={() =>
                          sendMessageOnWpp(
                            details.customer?.phone_number,
                            details
                          )
                        }
                      >
                        <FaWhatsapp />
                      </IconContainer>
                    </li>
                    <li>
                      <IconContainer onClick={() => handleDelete(details.code)}>
                        <IconDelete color="#f56662" />
                      </IconContainer>
                    </li>
                  </Actions>
                </>
              ) : (
                <>
                  <ButtonsContainer direction="row">
                    <Button
                      tertiary
                      sm
                      onClick={() => handleRevert(details.code)}
                    >
                      Reverter
                    </Button>
                    <Button tertiary sm onClick={() => window.print()}>
                      Imprimir
                    </Button>
                  </ButtonsContainer>
                  <Actions>
                    <li>
                      <IconContainer
                        onClick={() =>
                          sendMessageOnWpp(
                            details.customer?.phone_number,
                            details
                          )
                        }
                      >
                        <FaWhatsapp />
                      </IconContainer>
                    </li>
                  </Actions>
                </>
              )}
            </Footer>
          </Detail>
        ) : (
          <Detail>
            <p>
              Carregando<span>...</span>
            </p>
          </Detail>
        )}
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalOrderDetail;
