import React from "react";
import PropTypes from "prop-types";

//utils
import { formatPrice } from "../../utils/price-format";
import { formatDate } from "../../utils/date-format";

// services

import { Button } from "../../atoms/Button";
import { IconContainer } from "../../atoms/IconContainer";
import DataNotFound from "../../atoms/DataNotFound";
import Switch from "../../atoms/Switch";

import {
  IconDelete,
  CardsGrid,
  Card,
  Acordeon,
  AccordionShow,
  ControlAcordeon,
  Actions,
  Key,
  Value,
  IconAcordeon,
  KeyPayment,
  ValuePayment,
} from "./styles";

TableDemandProgress.propTypes = {
  demands: PropTypes.array.isRequired,
  handleDelete: PropTypes.func,
  handleFinished: PropTypes.func,
  handlePayment: PropTypes.func,
  handleDetail: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

function TableDemandProgress({
  demands,
  isSubmitting,
  details,
  handleDelete,
  handleFinished,
  handlePayment,
  handleDetail,
}) {
  return (
    <>
      {demands.length === 0 ? (
        <DataNotFound message="Nenhum resultado encontrado" />
      ) : (
        <CardsGrid>
          {demands.map((demand) => (
            <Card key={demand.code}>
              <Key>
                Código
                <Value>{demand.code}</Value>
              </Key>

              <AccordionShow type="checkbox" id={demand.code} />
              <ControlAcordeon htmlFor={demand.code}>
                <IconAcordeon />
              </ControlAcordeon>

              <Acordeon className="card">
                <Key>
                  Nome
                  <Value>
                    {demand.customer?.id} - {demand.customer?.name}
                  </Value>
                </Key>
                <Key>
                  Total
                  <Value>{formatPrice(demand.total)}</Value>
                </Key>
                <Key>
                  Data de pagamento
                  <Value>
                    {demand.payment_date !== null
                      ? formatDate(demand?.payment_date, "dd-MM-yyyy")
                      : "Em espera"}
                  </Value>
                </Key>
                <KeyPayment>
                  <p>Pago</p>
                  <ValuePayment>
                    <Switch
                      disabled={isSubmitting}
                      value={demand.paid}
                      handleChange={() =>
                        handlePayment(demand.paid, demand.code)
                      }
                    />
                  </ValuePayment>
                </KeyPayment>

                <Actions>
                  <Button
                    onClick={() => handleFinished(demand.code)}
                    disabled={isSubmitting}
                    tertiary
                    sm
                  >
                    Finalizar pedido
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => handleDetail(demand.code)}
                    tertiary
                    sm
                  >
                    Ver detalhes
                  </Button>
                  <IconContainer onClick={() => handleDelete(demand.code)}>
                    <IconDelete disabled={isSubmitting} />
                  </IconContainer>
                </Actions>
              </Acordeon>
            </Card>
          ))}
        </CardsGrid>
      )}
    </>
  );
}

export default TableDemandProgress;
