import styled, { css } from "styled-components";
import {
  FaUsers,
  FaChartLine,
  FaListAlt,
  FaTshirt,
  FaBoxOpen,
  FaDollarSign,
  FaCog,
} from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";
import Fonts from "../../settings/fonts";

export const LateralMenu = styled.nav`
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  flex: none;

  width: ${(props) => (props.isVisible ? "260px" : "80px")};
  transition: width 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  height: 100vh;
  padding: calc(${Metrics.spaceMd} - 6px) 0;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;

  background: ${Colors.darkBlue};
  box-shadow: 3px 0px 6px ${Colors.greyShadow};

  z-index: 1000;

  @media (max-width: 800px) {
    position: absolute;
    width: ${(props) => (props.isVisible ? "260px" : "0px")};
  }
`;

export const Header = styled.header`
  width: 260px;
  height: 40px;

  width: ${(props) => (props.isVisible ? "260px" : "40px")};
  padding: ${(props) =>
    props.isVisible ? `0 ${Metrics.spaceSm} 0 ${Metrics.spaceMd}` : "0px"};
  transition: width 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  margin: 0 auto;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > img {
    height: ${(props) => (props.isVisible ? "40px" : "0")};
    opacity: ${(props) => (props.isVisible ? "1" : "0")};
    transition: height 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955),
      opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  @media (max-width: 800px) {
    > img {
      height: 30px;
    }
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto;
  overflow: hidden;
  background: ${Colors.darkBlue};

  width: ${(props) => (props.isVisible ? "220px" : "48px")};
  padding: ${(props) =>
    props.isVisible ? `4px ${Metrics.spaceSm} 4px 4px` : "4px"};

  box-shadow: 0 3px 6px ${Colors.greyShadow};
  border-radius: ${(props) =>
    props.isVisible ? "40px" : `${Metrics.radiusCircle}`};

  transition: width 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    border-radius 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  p {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: ${Metrics.spaceXs};

    span.user-logged {
      display: block;
      position: relative;
      font-size: ${Fonts.textMd};
      color: ${Colors.white};
      font-weight: ${Fonts.boldWeight};
      margin: 0 ${Metrics.spaceXs};

      text-transform: none;
      margin-left: 5px;

      max-width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span.user-role {
      display: block;
      font-size: ${Fonts.textSm};
      color: ${Colors.white};
      font-weight: ${Fonts.normalWeight};
      margin: 0 ${Metrics.spaceXs};
      text-transform: none;
      margin-left: 5px;

      max-width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const MenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  li {
    margin-top: 10px;
    > a {
      > span {
        display: ${(props) => (props.isVisible ? "block" : "none")};
        opacity: ${(props) => (props.isVisible ? "1" : "0")};
        visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
        height: ${(props) => (props.isVisible ? "auto" : "0")};

        overflow: hidden;
        white-space: nowrap;

        transition: visibility 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955),
          opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955),
          height 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
    }
  }
`;

export const MenuItem = styled.li`
  > a {
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: ${Metrics.spaceSm} calc(${Metrics.spaceMd} + 3px);
    position: relative;

    transition: background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);

    color: ${Colors.white};

    > span {
      margin-left: ${Metrics.space};
    }

    &:not(:first-child) {
      margin-top: calc(${Metrics.spaceSm} + ${Metrics.spaceXs});
    }

    ::after {
      content: "";
      position: absolute;
      background: ${Colors.orange};
      width: 0px;
      height: 100%;
      left: 0;
      top: 0;
      transition: width 0.3s cubic-bezier(0.86, 0, 0.07, 1);
    }

    &:hover {
      background: ${Colors.darkBlueHover};
      &:after {
        width: 6px;
      }
    }
    &.active {
      background: ${Colors.orange};
    }
  }
`;

const iconCSS = css`
  width: 24px;
  height: 24px;
  color: ${Colors.darkBlue};
`;

export const IconMenu = styled.button`
  display: flex;
  flex-direction: column;
  position: absolute;
  cursor: pointer;
  top: 24px;
  left: 28px;
  z-index: 1002;

  span {
    width: 24px;
    height: 2px;
    background: ${Colors.white};
    transition: transform 0.2s cubic-bezier(0.47, 0, 0.745, 0.715),
      opacity 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);

    @media (max-width: 800px) {
      background: ${(props) =>
        props.isVisible ? `${Colors.white}` : `${Colors.darkBlue}`};
    }

    transform-origin: center;
  }

  span:not(:first-child) {
    margin-top: 6px;
  }

  span:nth-child(1) {
    transform: ${(props) =>
      props.isVisible
        ? "rotate(45deg) translateY(12px)"
        : "rotate(0deg) translateY(0px)"};
  }
  span:nth-child(2) {
    opacity: ${(props) => (props.isVisible ? "0" : "inheit")};
  }
  span:nth-child(3) {
    transform: ${(props) =>
      props.isVisible
        ? "rotate(-45deg) translateY(-12px)"
        : "rotate(0deg) translateY(0px)"};
  }
`;

export const IconControls = styled(FaChartLine)`
  ${iconCSS};
  cursor: pointer;
  color: ${Colors.white};
`;

export const IconUsers = styled(FaUsers)`
  ${iconCSS};
  cursor: pointer;
  color: ${Colors.white};
`;

export const IconList = styled(FaListAlt)`
  ${iconCSS};
  cursor: pointer;
  color: ${Colors.white};
`;

export const IconTShirt = styled(FaTshirt)`
  ${iconCSS};
  cursor: pointer;
  color: ${Colors.white};
`;

export const IconBox = styled(FaBoxOpen)`
  ${iconCSS};
  cursor: pointer;
  color: ${Colors.white};
`;

export const IconDollar = styled(FaDollarSign)`
  ${iconCSS};
  cursor: pointer;
  color: ${Colors.white};
`;

/* export const IconArrow = styled(FiArrowRight)`
  ${iconCSS};
  cursor: pointer;
  color: ${Colors.white};
`; */

export const IconLogOut = styled(FiLogOut)`
  ${iconCSS};
  cursor: pointer;
  color: ${Colors.white};
`;

export const IconConfiguration = styled(FaCog)`
  ${iconCSS};
  cursor: pointer;
  color: ${Colors.white};
`;
