import styled, { css } from "styled-components";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";
import Fonts from "../../settings/fonts";

import { FiTrash2, FiChevronDown } from "react-icons/fi";

const iconCSS = css`
  color: ${Colors.darkBlue};
  width: 22px;
  height: 22px;
`;
export const IconDelete = styled(FiTrash2)`
  ${iconCSS};
  cursor: pointer;
  margin-left: ${Metrics.spaceMd};
`;

export const IconAcordeon = styled(FiChevronDown)`
  ${iconCSS};

  translate: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: ${Metrics.space};
  }
`;

/** RESPONSIVIDADE CARDS */

export const CardsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
  margin-top: 40px;
  margin-bottom: 20px;
  grid-gap: 10px;
`;

export const Card = styled.div`
  width: 100%;

  background: ${Colors.white};

  border-radius: ${Metrics.radiusSm};
  box-shadow: 0 3px 6px ${Colors.greyShadow};
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 1089px) {
    flex-direction: column;
  }
`;

export const ControlAcordeon = styled.label`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;

  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  transition: background 0.2s cubic-bezier(0.47, 0, 0.745, 0.715),
    color 0.2s cubic-bezier(0.47, 0, 0.745, 0.715),
    transform 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);

  &:hover {
    background: ${Colors.lightGrey};

    > svg {
      color: ${Colors.lightBlue};
    }
  }

  &:active {
    background: ${Colors.lightBlue};

    > svg {
      color: ${Colors.white};
    }
  }

  display: none;

  @media (max-width: 1089px) {
    display: flex;
  }
`;

export const AccordionShow = styled.input`
  display: none;

  &:checked ~ .card {
    visibility: visible;
    opacity: 1;
    display: flex;
  }
  &:checked ~ label {
    transform: rotate(180deg);
  }
`;

export const Acordeon = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  align-items: flex-start;

  @media (max-width: 1089px) {
    flex-direction: column;
    margin-top: 10px;
    padding-top: 10px;

    visibility: hidden;
    opacity: 0;
    display: none;
  }
`;

export const Key = styled.p`
  color: ${Colors.lightBlue};
  font-weight: ${Fonts.boldWeight};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 130px;
  width: 100%;

  padding: 0 5px;

  @media (max-width: 1089px) {
    flex-direction: row;
    max-width: max-content;
    padding: 0;
  }
`;

export const KeyPayment = styled.div`
  color: ${Colors.lightBlue};
  font-weight: ${Fonts.boldWeight};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 130px;
  width: 100%;

  @media (max-width: 1089px) {
    flex-direction: row;
    max-width: max-content;
  }
`;

export const Value = styled.span`
  color: ${Colors.black};
  font-weight: ${Fonts.regularWeight};

  @media (max-width: 1089px) {
    margin-left: 10px;
  }
`;

export const ValuePayment = styled.div``;

export const Actions = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  place-items: center;

  button {
    width: 100%;
  }

  @media (max-width: 1089px) {
    margin-top: 20px;
  }

  @media (max-width: 598px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;
