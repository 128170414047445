import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { formatDate } from "../../../utils/date-format";
import { formatPrice } from "../../../utils/price-format";

import TableInputCash from "../../../organisms/TableInputCash";
import PaginationTab from "../../../atoms/PaginationTab";

import api from "../../../services/api";
import { useStore } from "../../../store";

import {
  Title,
  DateTime,
  Subtotal,
  TableBox,
  Actions,
  TabNavigation,
} from "./styles";

InputCash.propTypes = {
  dateToFilter: PropTypes.any.isRequired,
};

function InputCash({ dateToFilter }) {
  const { state, dispatch } = useStore();

  const [inputCash, setInputCash] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);

  const getInputCash = React.useCallback(
    async (page, date = "", mounted) => {
      const { data } = await api.get(
        `/transactions/in?day=${formatDate(date, "yyyy-MM-dd").replaceAll(
          "/",
          "-"
        )}&page=${page}&limit=10`
      );

      if (mounted) {
        dispatch({
          type: "SET_INPUT_TOTAL",
          inputTotal: data?.total,
        });
      }

      setInputCash(data.data);
      setLastPage(data.meta?.last_page);
    },
    [dispatch]
  );

  useEffect(() => {
    let mounted = true;
    getInputCash(page, dateToFilter, mounted);

    return () => (mounted = false);
  }, [page, getInputCash, dateToFilter]);

  return (
    <TableBox>
      <Actions>
        <Title cashType="inputCash">
          Entradas
          <DateTime cashType="inputCash">
            {formatDate(dateToFilter, "day dd-MM-yyyy")}
          </DateTime>
        </Title>
        <Subtotal cashType="inputCash">
          <p>SubTotal de Entradas</p>
          <p>{formatPrice(state.inputTotal)}</p>
        </Subtotal>
      </Actions>
      <TableInputCash title="Entradas" type="inputCash" items={inputCash} />
      {lastPage > 1 && (
        <TabNavigation>
          <PaginationTab
            count={lastPage}
            page={page}
            handleChange={(event, value) => {
              setPage(value);
            }}
          />
        </TabNavigation>
      )}
    </TableBox>
  );
}

export default InputCash;
