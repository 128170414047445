import styled from "styled-components";

import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";
import Metrics from "../../settings/metrics";

export const Error = styled.span`
  text-align: left;
  font-size: ${Fonts.textMd};
  font-weight: ${Fonts.semiBoldWeight};
  color: ${Colors.darkWarn};
  line-height: ${Fonts.titleSm};
  margin-top: calc(${Metrics.spaceSm} - 0.7px);
`;
