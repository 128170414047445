import styled, { css } from "styled-components";

import { FiX, FiTrash2 } from "react-icons/fi";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";
import Fonts from "../../settings/fonts";

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1299;

  display: ${(props) => (props.visible ? "flex" : "none")};

  justify-content: center;

  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ModalContent = styled.div`
  position: absolute;
  left: 50%;

  transform: translate(-50%, 0px);
  padding: ${Metrics.space} ${Metrics.spaceLg};

  max-width: 500px;
  width: 100%;
  min-height: 500px;

  border-radius: ${Metrics.radiusSm};
  background: ${Colors.white};
  margin: ${Metrics.space} 0;

  fieldset {
    margin: ${Metrics.space} auto;

    legend {
      color: ${Colors.darkGrey};
      font-size: ${Fonts.textLg};
      font-weight: ${Fonts.boldWeight};
      margin: ${Metrics.space} 0;
    }
  }

  @media (max-width: 768px) {
    max-width: 500px;
    width: 94%;
    padding: ${Metrics.space};
  }
`;

export const Title = styled.h4`
  font-size: ${Fonts.titleMd};
  color: ${Colors.lightBlue};

  @media (max-width: 404px) {
    width: 180px;
  }
`;

export const Form = styled.form`
  margin-top: ${Metrics.spaceSm};
  display: flex;
  flex-direction: column;
`;
export const Group = styled.div``;

export const Detail = styled.div`
  padding: ${Metrics.spaceSm};
  margin: ${Metrics.spaceSm} auto;

  p {
    color: ${Colors.lightBlue};
    font-weight: ${Fonts.boldWeight};
    margin: ${Metrics.spaceSm} 0;
    span {
      color: ${Colors.darkGrey};
      font-weight: ${Fonts.semiBoldWeight};
      margin-right: ${Metrics.spaceSm};
    }
  }
  h4 {
    margin: ${Metrics.space} 0;
    color: ${Colors.darkGrey};
    font-weight: ${Fonts.boldWeight};
  }
  b {
    display: flex;
    justify-content: flex-end;
    color: ${Colors.darkBlue};
    font-weight: ${Fonts.boldWeight};
    font-size: ${Fonts.titleSm};
  }
`;

export const Notes = styled.div`
  margin-top: ${Metrics.spaceMd};

  p {
    color: ${Colors.lightBlue};
    font-weight: ${Fonts.boldWeight};
    margin: ${Metrics.spaceSm} 0;
    span {
      color: ${Colors.darkGrey};
      font-weight: ${Fonts.semiBoldWeight};
      margin-right: ${Metrics.spaceSm};
    }
  }
`;

export const GroupDetail = styled.div`
  display: flex;
  align-items: center;
`;
export const StatusPayment = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${Metrics.spaceMd};
`;

export const List = styled.ul``;

export const Demand = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.white};
  box-shadow: 0 3px 6px ${Colors.greyShadow};
  margin-bottom: ${Metrics.spaceMd};
  padding: ${Metrics.spaceSm};
  border-radius: ${Metrics.radiusSm};

  p {
    color: ${Colors.black};
    font-weight: ${Fonts.boldWeight};
    max-width: 60%;
    font-size: ${Fonts.textMd};

    @media (max-width: 422px) {
      max-width: 100%;
    }
  }
  span {
    color: ${Colors.lightBlue};
    font-weight: ${Fonts.boldWeight};
  }

  @media (max-width: 422px) {
    flex-direction: column;
    align-items: flex-start;
    padding: ${Metrics.spaceMd};
  }
`;

export const Footer = styled.footer`
  margin: ${Metrics.spaceMd} auto ${Metrics.spaceSm};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 408px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    width: 100%;

    > button {
      width: 100%;
    }
  }
`;

export const Actions = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;

  li + li {
    margin-left: calc(${Metrics.space} + ${Metrics.spaceSm});
  }

  li {
    svg {
      width: 22px;
      height: 22px;
      color: ${(props) => props.color || Colors.darkBlue};
      cursor: pointer;
    }
  }
  @media (max-width: 408px) {
    margin-top: ${Metrics.spaceMd};
  }
`;

export const CloseModal = styled.button`
  background: none;
  border: none;

  position: absolute;
  right: ${Metrics.space};
  top: ${Metrics.space};
`;

const iconCSS = css`
  width: 24px;
  height: 24px;
  color: ${Colors.darkBlue};
`;

export const IconCloseModal = styled(FiX)`
  ${iconCSS};
  stroke-width: 4px;
`;

/*  */
/* exclusiva para a impressão térmica */
export const ToPrint = styled.div`
  display: none;

  p,
  span,
  address {
    font-size: 12px;
  }

  > p:nth-child(1) {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  div {
    margin: 8px 0;
    padding-top: 5px;
  }

  div + div {
    border-top: 1px dashed grey;
  }

  div:last-child {
    text-align: center;
  }

  div:last-child em {
    margin-top: 5px;
    display: inline-block;
    font-size: 10px;
  }

  div:nth-last-child(2) p {
    display: flex;
    justify-content: space-between;
  }

  div.resume {
    fieldset {
      p {
        font-size: 10px;
        padding: 5px;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.direction ? `${props.direction}` : "column"};

  button {
    width: 100%;
  }
  button + button {
    margin: ${(props) => (props.direction ? "0 0 0 5px" : "5px 0 0")};
  }

  @media (max-width: 360px) {
    flex-direction: column;

    button + button {
      margin: 5px 0 0;
    }
  }
`;

export const IconDelete = styled(FiTrash2)`
  transition: color 0.2s ease-out;
`;
