import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormFormik } from "formik";
import VMasker from "vanilla-masker";

import { formatToUpdate } from "../../utils/price-format";
import { increment, decrement } from "../../utils/controls-input-number";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Form,
} from "./styles";
import { Button } from "../../atoms/Button";
import SelectCustom from "../../atoms/SelectCustom";

import FieldCustom from "../../organisms/FieldCustom";
import InputNumber from "../../atoms/InputNumber";
import TextArea from "../../atoms/TextArea";

import validations from "./validations";
import { measures } from "../../utils/service_types";

ModalServiceUpdate.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

function ModalServiceUpdate({
  visible,
  handleToggleVisibility,
  service,
  isSubmitting,
  handleSubmit,
}) {
  const [initialValues, setInitialValues] = useState({
    id: "",
    type: "",
    description: "",
    unit_price: "",
  });

  useEffect(() => {
    setInitialValues({
      id: service.id ? service.id : "",
      type: service.type ? service.type : "",
      description: service.description ? service.description : "",
      unit_price: service.unit_price ? formatToUpdate(service.unit_price) : "",
    });
  }, [service]);

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Atualizar dados do serviço</Title>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {({
            dirty,
            isValid,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
          }) => (
            <FormFormik as={Form}>
              <fieldset>
                <FieldCustom
                  required
                  component={SelectCustom}
                  name="type"
                  title="Tipo de serviço"
                  placeholder="Tipo de serviço..."
                  touched={touched.type}
                  errors={errors.type}
                  value={values.type}
                  data={measures}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                />

                <FieldCustom
                  as={TextArea}
                  name="description"
                  type="text"
                  title="Descrição"
                  placeholder="Descrição..."
                  touched={touched.description}
                  errors={errors.description}
                  value={values.description}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                  maxLength={150}
                />

                <FieldCustom
                  as={InputNumber}
                  name="unit_price"
                  type="text"
                  title="Valor unitário"
                  placeholder="Valor unitário (reais)"
                  touched={touched.unit_price}
                  errors={errors.unit_price}
                  value={values.unit_price}
                  handleChange={(e) => {
                    setFieldValue(
                      "unit_price",
                      VMasker.toMoney(e.target.value)
                    );
                  }}
                  handleBlur={(value) => handleBlur(value)}
                  increment={() =>
                    setFieldValue(
                      "unit_price",
                      VMasker.toMoney(increment(values.unit_price))
                    )
                  }
                  decrement={() =>
                    setFieldValue(
                      "unit_price",
                      VMasker.toMoney(decrement(values.unit_price))
                    )
                  }
                />

                {/* <FieldCustom
                  required
                  component={SelectCustom}
                  name="unit"
                  title="Unidade de medida"
                  placeholder="Unidade de medida..."
                  touched={touched.unit}
                  errors={errors.unit}
                  data={unitys}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                /> */}
              </fieldset>
              <Button
                disabled={!dirty || !isValid || isSubmitting}
                type="submit"
                mt="30"
                primary
                upper
                sm
                fullWidth
              >
                Atualizar
              </Button>
            </FormFormik>
          )}
        </Formik>
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalServiceUpdate;
