import * as yup from "yup";

const validations = yup.object().shape({
  type: yup.string().required("Campo não-preenchido"),
  description: yup
    .string()
    .max(150, "Limite máximo de caracteres excedido")
    .required("Campo não-preenchido"),
  unit_price: yup
    .string()
    .required("Campo não-preenchido")
    .matches(
      /(?=.*\d)^\$?(([1-9]\d{0,2}(.\d{3})*)|0)?(,\d{1,2})?$/,
      "Insira um valor positivo no formato R$ 0.000,00"
    ),
  /*   unit: yup.string().required("Campo não-preenchido"), */
});

export default validations;
