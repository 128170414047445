import styled from "styled-components";

import Metrics from "../../settings/metrics";
import Colors from "../../settings/colors";

/***
 * Analisar o overflow scroll / padding bottom após finalizar
 */
export const Container = styled.div`
  margin: calc(${Metrics.spaceXl} + 20px) 10px calc(${Metrics.spaceMd} * 2) 40px;
  padding: 0 10px calc(${Metrics.spaceXl} + 20px);
  width: 100%;
  height: calc(100vh - ${Metrics.marginNavbarToBody});
  overflow-x: hidden;
  overflow-y: scroll;

  @media (max-width: 800px) {
    margin: calc(${Metrics.spaceXl} + 20px) ${Metrics.spaceXs};
    padding: 0 10px;
  }

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
    background: ${Colors.greyShadow};
  }
  ::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 4px;
    background: ${Colors.lightBlue};
  }

  /* ::-webkit-scrollbar {
    display: none;
  } */
`;
