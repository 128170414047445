export const handleChangeCpfMask = (value) => {
  if (value.length <= 14) {
    return "999.999.999-99";
  } else {
    return "99.999.999/9999-99";
  }
};

export const handleChangePhoneMask = (value) => {
  const string = value.replace(/[^A-Z0-9]+/gi, "");

  if (string.length <= 8) {
    return "9999-9999";
  } else if (string.length === 9) {
    return "99999-9999";
  } else if (string.length === 10) {
    return "(99) 9999-9999";
  } else if (string.length > 10) {
    return "(99) 99999-9999";
  }
};
