import React from "react";
import PropTypes from "prop-types";

import { Input } from "../../atoms/Input";

import { Container, Wrapper, TextControl, Count } from "./styles";

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  maxLength: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
};

function TextArea({
  name,
  value,
  handleChange,
  labelSecondary,
  handleBlur,
  maxLength,
  placeholder,
}) {
  const [rows, setRows] = React.useState(1);

  function insertLine(value) {
    const increment = value / 20;
    if (increment > 1) {
      setRows(Math.round(increment));
    } else {
      setRows(1);
    }

    return rows;
  }

  return (
    <Container>
      <Wrapper>
        <Input
          as="textarea"
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          onKeyUp={(e) => insertLine(value?.replaceAll(" ", "")?.length)}
          rows={rows}
        />
        <TextControl>
          <Count
            error={value?.length >= maxLength + 1 ? true : false}
            secondary={labelSecondary}
          >
            {value?.length}/{maxLength}
          </Count>
        </TextControl>
      </Wrapper>
    </Container>
  );
}

export default TextArea;
