import styled from "styled-components";

import Metrics from "../../settings/metrics";
import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";

import { FiChevronLeft } from "react-icons/fi";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 40px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    padding: 40px;
    max-width: 480px;
    width: 90%;
    background: ${Colors.white};
    border-radius: ${Metrics.radiusSm};
    box-shadow: 0px 3px 6px ${Colors.greyShadow};
    position: relative;

    @media (max-width: 400px) {
      padding: 20px;
    }
  }
`;

export const ContainerImg = styled.div`
  max-width: 300px;
  margin-bottom: ${Metrics.spaceLg};

  > img {
    width: 100%;
    margin: 0 auto;
    display: block;

    @media (max-width: 604px) {
      width: 80%;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${Metrics.spaceLg};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Metrics.spaceMd};

  h2 {
    color: ${Colors.lightBlue};
  }

  @media (max-width: 456px) {
    justify-content: center;
    align-items: center;

    h2 {
      margin-top: ${Metrics.spaceMd};
    }
  }
`;

export const GoBack = styled.div`
  display: flex;
  align-items: center;
  font-size: ${Fonts.textLg};
  cursor: pointer;
  color: ${Colors.darkBlue};
  font-weight: ${Fonts.boldWeight};

  transition: all 0.2s ease-out;

  &:hover {
    color: ${Colors.white};
    background: ${Colors.lightBlue};

    svg {
      color: ${Colors.white};
    }
  }

  &:active {
    color: ${Colors.white};
    background: ${Colors.darkBlue};

    svg {
      color: ${Colors.white};
    }
  }

  @media (max-width: 456px) {
    position: absolute;
    top: 15px;
    right: 20px;
  }
`;

export const IconGoBack = styled(FiChevronLeft)`
  color: ${Colors.darkBlue};
  width: 18px;
  height: 18px;
  margin-right: ${Metrics.spaceXs};
`;
