import styled, { css } from "styled-components";

import { FiChevronLeft } from "react-icons/fi";

import Colors from "../../../settings/colors";
import Fonts from "../../../settings/fonts";
import Metrics from "../../../settings/metrics";

export const Title = styled.p`
  display: flex;
  flex-direction: column;

  font-size: ${Fonts.titleMd};
  font-weight: ${Fonts.boldWeight};
  letter-spacing: ${Fonts.titleSpacingS};

  ${(props) =>
    props.cashType === "inputCash" &&
    css`
      color: ${Colors.lightBlue};
    `}

  ${(props) =>
    props.cashType === "outputCash" &&
    css`
      color: ${Colors.orange};
    `}
`;

export const Actions = styled.div`
  margin: ${Metrics.space} 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 320px));
  grid-gap: 10px;

  justify-content: flex-start;
  align-items: center;
  min-height: 40px;

  button {
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  button + button {
    margin-left: ${Metrics.spaceMd};

    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: calc(${Metrics.spaceSm} + ${Metrics.spaceXs});
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

export const GoBack = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${Colors.darkBlue};
  font-size: ${Fonts.textMd};
  padding: ${Metrics.spaceSm};
`;

export const IconGoBack = styled(FiChevronLeft)`
  color: ${Colors.darkBlue};
  width: 24px;
  height: 240x;
`;

export const DateTime = styled.span`
  font-size: ${Fonts.titleSm};
  color: ${Colors.darkGrey};
`;

export const TableBox = styled.div`
  min-height: 400px;
  padding: ${Metrics.spaceSm};
`;

export const Subtotal = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    font-weight: ${Fonts.boldWeight};
    font-size: ${Fonts.textLg};

    ${(props) =>
      props.cashType === "inputCash" &&
      css`
        color: ${Colors.lightBlue};
      `}

    ${(props) =>
      props.cashType === "outputCash" &&
      css`
        color: ${Colors.orange};
      `}
  }
`;

export const TabNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: ${Metrics.space};
`;
