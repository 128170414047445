import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import api from "../../../services/api";
import { formatDate } from "../../../utils/date-format";
import { formatPrice } from "../../../utils/price-format";

import { Grid, Card, CardContent, Link } from "./styles";

import ModalClientsTotal from "../../../organisms/ModalClientsTotal";
import ModalClientsDefaulters from "../../../organisms/ModalClientsDefaulters";

function Resume({ date }) {
  const history = useHistory();

  const [activeCustomers, setActiveCustomers] = useState({});
  const [defaultersCustomers, setDefaultersCustomers] = useState({});
  const [amountReceivable, setAmountReceivable] = useState({});

  const getActiveCustomers = useCallback(async (date = "") => {
    const { data } = await api.get(
      `/dashboard/active-customers?day=${formatDate(
        date,
        "yyyy-MM-dd"
      ).replaceAll("/", "-")}`
    );
    setActiveCustomers(data);
  }, []);

  const getDefaultersCustomers = useCallback(async (date = "") => {
    const { data } = await api.get(
      `/dashboard/defaulter-customers?day=${formatDate(
        date,
        "yyyy-MM-dd"
      ).replaceAll("/", "-")}`
    );
    setDefaultersCustomers(data);
  }, []);

  const getAmountReceivable = useCallback(async (date = "") => {
    const { data } = await api.get(
      `/dashboard/amount-receivable?day=${formatDate(
        date,
        "yyyy-MM-dd"
      ).replaceAll("/", "-")}`
    );
    setAmountReceivable(data);
  }, []);

  useEffect(() => {
    getActiveCustomers(date);
    getDefaultersCustomers(date);
    getAmountReceivable(date);
  }, [date, getActiveCustomers, getAmountReceivable, getDefaultersCustomers]);

  const [isModalClientsTotalVisible, setIsModalClientsTotalVisible] = useState(
    false
  );
  const [
    isModalClientsDefaultersVisible,
    setIsModalClientsDefaultersVisible,
  ] = useState(false);

  const handleToggleModalClientsTotalVisibility = () => {
    setIsModalClientsTotalVisible(!isModalClientsTotalVisible);
  };

  const handleToggleModalClientsDefaultersVisibility = () => {
    setIsModalClientsDefaultersVisible(!isModalClientsDefaultersVisible);
  };

  const navigateToDemands = () => {
    history.push("/demand");
  };

  return (
    <>
      <ModalClientsDefaulters
        visible={isModalClientsDefaultersVisible}
        handleToggleVisibility={handleToggleModalClientsDefaultersVisibility}
        date={date}
      />
      <ModalClientsTotal
        visible={isModalClientsTotalVisible}
        handleToggleVisibility={handleToggleModalClientsTotalVisibility}
        date={date}
      />

      <Grid>
        <Card>
          <CardContent
            customersActive
            onClick={handleToggleModalClientsTotalVisibility}
          >
            <p>
              {activeCustomers?.actives || 0}
              <span>Clientes Ativos</span>
            </p>
            <Link customersActive>Total de {activeCustomers?.total || 0}</Link>
          </CardContent>
        </Card>
        <Card>
          <CardContent
            customersDefaulters
            onClick={handleToggleModalClientsDefaultersVisibility}
          >
            <p>
              {defaultersCustomers?.defaulters || 0}
              <span>Clientes Inadimplentes</span>
            </p>
            <Link customersDefaulters>
              Em {defaultersCustomers?.orders_count || 0} pedidos
            </Link>
          </CardContent>
        </Card>
        <Card>
          <CardContent receivable onClick={navigateToDemands}>
            <p>
              {formatPrice(amountReceivable?.value || 0)}
              <span>A receber</span>
            </p>
            <Link receivable>
              Em {amountReceivable?.orders_count || 0} pedidos
            </Link>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default Resume;
