import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    box-sizing: border-box;

    outline: none;
    border: 0;
    margin: 0;
    padding: 0;

    background: transparent;
    letter-spacing: 1px;

    font-family: "Source Sans Pro", sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
  
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100vh;
  }

  input, textarea, select, button {
    font-family: "Source Sans Pro", sans-serif;
  }

  button {
    cursor: pointer;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
  em {
    text-transform: initial;
    font-weight: normal;
  }

  @media print {
    body * {
    visibility: hidden;
    }
    body {
        max-width: 300px;
        margin: 0 auto;
        > * ::-webkit-scrollbar{
          display: none;
        }
    }
    #to-print, #to-print * {
      visibility: visible;
    }
    #to-print {
      display: unset;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

`;
