import React, { useState } from "react";

import { useStore } from "../../store";
import { useHistory, Link } from "react-router-dom";
import { Toast } from "../../utils/swal";

import { Formik, Form as FormFormik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// services
import { login, saveUserInfo } from "../../services/auth";
import api from "../../services/api";
import { fireAction } from "../../utils/swal";

import validations from "./validations";
// styles
import { Container, ContainerImg, ForgotPassword } from "./styles";

// components
import { Button } from "../../atoms/Button";
import Icon from "../../atoms/Icon";

import FieldCustom from "../../organisms/FieldCustom";

// assets
import logoImg from "../../assets/logomarca.png";

function Login() {
  const { dispatch } = useStore();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  function handleTogglePasswordVisible() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const { email, password } = values;

    try {
      const { data } = await api.post("/login", {
        email,
        password,
      });

      if (data.token) {
        Toast.fire({
          icon: "success",
          title: "Logado com sucesso. Bem vindo!",
        });

        dispatch({
          type: "SET_USER_ACCOUNT",
          role: data.role,
          name: data.name,
          email: data.email,
        });

        login(data.token);
        saveUserInfo(data.role, data.name, data.email);

        history.push("/home");
      }
    } catch (err) {
      fireAction(
        "Erro",
        "Falha ao efetuar login, verifique suas credenciais",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <ContainerImg>
        <img src={logoImg} alt="Seg Lavanderia" />
      </ContainerImg>
      <Formik
        initialValues={initialValues}
        validationSchema={validations}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values).then(() =>
            resetForm({
              email: "",
              password: "",
            })
          );
        }}
      >
        {({ dirty, isValid, touched, errors, values }) => (
          <FormFormik>
            <FieldCustom
              name="email"
              title="E-mail"
              placeholder="Entre com o e-mail..."
              touched={touched.email}
              errors={errors.email}
              value={values.email}
            />

            <FieldCustom
              name="password"
              title="Senha"
              placeholder="Digite sua senha..."
              touched={touched.password}
              errors={errors.password}
              value={values.password}
              type={isPasswordVisible ? "text" : "password"}
              icon={
                <Icon handleClick={handleTogglePasswordVisible}>
                  {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </Icon>
              }
            />

            <Button
              disabled={!dirty || !isValid || isSubmitting}
              type="submit"
              mt="30"
              primary
              upper
              sm
              fullWidth
            >
              Entrar
            </Button>
          </FormFormik>
        )}
      </Formik>
      <ForgotPassword isSubmitting={isSubmitting}>
        <Link to="/forgot-password">Esqueci minha senha</Link>
      </ForgotPassword>
    </Container>
  );
}

export default Login;
