import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { Formik, Form as FormFormik } from "formik";

import VMasker from "vanilla-masker";

import { increment, decrement } from "../../utils/controls-input-number";

// utils
import { formatPrice } from "../../utils/price-format";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Form,
  GroupContainer,
  ProductCount,
  Item,
  PriceCustom,
  Info,
  Controls,
  Total,
} from "./styles";
import { Button } from "../../atoms/Button";

import SelectCustom from "../../atoms/SelectCustom";
import TextArea from "../../atoms/TextArea";
import AutoSuggestOrder from "../../atoms/AutoSuggestOrder";
import AutoSuggestClient from "../../atoms/AutoSuggestClient";
import InputNumber from "../../atoms/InputNumber";

import FieldCustom from "../../organisms/FieldCustom";
import { InputField } from "../../molecules/InputField";
import { Label } from "../../atoms/Label";

import validations from "./validations";

import { payments } from "../../utils/payment_types";

function ModalOrderRegister({
  visible,
  handleToggleVisibility,
  handleSubmit,
  isSubmitting,
  setIsSubmitting,
}) {
  const [suggestClient, setSuggestClient] = useState({});
  const [suggestItem, setSuggestItem] = useState({});
  const [searchServiceOrProduct, setSearchServiceOrProduct] = useState("");

  const [items, setItems] = useState([]);

  function addNewItem() {
    const index = items.findIndex(
      (item) =>
        item.product_id === suggestItem.product_id &&
        item.service_id === suggestItem.service_id
    );

    let listOfItemsUpdated = [];

    if (index !== -1) {
      listOfItemsUpdated = items;
      listOfItemsUpdated[index] = {
        ...items[index],
        quantity: items[index].quantity + 1,
      };
    } else {
      listOfItemsUpdated = [
        ...items,
        {
          ...suggestItem,
          quantity: 1,
          price_custom: VMasker.toMoney(suggestItem.unit_price),
        },
      ];
    }

    setItems(listOfItemsUpdated);

    setSearchServiceOrProduct("");
    setSuggestItem({});
  }

  function setItemValue(position, field, value) {
    const updateItems = items
      .map((item, index) => {
        if (index === position) {
          return { ...item, [field]: value };
        }

        return item;
      })
      .filter((item) => item.quantity >= 1);

    setItems(updateItems);
  }

  function calculateTotal() {
    const total = items
      .map(
        (item) =>
          (item.price_custom.replaceAll(".", "").replace(",", ".") > 0
            ? item.price_custom.replaceAll(".", "").replace(",", ".")
            : item.unit_price) * item.quantity
      )
      .reduce((acc, cur) => Number(acc + cur));

    return formatPrice(total);
  }

  function closeModal() {
    setSuggestClient({});
    setItems([]);
    setSuggestItem({});
    setSearchServiceOrProduct("");

    handleToggleVisibility();
  }

  function onSubmit(values) {
    // montar objeto para enviar

    /* usando parseFloat para tratar e formatar dado do price_custom e enviar como número pra requisição */
    const products = items
      .filter((item) => item.product_id)
      .map((item) => {
        return {
          product_id: item.product_id,
          quantity: item.quantity,
          price_custom:
            item.price_custom.replaceAll(".", "").replace(",", ".") > 0
              ? parseFloat(
                  item.price_custom.replaceAll(".", "").replace(",", ".")
                )
              : null,
        };
      });

    /* usando parseFloat para tratar e formatar dado do price_custom e enviar como número pra requisição */
    const services = items
      .filter((item) => item.service_id)
      .map((item) => {
        return {
          service_id: item.service_id,
          quantity: item.quantity,
          price_custom:
            item.price_custom.replaceAll(".", "").replace(",", ".") > 0
              ? parseFloat(
                  item.price_custom.replaceAll(".", "").replace(",", ".")
                )
              : null,
        };
      });

    const data = {
      payment_type: values.payment,
      status: "pending",
      notes: values.notes,
      paid: false,
      customer_id: suggestClient?.id,
      items: [...products, ...services],
    };

    setIsSubmitting(true);
    handleSubmit(data);
    closeModal();
  }

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={closeModal}>
          <IconCloseModal />
        </CloseModal>
        <Title>Nova Ordem de Pedido</Title>
        <Formik
          validationSchema={validations}
          initialValues={{
            name: "",
            payment: "",
            notes: "",
          }}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            resetForm();
          }}
        >
          {({
            touched,
            errors,
            handleBlur,
            handleChange,
            values,
            setFieldValue,
          }) => (
            <FormFormik as={Form}>
              <fieldset>
                <FieldCustom
                  as={AutoSuggestClient}
                  title="Clientes"
                  name="name"
                  placeholder="Selecione um cliente..."
                  suggestItem={suggestClient}
                  setSuggestItem={setSuggestClient}
                  value={values.name}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                  touched={touched.name}
                  errors={errors.name}
                  setValue={setFieldValue}
                />

                <FieldCustom
                  as={SelectCustom}
                  touched={touched.payment}
                  errors={errors.payment}
                  value={values.payment}
                  title="Pagamento"
                  name="payment"
                  placeholder="Forma de pagamento..."
                  data={payments}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                />
              </fieldset>

              <fieldset>
                <legend>Adicione um Serviço ou Produto</legend>
                <GroupContainer>
                  <AutoSuggestOrder
                    placeholder="Serviços ou Produtos"
                    value={searchServiceOrProduct}
                    setValue={setSearchServiceOrProduct}
                    setSuggestItem={setSuggestItem}
                  />

                  <Button
                    type="button"
                    floating
                    primary
                    onClick={addNewItem}
                    disabled={
                      searchServiceOrProduct === "" ||
                      Object.keys(suggestItem).length === 0 ||
                      isSubmitting
                    }
                  >
                    <FiPlus />
                  </Button>
                </GroupContainer>
              </fieldset>

              {items.length > 0 && (
                <fieldset>
                  <legend>Lista do pedido</legend>
                  {items.map((item, index) => {
                    return (
                      <Item key={index}>
                        <ProductCount key={index}>
                          <Info>
                            <span>
                              {item.name}
                              {item.type ? ` - ${item.type}` : ""}
                            </span>
                            <p>
                              {item.quantity} und. -{" "}
                              {formatPrice(item.unit_price * item.quantity)}
                            </p>
                          </Info>
                          <Controls>
                            <button
                              type="button"
                              disabled={isSubmitting}
                              onClick={() =>
                                setItemValue(
                                  index,
                                  "quantity",
                                  item.quantity + 1
                                )
                              }
                            >
                              <FiPlus />
                            </button>
                            <button
                              type="button"
                              disabled={isSubmitting}
                              onClick={() =>
                                setItemValue(
                                  index,
                                  "quantity",
                                  item.quantity - 1
                                )
                              }
                            >
                              <FiMinus />
                            </button>
                          </Controls>
                        </ProductCount>
                        {/*  */}
                        <div>
                          <PriceCustom>
                            <em>
                              Por padrão, o preço customizado é igual ao preço
                              unitário do produto ou serviço. Altere-o para
                              aplicar um preço customizado. Caso o preço
                              customizado aplicado seja de R$ 0,00, o valor
                              aplicado para a ordem será o preço unitário
                              original.
                            </em>
                            <Label htmlFor={`price-custom-${index}`}>
                              Preço customizado
                            </Label>
                            <InputField>
                              <InputNumber
                                type="text"
                                name={`price-custom-${index}`}
                                placeholder="Preço customizado (reais)..."
                                value={item.price_custom}
                                handleChange={(e) => {
                                  setItemValue(
                                    index,
                                    "price_custom",
                                    VMasker.toMoney(e.target.value)
                                  );
                                }}
                                increment={() =>
                                  setItemValue(
                                    index,
                                    "price_custom",
                                    VMasker.toMoney(
                                      increment(String(item.price_custom))
                                    )
                                  )
                                }
                                decrement={() =>
                                  setItemValue(
                                    index,
                                    "price_custom",
                                    VMasker.toMoney(
                                      decrement(String(item.price_custom))
                                    )
                                  )
                                }
                              />
                            </InputField>
                          </PriceCustom>
                        </div>
                      </Item>
                    );
                  })}
                  <Total>Valor Total {calculateTotal()}</Total>
                </fieldset>
              )}

              <fieldset>
                <FieldCustom
                  as={TextArea}
                  touched={touched.notes}
                  errors={errors.notes}
                  value={values.notes}
                  name="notes"
                  title="Observação"
                  placeholder="Observação..."
                  maxLength={150}
                  handleBlur={(value) => handleBlur(value)}
                  handleChange={(value) => handleChange(value)}
                />
              </fieldset>

              <Button
                type="submit"
                mt="30"
                primary
                upper
                sm
                fullWidth
                disabled={
                  isSubmitting ||
                  !suggestClient.name ||
                  values.payment === "" ||
                  items.length === 0
                }
              >
                Criar Nova Ordem de Pedido
              </Button>
            </FormFormik>
          )}
        </Formik>
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalOrderRegister;
