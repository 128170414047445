import styled, { css } from "styled-components";

import Metrics from "../../settings/metrics";
import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";

export const Input = styled.input`
  width: ${(props) => (props.group ? "90%" : "400px")};
  height: 50px;
  border-radius: ${Metrics.radiusSm};
  border: 1px solid ${Colors.white};

  padding: ${(props) =>
    props.icon
      ? `calc(${Metrics.spaceSm} + ${Metrics.spaceXs}) ${Metrics.spaceLg}`
      : `calc(${Metrics.spaceSm} + ${Metrics.spaceXs}) ${Metrics.space}`};

  font-size: ${Fonts.textLg};
  font-weight: ${Fonts.semiBoldWeight};
  caret-color: ${Colors.lightBlue};

  position: relative;

  :not(:disabled) {
    box-shadow: 0 3px 6px ${Colors.greyShadow};
  }

  ::placeholder {
    color: ${Colors.black};
    line-height: ${Metrics.space};
    text-align: left;
    font-weight: ${Fonts.regularWeight};
    font-style: italic;
  }

  &:disabled {
    border-color: ${Colors.grey};
    color: ${Colors.grey};

    ::placeholder {
      color: ${Colors.grey};
    }
  }

  ${(props) =>
    props.type === "number" &&
    css`
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;

      ::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      position: relative;
    `}

  ${(props) => {
    props.type === "password" &&
      css`
        + svg {
          position: absolute;
          right: ${Metrics.spaceSm};
          top: 50%;
        }
      `;
  }}

  @media(max-width: 768px) {
    max-width: 400px;
    width: 100%;
  }
`;
