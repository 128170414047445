// validations
import * as yup from "yup";

const validations = yup.object().shape({
  password: yup
    .string()
    .min(6, "Mínimo de 6 caracteres")
    .required("Campo não-preenchido"),
  newPassword: yup
    .string()
    .min(6, "Mínimo de 6 caracteres")
    .required("Campo não-preenchido"),
  passwordConfirmation: yup
    .string()
    .required("Campo não-preenchido")
    .oneOf([yup.ref("newPassword"), null], "As senhas são diferentes"),
});

export default validations;
