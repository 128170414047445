import React from "react";
import PropTypes from "prop-types";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { createMuiTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function PaginationTab({ labelSecondary, page, handleChange, count }) {
  const theme = createMuiTheme({
    overrides: {
      MuiPaginationItem: {
        page: {
          color: "#005D89",
          fontFamily: "'Source Sans Pro','sans-serif'",
          fontWeight: "700",
          "&$selected": {
            backgroundColor: labelSecondary ? "#FFC06A" : "#0CBCE4",
            color: "#fff",
          },

          "&:hover": {
            backgroundColor: "#F2F2F2",
            color: "#0CBCE4",
          },
        },
      },
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Pagination count={count} page={page} onChange={handleChange} />
      </ThemeProvider>
    </div>
  );
}

export default PaginationTab;

PaginationTab.propTypes = {
  labelSecondary: PropTypes.bool,
  page: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};
