export default {
  space: "20px",

  spaceXs: "5px",
  spaceSm: "10px",
  spaceMd: "25px",
  spaceLg: "40px",
  spaceXl: "80px",

  radiusSm: "15px",
  radiusCircle: "50%",

  marginNavbarToBody: "70px",
};
