import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styles";

DataNotFound.propTypes = {
  labelSecondary: PropTypes.bool,
  message: PropTypes.string.isRequired,
};

function DataNotFound({ message, labelSecondary }) {
  return (
    <Container labelSecondary={labelSecondary}>
      <span>{message}</span>
    </Container>
  );
}

export default DataNotFound;
