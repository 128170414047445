import styled from "styled-components";

import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";
import Metrics from "../../settings/metrics";

export const Container = styled.div`
  overflow: hidden;
  margin-bottom: 40px;
  padding: ${Metrics.spaceXs};
`;

export const Header = styled.div`
  display: flex;

  > h1 {
    color: ${Colors.lightBlue};
    font-size: ${Fonts.titleXl};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-left: ${Metrics.spaceXl};

  > button {
    margin-right: ${Metrics.spaceLg};

    @media (max-width: 758px) {
      margin-right: 0;
      margin-top: ${Metrics.spaceSm};
    }
  }

  @media (max-width: 758px) {
    margin-left: 0;
    max-width: 400px;
    width: 100%;
    flex-direction: column;
  }
`;

export const TabNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: ${Metrics.space};
`;

export const Report = styled.div`
  width: 100%;
  margin-top: ${Metrics.space};

  > em {
    display: block;
    max-width: 500px;
    margin-bottom: ${Metrics.space};
  }

  @media (max-width: 569px) {
    display: grid;
    place-items: center;

    > em {
      display: block;
      max-width: 280px;
      margin-bottom: ${Metrics.space};
    }
  }
`;

/*  */

/*  */
/* exclusiva para a impressão térmica */
export const ToPrint = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

export const ContainerToPrint = styled.div`
  max-width: 280px;
  width: 100%;
  margin: 0 auto;

  p,
  span,
  address {
    font-size: 12px;

    span {
      text-align: right;
    }
  }

  > p:nth-child(1) {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  div {
    margin: 8px 0;
    padding-top: 5px;
  }

  div + div {
    border-top: 1px dashed grey;
  }

  div:last-child {
    text-align: center;
  }

  div:last-child em {
    margin-top: 5px;
    display: inline-block;
    font-size: 10px;
  }

  div:nth-last-child(2) p {
    display: flex;
    justify-content: space-between;
  }

  div.resume {
    fieldset {
      p {
        font-size: 10px;
        padding: 5px;
      }
    }
  }
`;
