import React from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormFormik } from "formik";
import VMasker from "vanilla-masker";

import { increment, decrement } from "../../utils/controls-input-number";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Form,
  Description,
} from "./styles";
import { Button } from "../../atoms/Button";

import FieldCustom from "../../organisms/FieldCustom";
import FieldControled from "../../organisms/FieldControled";
import InputNumber from "../../atoms/InputNumber";
import SearchFilter from "../../organisms/SearchFilter";

import validations from "./validations";

ModalProductBuy.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function ModalProductBuy({
  visible,
  handleToggleVisibility,
  product,
  handleSubmit,
  isSubmitting,
}) {
  const initialValues = {
    id: "",
    date: null,
    price: "",
    amount: "",
  };

  const preSubmit = (values) => {
    const data = {
      id: product.id ? product.id : "",
      date: values.date,
      price: values.price,
      amount: values.amount,
    };

    handleSubmit(data);
  };

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Registro de compra de produto</Title>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={(values, { resetForm }) => {
            preSubmit(values);
            resetForm({
              id: "",
              date: null,
              price: "",
              amount: "",
            });
          }}
        >
          {({
            dirty,
            isValid,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
          }) => (
            <FormFormik as={Form}>
              <fieldset>
                <Description>
                  <p>
                    Código <span>{product?.id}</span>
                  </p>
                  <p>
                    Descrição <span>{product?.name}</span>
                  </p>
                </Description>

                <FieldControled
                  name="date"
                  title="Data da compra"
                  touched={touched.date}
                  errors={errors.date}
                  value={values.date}
                  component={
                    <SearchFilter
                      name="date"
                      currentValue={values.date}
                      handleDateChange={(date) => {
                        if (date?._isValid && date <= new Date()) {
                          setFieldValue("date", date);
                        } else {
                          setFieldValue("date", null);
                        }
                      }}
                      maxDate={new Date()}
                      minDate={new Date("1900-01-01")}
                      onBlur={(value) => handleBlur(value)}
                      placeholder="Data da compra"
                      disableFuture={true}
                    />
                  }
                />

                <FieldCustom
                  as={InputNumber}
                  name="amount"
                  type="number"
                  title="Quantidade"
                  placeholder="Quantidade"
                  touched={touched.amount}
                  errors={errors.amount}
                  value={values.amount}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                  increment={() =>
                    setFieldValue("amount", Number(values.amount) + 1)
                  }
                  decrement={() => {
                    if (values.amount <= 0) {
                      return;
                    }
                    setFieldValue("amount", Number(values.amount) - 1);
                  }}
                />

                <FieldCustom
                  as={InputNumber}
                  name="price"
                  type="text"
                  title="Valor"
                  placeholder="Valor total gasto (reais)"
                  touched={touched.price}
                  errors={errors.price}
                  value={values.price}
                  handleChange={(e) => {
                    setFieldValue("price", VMasker.toMoney(e.target.value));
                  }}
                  handleBlur={(value) => handleBlur(value)}
                  increment={() =>
                    setFieldValue(
                      "price",
                      VMasker.toMoney(increment(values.price))
                    )
                  }
                  decrement={() =>
                    setFieldValue(
                      "price",
                      VMasker.toMoney(decrement(values.price))
                    )
                  }
                />
              </fieldset>
              <Button
                disabled={
                  !dirty ||
                  !isValid ||
                  isSubmitting ||
                  initialValues.date?._isValid
                }
                type="submit"
                mt="30"
                primary
                upper
                sm
                fullWidth
              >
                Registrar compra de produto
              </Button>
            </FormFormik>
          )}
        </Formik>
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalProductBuy;
