import React, { useState, useEffect } from "react";

//services
import api from "../../services/api";
import swal, { fireAction } from "../../utils/swal";

/* components */
import Dashboard from "../../templates/Dashboard";
import { Button } from "../../atoms/Button";
import PaginationTab from "../../atoms/PaginationTab";
import TableClients from "../../organisms/TableClients";
import Search from "../../organisms/Search";

import ModalClientRegister from "../../organisms/ModalClientRegister";
import ModalClientUpdate from "../../organisms/ModalClientUpdate";

/* styles */
import { Container, Header, ButtonsContainer, TabNavigation } from "./styles";

function Clients() {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [query, setQuery] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [clientToUpdate, setClientToUpdate] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isModalRegisterVisible, setIsModalRegisterVisible] = useState(false);
  const [isSearchbarVisible, setIsSearchbarVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [customers, setCustomers] = useState([]);

  const getCustomers = async (page, query = "", reset = false) => {
    if (reset) {
      setPage(1);
    }

    try {
      const { data } = await api.get(
        `/customers?qs=${query}&page=${reset ? 1 : page}&limit=10`
      );

      setCustomers(data.data);
      setLastPage(data.meta?.last_page);
    } catch (err) {
      console.warn("CLIENT-GET-CUSTOMERS:" + err);
    }
  };

  const handleRegisterCustomer = async (values) => {
    setIsSubmitting(true);

    const {
      name,
      cpf,
      phone_number,
      email,
      address,
      cep,
      city,
      neighborhood,
    } = values;

    try {
      await api.post("/customers", {
        name,
        cpf: cpf ? cpf : null,
        phone_number: phone_number ? phone_number : null,
        email: email ? email : null,
        cep,
        city,
        neighborhood,
        address,
      });

      handleToggleModalRegisterVisibility();
      fireAction(
        "Tudo certo!",
        "O cadastro do novo cliente foi realizado com sucesso",
        "success"
      );

      getCustomers(page);
    } catch (err) {
      if (err.response.status === 500) {
        fireAction(
          "Ops, algo deu errado!",
          `Erro interno no servidor...`,
          "error"
        );
        return;
      }

      const { data } = err.response;
      const fields = data.errors?.map((error) => error.field);
      const toPtBR = fields?.map((field) =>
        field === "phone_number" ? "contato" : field
      );

      if (toPtBR) {
        fireAction(
          "Ops, algo deu errado!",
          `Já existe um cliente com o ${toPtBR.join(", ")} informado.`,
          "error"
        );
      } else {
        fireAction(
          "Ops, algo deu errado!",
          "O cadastro do novo cliente falhou",
          "error"
        );
      }

      console.warn("CLIENT-NEW:" + err);
    } finally {
      handleToggleModalRegisterVisibility();
      setIsSubmitting(false);
    }
  };

  const handleUpdateCustomer = async (values) => {
    setIsSubmitting(true);

    const {
      id,
      name,
      cpf,
      phone_number,
      email,
      cep,
      city,
      neighborhood,
      address,
    } = values;

    try {
      await api.put(`/${id}`, {
        name,
        cpf: cpf ? cpf : null,
        phone_number: phone_number ? phone_number : null,
        email: email ? email : null,
        cep,
        city,
        neighborhood,
        address,
      });

      handleToggleModalUpdateVisibility();
      fireAction(
        "Tudo certo!",
        "O cadastro do cliente foi atualizado",
        "success"
      );
      getCustomers(page);
    } catch (err) {
      if (err.response.status === 500) {
        fireAction(
          "Ops, algo deu errado!",
          `Erro interno no servidor...`,
          "error"
        );
        return;
      }

      const { data } = err.response;

      const errors = data.errors?.map((error) => {
        return {
          field: error.field,
          rule: error.rule,
        };
      });

      const errorsInPtBR = errors.map((error) => {
        if (error.rule === "unique") {
          error.rule = "deve ser único";
        } else if (error.rule === "regex") {
          error.rule = "possui um formato inválido";
        }

        if (error.field === "phone_number") {
          error.field = "contato";
        }

        return {
          field: error.field,
          rule: error.rule,
        };
      });

      const concatErrors = errorsInPtBR?.map(
        (error) => `${error.field} ${error.rule}`
      );

      if (concatErrors) {
        fireAction(
          "Ops, algo deu errado!",
          `Falha ao registrar o cliente, ${concatErrors.join(", ")}.`,
          "error"
        );
      } else {
        fireAction(
          "Ops, algo deu errado!",
          "Falha ao atualizar os dados do cliente",
          "error"
        );
      }

      console.warn("CLIENT-UPDATE:" + err);
    } finally {
      handleToggleModalUpdateVisibility();
      setIsSubmitting(false);
    }
  };

  /* ================================== */

  useEffect(() => {
    getCustomers(page, query, false);
  }, [page, query]);

  /* ================================== */

  function handleToggleModalUpdateVisibility() {
    setIsModalUpdateVisible(!isModalUpdateVisible);
  }

  const handleToggleModalRegisterVisibility = () => {
    setIsModalRegisterVisible(!isModalRegisterVisible);
  };

  function handleToggleSearchbarVisibility() {
    if (isSearchbarVisible) {
      setSearchValue("");
      getCustomers(page, "", true);

      if (query) {
        setQuery("");
      }
    }
    setIsSearchbarVisible(!isSearchbarVisible);
  }

  /* ================================== */

  const handleDelete = async (id) => {
    setIsSubmitting(true);

    swal
      .fire({
        title: "Tem certeza que deseja excluir o cliente?",
        text: "Esta ação não poderá ser revertida!",
        icon: "warning",
        customClass: {
          header: "header-alert",
          icon: "icon-alert",
          title: `title-alert-warning`,
          content: "description-alert",
        },
        showCancelButton: true,
        confirmButtonColor: "#6b915c",
        cancelButtonColor: "#cc2c29",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await api.delete(`/${id}`);

            fireAction(
              "Tudo certo!",
              "O cliente foi removido da base de dados",
              "success"
            );

            const filtered = customers.filter((customer) => customer.id !== id);
            setCustomers(filtered);
            if (filtered.length === 0 && page > 1) {
              setPage((page) => page - 1);
            }
          } catch (err) {
            if (err.response?.status === 401) {
              fireAction(
                "Ops, algo deu errado!",
                "Você não tem permissão para realizar esta ação",
                "error"
              );
            } else {
              fireAction(
                "Ops, algo deu errado!",
                "Falha ao remover o cliente da base de dados",
                "error"
              );
            }

            console.warn("CLIENT-DELETE:" + err);
          }
        }
      });

    setIsSubmitting(false);
  };

  // ver o uso de useCallback nessas funções
  const handleUpdate = (customerToUpdate) => {
    const client = customers.find(
      (customer) => customer.id === customerToUpdate.id
    );
    setClientToUpdate(client);
    handleToggleModalUpdateVisibility();
  };

  // decidir por auto pesquisar ou por submit
  const handleSubmit = (e) => {
    e.preventDefault();
    getCustomers(page, searchValue, true);
    setQuery(searchValue);
  };

  return (
    <Dashboard>
      <ModalClientRegister
        visible={isModalRegisterVisible}
        handleToggleVisibility={handleToggleModalRegisterVisibility}
        handleSubmit={handleRegisterCustomer}
        isSubmitting={isSubmitting}
      />
      <ModalClientUpdate
        visible={isModalUpdateVisible}
        handleToggleVisibility={handleToggleModalUpdateVisibility}
        client={clientToUpdate}
        handleSubmit={handleUpdateCustomer}
        isSubmitting={isSubmitting}
      />
      <Container>
        <Header>
          <h1>Clientes</h1>
          <ButtonsContainer>
            <Button primary sm onClick={handleToggleModalRegisterVisibility}>
              Novo Cliente
            </Button>
            <Button tertiary sm onClick={handleToggleSearchbarVisibility}>
              {isSearchbarVisible ? "Fechar busca" : "Buscar clientes"}
            </Button>
          </ButtonsContainer>
        </Header>
        <Search
          visible={isSearchbarVisible}
          handleSubmit={handleSubmit}
          name="Pesquisar por código ou nome..."
          value={searchValue}
          handleChange={setSearchValue}
        />
        {customers && (
          <TableClients
            customers={customers}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            isSubmitting={isSubmitting}
          />
        )}
        {lastPage > 1 && (
          <TabNavigation>
            <PaginationTab
              count={lastPage}
              page={page}
              handleChange={(event, value) => {
                setPage(value);
              }}
            />
          </TabNavigation>
        )}
      </Container>
    </Dashboard>
  );
}

export default Clients;
