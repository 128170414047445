import React from "react";
import PropTypes from "prop-types";

import { Container } from "./styles";
import { Button } from "../../atoms/Button";
import { Input } from "../../atoms/Input";

Search.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

function Search({ handleSubmit, visible, name, value, handleChange }) {
  return (
    <Container visible={visible} onSubmit={handleSubmit}>
      <Input
        placeholder={name}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        type="text"
      />
      <Button type="submit" primary sm upper>
        Pesquisar
      </Button>
    </Container>
  );
}

export default Search;
