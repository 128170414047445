import React, { useEffect, useMemo } from "react";
import { formatPrice } from "../../../utils/price-format";
import { formatDate } from "../../../utils/date-format";

import { useStore } from "../../../store";
import api from "../../../services/api";

import {
  HeaderGrid,
  Info,
  InputInfo,
  OutputInfo,
  Gain,
  Losses,
} from "./styles";

function HeaderInfo({ date, children, isModal }) {
  const { state, dispatch } = useStore();
  const balance = useMemo(() => state.inputTotal - state.outputTotal, [
    state.inputTotal,
    state.outputTotal,
  ]);
  /*   const rest = useMemo(() => balance + state.initialValue, [
    balance,
    state.initialValue,
  ]); */

  useEffect(() => {
    let mounted = true;
    async function getDailyBalance(date = "") {
      const { data } = await api.get(
        `/daily-balance?day=${formatDate(date, "yyyy-MM-dd").replaceAll(
          "/",
          "-"
        )}`
      );
      if (mounted) {
        dispatch({
          type: "SET_BALANCE",
          initialValue: data?.opening,
          inputTotal: data?.incoming,
          outputTotal: data?.outcoming,
          final: data?.final,
          closed: data?.closed,
          dirty: data?.dirty,
        });
      }
    }
    //console.log("DIRTY ATUAL: " + state.dirty);
    getDailyBalance(date);

    return () => (mounted = false);
  }, [date, dispatch, state.dirty]);

  return (
    <HeaderGrid isModal={isModal}>
      <p>
        Valor inicial
        <span>{formatPrice(state.initialValue)}</span>
      </p>

      <Info>
        <InputInfo>
          Valor Total de Entrada: <span>{formatPrice(state.inputTotal)}</span>
        </InputInfo>
        <OutputInfo>
          Valor Total de Saída: <span>{formatPrice(state.outputTotal)}</span>
        </OutputInfo>
      </Info>
      <Info>
        {balance >= 0 ? (
          <Gain>
            Saldo do Dia:{" "}
            <span>
              {formatPrice(balance)} <em>lucro</em>
            </span>
          </Gain>
        ) : (
          <Losses>
            Saldo do Dia:{" "}
            <span>
              {formatPrice(balance)} <em>prejuízo</em>
            </span>
          </Losses>
        )}

        {state.final >= 0 ? (
          <Gain>
            Valor Total Restante:{" "}
            <span>
              {formatPrice(state.final)} <em>lucro</em>
            </span>
          </Gain>
        ) : (
          <Losses>
            Valor Total Restante:{" "}
            <span>
              {formatPrice(state.final)} <em>prejuízo</em>
            </span>
          </Losses>
        )}
      </Info>
      {children}
    </HeaderGrid>
  );
}

export default HeaderInfo;
