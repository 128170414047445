/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

/* ================= */
import api from "../../services/api";
import { formatDate } from "../../utils/date-format";
import { fireAction } from "../../utils/swal";

import { Button } from "../../atoms/Button";
import { Error } from "../../atoms/Error";

import Dashboard from "../../templates/Dashboard";

import ModalCloseDay from "../../organisms/ModalCloseDay";

import SearchFilter from "../../organisms/SearchFilter";
import HeaderInfo from "./HeaderInfo";

import InputCash from "./InputCash";
import OutputCash from "./OutputCash";

import { useStore } from "../../store";

/* ================= */

import {
  Container,
  Header,
  ButtonsContainer,
  PickerContainer,
  GoBack,
  IconGoBack,
  Actions,
} from "./styles";

const AntTabs = withStyles({
  root: {
    width: "max-content",
    borderBottom: "1px solid #e8e8e8",
    marginTop: 20,
  },
  indicator: {
    backgroundColor: "#0CBCE4",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 150,
    "@media(max-width: 468px)": {
      maxWidth: 150,
    },
    fontWeight: "bold",
    color: "#005D89",
    opacity: 1,
    fontFamily: [
      '"Source Sans Pro"',
      '"sans-serif"',
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 16,
    transition: "color .2s ease-out",
    "&:hover": {
      color: "#094967",
    },
    "&$selected": {
      color: "#0CBCE4",
    },
    "&:focus": {
      color: "#0CBCE4",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: "#fff",
    "@media(max-width: 768px)": {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr)",
      gridGap: "10px",
      placeItems: "center",
    },
  },
}));

function CashFlow() {
  const classes = useStyles();
  const { state, dispatch } = useStore();

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [modalCloseDay, setModalCloseDay] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [dateFilter, setDateFilter] = useState(new Date());

  /* TABS NAVIGSTION CONTROL ===== */
  /* ==================== */
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  /* ==================== */

  /* MODAL CLOSE CASHFLOW ===== */
  /* ==================== */
  const handleToggleModalCloseDay = () => {
    setModalCloseDay(!modalCloseDay);
  };
  /* ==================== */

  const handleCloseCashFlow = async () => {
    setIsSubmitting(true);
    try {
      await api.post(`/daily-balance`, {
        day: formatDate(dateFilter, "yyyy-MM-dd").replaceAll("/", "-"),
      });
      handleToggleModalCloseDay();

      fireAction(
        "Tudo certo!",
        "O fechamento do caixa foi realizado com sucesso",
        "success"
      );

      dispatch({
        type: "SET_BALANCE_STATUS",
        closed: true,
        dirty: false,
      });
      // usar dirty
    } catch (err) {
      handleToggleModalCloseDay();
      fireAction(
        "Ops, algo deu errado!",
        "O fechamento do caixa falhou",
        "error"
      );
      console.warn("CLOSE-CASHFLOW:" + err);
    }
    setIsSubmitting(false);
  };

  /**
   * TODO:
   * ---------
   * ALTERAR O IF TERNARIO N BOTÃO DE FECHAR CAIXA QUANDO O BACK ARRUMAR O RETORNO DO DIRTY
   */

  const cashFlowIsDirty = React.useCallback(() => {
    state.dirty &&
      setTimeout(() => {
        fireAction(
          "Aviso",
          "Existe uma inconsistência nos valores por uma transação contabilizada após o fechamento do caixa do dia. Para corrigir essa inconsistência, feche o caixa novamente.",
          "warning"
        );
      }, 1000);
  }, [state.dirty]);

  React.useEffect(() => {
    cashFlowIsDirty();
  }, [cashFlowIsDirty]);

  return (
    <Dashboard>
      <ModalCloseDay
        visible={modalCloseDay}
        handleToggleVisibility={handleToggleModalCloseDay}
        handleSubmit={handleCloseCashFlow}
        isSubmitting={isSubmitting}
        date={dateFilter}
      />
      <Container>
        <Header>
          <h1>Fluxo de Caixa</h1>
          <ButtonsContainer>
            <Button
              primary
              sm
              onClick={handleToggleModalCloseDay}
              disabled={state.closed && !state.dirty}
            >
              {state.closed && !state.dirty
                ? "O Caixa está Fechado"
                : "Fechar Caixa do Dia"}
            </Button>
            <Actions>
              <PickerContainer>
                <SearchFilter
                  dateFilter={dateFilter}
                  setDateFilter={(date) => {
                    if (date?._isValid) {
                      setDateFilter(date);
                    }
                  }}
                  placeholder="Filtrar entradas por dia"
                  disableFuture={true}
                />
              </PickerContainer>

              {new Date(dateFilter).toDateString() !==
                new Date().toDateString() && (
                <GoBack onClick={() => setDateFilter(new Date())}>
                  <IconGoBack />
                  Voltar para o dia atual
                </GoBack>
              )}
            </Actions>
          </ButtonsContainer>
        </Header>

        <HeaderInfo date={dateFilter} />

        <div className={classes.demo1}>
          <AntTabs value={selectedTab} onChange={handleChangeTab}>
            <AntTab label="Entradas" />
            <AntTab label="Saídas" />
          </AntTabs>
        </div>
        {selectedTab === 0 && <InputCash dateToFilter={dateFilter} />}
        {selectedTab === 1 && <OutputCash dateToFilter={dateFilter} />}
      </Container>
    </Dashboard>
  );
}

export default CashFlow;
