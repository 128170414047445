import * as yup from "yup";

const validations = yup.object().shape({
  name: yup.string().required("Campo não-preenchido"),
  unit_price: yup
    .string()
    .required("Campo não-preenchido")
    .matches(
      /(?=.*\d)^\$?(([1-9]\d{0,2}(.\d{3})*)|0)?(,\d{1,2})?$/,
      "Insira um valor positivo no formato R$ 0.000,00"
    ),
  stock: yup
    .number()
    .integer("Campo inválido")
    .positive("Campo inválido")
    .required("Campo não-preenchido"),
  inventory: yup.string().required("Campo não-preenchido"),
});

export default validations;
