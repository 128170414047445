import styled from "styled-components";

import Metrics from "../../settings/metrics";

export const InputField = styled.fieldset`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${Metrics.space} auto;

  margin-bottom: ${Metrics.spaceMd};

  > span {
    bottom: calc(-${Metrics.spaceSm} - 12px);
  }
  > input {
    margin: 0 auto;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  margin: ${Metrics.space} auto;
  margin: 0 auto;
  align-items: flex-end;

  > div {
    input {
      flex: none;
      width: 90%;
    }
  }
  > div + div {
    margin-left: ${Metrics.spaceSm};

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 0;
    align-items: unset;
    > div {
      input {
        flex: 1 1 100%;
        width: 100%;
      }
    }
  }
`;
