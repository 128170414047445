import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 58,
    height: 28,
    padding: 0,
    margin: theme.spacing(1),
    borderRadius: 28 / 2,
    boxShadow: "0px 1px 3px #00000033",
  },
  switchBase: {
    padding: 1,
    transform: "translateX(32px) translateY(2px)",
    color: "#0cbce4",

    primary: {
      color: "red",
    },

    "&$checked": {
      transform: "translateX(2px) translateY(2px)",
      color: "#fff",

      "& + $track": {
        backgroundColor: "#0cbce4",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#0cbce4",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 22,
    height: 22,
    zIndex: 2,
    pointerEvents: "none",
  },
  track: {
    borderRadius: 28 / 2,

    "&::before": {
      position: "absolute",
      content: '"✔"',
      fontSize: 14,
      width: 14,
      height: 14,
      color: "#fff",
      transform: "translateX(-5px) translateY(4px)",
      right: "2px",
      zIndex: 1,
      pointerEvents: "none",
    },

    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default IOSSwitch;
