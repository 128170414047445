import styled, { css } from "styled-components";

import { FiX } from "react-icons/fi";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";
import Fonts from "../../settings/fonts";

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1299;

  display: ${(props) => (props.visible ? "flex" : "none")};

  justify-content: center;

  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ModalContent = styled.div`
  position: absolute;
  left: 50%;

  transform: translate(-50%, 0px);
  padding: ${Metrics.space} ${Metrics.spaceLg};

  max-width: 480px;
  width: 100%;
  min-height: 500px;

  border-radius: ${Metrics.radiusSm};
  background: ${Colors.white};
  margin: ${Metrics.space} 0 150px;

  fieldset {
    margin: ${Metrics.space} auto;

    legend {
      color: ${Colors.darkGrey};
      font-size: ${Fonts.textLg};
      font-weight: ${Fonts.boldWeight};
      margin: ${Metrics.space} 0;
    }
  }

  @media (max-width: 600px) {
    max-width: 500px;
    width: 94%;
    padding: ${Metrics.space};
  }
`;

export const Title = styled.h4`
  font-size: ${Fonts.titleMd};
  color: ${Colors.lightBlue};

  @media (max-width: 454px) {
    width: 90%;
  }
`;

export const Form = styled.form`
  margin-top: ${Metrics.spaceSm};
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  display: grid;
  flex-wrap: wrap;

  grid-template-columns: repeat(auto-fit, 30% 70%);
  grid-gap: ${Metrics.spaceXs};
  margin-top: calc(${Metrics.spaceSm} + ${Metrics.spaceXs});
  margin-bottom: ${Metrics.spaceLg};

  > p {
    padding: ${Metrics.spaceXs};
    color: ${Colors.lightBlue};
    font-weight: ${Fonts.boldWeight};

    > span {
      color: ${Colors.black};
      font-weight: ${Fonts.regularWeight};
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

/* Close Modal Btn */
export const CloseModal = styled.button`
  background: none;
  border: none;

  position: absolute;
  right: ${Metrics.space};
  top: ${Metrics.space};
`;

const iconCSS = css`
  width: 24px;
  height: 24px;
  color: ${Colors.darkBlue};
`;

export const IconCloseModal = styled(FiX)`
  ${iconCSS};
  stroke-width: 4px;
`;
