import Swal from "sweetalert2";
import "./reset-swal-style.css";

/**
 *
 * @param {String} title Título da mensagem
 * @param {String} message Mensagem
 * @param {String} type Tipo de ação (success, error, warning)
 */
export function fireAction(title, message, type) {
  Swal.fire({
    title: title,
    text: message,
    icon: type,
    confirmButtonText: "",
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn-close-alert",
      header: "header-alert",
      icon: "icon-alert",
      title: `title-alert-${type}`,
      content: "description-alert",
    },
  });
}

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default Swal;
