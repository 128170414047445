import React from "react";

// services
import { formatDate } from "../../utils/date-format";
import { formatPrice } from "../../utils/price-format";
import { formatPhone } from "../../utils/phone-format";

import DataNotFound from "../../atoms/DataNotFound";

import {
  IconAcordeon,
  CardsGrid,
  Card,
  Key,
  Code,
  Value,
  ControlAcordeon,
  AccordionShow,
  Acordeon,
} from "./styles";

function TableClientsDefaulters({ customers }) {
  return (
    <>
      {customers.length === 0 ? (
        <DataNotFound message="Nenhum resultado encontrado" />
      ) : (
        <CardsGrid>
          {customers.map((customer, index) => (
            <Card key={index}>
              <Code>
                Código &nbsp;<Value>{customer.id}</Value>
              </Code>

              <AccordionShow
                type="checkbox"
                id={`defaulter-${customer.id}-${index}`}
              />
              <ControlAcordeon htmlFor={`defaulter-${customer.id}-${index}`}>
                <IconAcordeon />
              </ControlAcordeon>

              <Acordeon className="card">
                <Key>
                  Nome &nbsp;<Value>{customer.name}</Value>
                </Key>
                <Key>
                  Contato &nbsp;
                  <Value>{formatPhone(customer.phone_number)}</Value>
                </Key>
                <Key>
                  Valor &nbsp;
                  <Value>{formatPrice(customer.total)}</Value>
                </Key>
                <Key>
                  Data de criação &nbsp;
                  <Value>
                    {formatDate(customer.created_at, "dd-month-yyyy")}
                  </Value>
                </Key>
              </Acordeon>
            </Card>
          ))}
        </CardsGrid>
      )}
    </>
  );
}

export default TableClientsDefaulters;
