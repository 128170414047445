import React from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormFormik } from "formik";

import {
  ModalContainer,
  ModalContent,
  CloseModal,
  IconCloseModal,
  Title,
  Form,
  Description,
} from "./styles";
import { Button } from "../../atoms/Button";

import FieldCustom from "../../organisms/FieldCustom";
import InputNumber from "../../atoms/InputNumber";

import validations from "./validations";

ModalProductSale.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function ModalProductSale({
  visible,
  handleToggleVisibility,
  product,
  handleSubmit,
  isSubmitting,
}) {
  const initialValues = {
    id: "",
    amount: "",
  };

  const preSubmit = (values) => {
    const data = {
      id: product.id ? product.id : "",
      amount: values.amount,
    };

    handleSubmit(data);
  };

  return (
    <ModalContainer visible={visible}>
      <ModalContent>
        <CloseModal onClick={handleToggleVisibility}>
          <IconCloseModal />
        </CloseModal>
        <Title>Registro de saída de produto</Title>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={(values, { resetForm }) => {
            preSubmit(values);
            resetForm({
              id: "",
              amount: "",
            });
          }}
        >
          {({
            dirty,
            isValid,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
          }) => (
            <FormFormik as={Form}>
              <fieldset>
                <Description>
                  <p>
                    Código <span>{product?.id}</span>
                  </p>
                  <p>
                    Descrição <span>{product?.name}</span>
                  </p>
                  <p>
                    Estoque <span>{product?.stock} unidades</span>
                  </p>
                </Description>
                <FieldCustom
                  labelSecondary
                  as={InputNumber}
                  name="amount"
                  type="number"
                  title="Quantidade"
                  placeholder="Quantidade"
                  touched={touched.amount}
                  errors={errors.amount}
                  value={values.amount}
                  handleChange={(value) => handleChange(value)}
                  handleBlur={(value) => handleBlur(value)}
                  increment={() =>
                    setFieldValue("amount", Number(values.amount) + 1)
                  }
                  decrement={() => {
                    if (values.amount <= 0) {
                      return;
                    }
                    setFieldValue("amount", Number(values.amount) - 1);
                  }}
                />
              </fieldset>
              <Button
                disabled={!dirty || !isValid || isSubmitting}
                type="submit"
                mt="30"
                secondary
                upper
                sm
                fullWidth
              >
                Registrar saída de produto
              </Button>
            </FormFormik>
          )}
        </Formik>
      </ModalContent>
    </ModalContainer>
  );
}

export default ModalProductSale;
