import * as yup from "yup";

const validations = yup.object().shape({
  date: yup
    .date()
    .min(new Date("1900-01-01"), " ")
    .required("Preencha com uma data válida")
    .nullable(),
  price: yup
    .string()
    .required("Campo não-preenchido")
    .matches(
      /(?=.*\d)^\$?(([1-9]\d{0,2}(.\d{3})*)|0)?(,\d{1,2})?$/,
      "Insira um valor positivo no formato R$ 0.000,00"
    ),
  amount: yup
    .number()
    .integer("Campo inválido")
    .positive("Campo inválido")
    .required("Campo não-preenchido"),
});

export default validations;
