import styled from "styled-components";

import Colors from "../../settings/colors";
import Metrics from "../../settings/metrics";
import Fonts from "../../settings/fonts";

export const Container = styled.div`
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;

  max-width: 400px;
  width: 100%;

  .react-autosuggest__container {
    position: relative;

    @media (max-width: 768px) {
      max-width: 400px;
      width: 100%;
    }
  }

  .react-autosuggest__input {
    width: 100%;
    height: 50px;
    padding: calc(${Metrics.spaceSm} + ${Metrics.spaceXs}) ${Metrics.space};
    border-radius: ${Metrics.radiusSm};
    border: 1px solid ${Colors.white};
    font-size: ${Fonts.textLg};
    font-weight: ${Fonts.semiBoldWeight};
    caret-color: ${Colors.lightBlue};
    border: 1px solid #fff;
    box-shadow: 0 3px 6px ${Colors.greyShadow};

    ::placeholder {
      color: ${Colors.black};
      line-height: ${Metrics.space};
      text-align: left;
      font-weight: ${Fonts.regularWeight};
      font-style: italic;
    }

    @media (max-width: 768px) {
      max-width: 400px;
      width: 100%;
    }
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 52px;
    width: 100%;
    padding: calc(${Metrics.spaceSm} + ${Metrics.spaceXs}) ${Metrics.space};
    border: 1px solid #fff;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-radius: 15px;
    z-index: 25;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);

    @media (max-width: 768px) {
      max-width: 400px;
      width: 100%;
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #0cbce4;
    color: #fff;
  }
`;
