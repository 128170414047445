import * as yup from "yup";

const validations = yup.object().shape({
  amount: yup
    .number()
    .integer("Campo inválido")
    .positive("Campo inválido")
    .required("Campo não-preenchido"),
});

export default validations;
