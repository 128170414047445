export const payments = [
  {
    id: 1,
    name: "Cartão",
    value: "Cartão",
  },
  {
    id: 2,
    name: "Cheque",
    value: "Cheque",
  },
  {
    id: 3,
    name: "Espécie",
    value: "Espécie",
  },
];
