export const measures = [
  {
    name: "Lavar",
    value: "Lavar",
  },
  {
    name: "Passar",
    value: "Passar",
  },
  {
    name: "Completo",
    value: "Completo",
  },
];
