import React from "react";
import PropTypes from "prop-types";
import { Label } from "../../atoms/Label";

import { Error } from "../../atoms/Error";
import { InputField } from "../../molecules/InputField";

FieldControled.propTypes = {
  touched: PropTypes.any,
  errors: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  component: PropTypes.any,
};

function FieldControled({ touched, errors, value, name, title, component }) {
  return (
    <>
      {/* {(value || (errors && touched)) && (
        <Label htmlFor={name} error={touched && errors}>
          {title}
        </Label>
      )} */}
      <Label htmlFor={name} error={touched && errors}>
        {title}
      </Label>
      <InputField>
        {component}
        <Error>{touched && errors}</Error>
      </InputField>
    </>
  );
}

export default FieldControled;
