import styled from "styled-components";

import Metrics from "../../settings/metrics";
import Colors from "../../settings/colors";
import Fonts from "../../settings/fonts";

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    max-width: 400px;
    width: 90%;
  }
`;

export const ContainerImg = styled.div`
  max-width: 300px;
  margin-bottom: ${Metrics.spaceLg};

  > img {
    width: 100%;
    margin: 0 auto;
    display: block;

    @media (max-width: 604px) {
      width: 80%;
    }
  }
`;

export const ForgotPassword = styled.div`
  margin-top: ${Metrics.spaceMd};
  max-width: 400px;
  width: 100%;
  display: grid;
  place-items: center;
  pointer-events: ${(props) => (props.isSubmitting ? "none" : "all")};

  a {
    color: ${Colors.darkBlue};
    font-weight: ${Fonts.boldWeight};
  }
`;
