import styled from "styled-components";
import Metrics from "../../settings/metrics";

/* Containers */
export const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${Metrics.marginNavbarToBody});
  /*  height: 100vh; */
  background: rgba(0 0, 0, 0.12);

  position: relative;
`;

export const Content = styled.div`
  display: flex;
  /* height: calc(100vh - ${Metrics.marginNavbarToBody}); */
  height: 100vh;

  overflow: hidden;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
`;
